import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const Footer = ({ children, links, online }) => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="container pt-20 pb-50">
        <div className="flex flex-wrap justify-center flex-col-reverse lg:flex-row lg:justify-between items-center pb-5">
          <nav className="secondary-nav-links py-20 lg:mr-auto -ml-5">
            <ul>
              {links.map(({ id, path }) => (
                <li key={id}>
                  <NavLink
                    to={path}
                    activeClassName="active"
                    className="secondary-nav-links__link text-lg"
                  >
                    {t(`footer.nav.${id}`)}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>

          {children}

          <div className="flex items-center lg:ml-35 py-20 lg:py-0">
            <svg aria-hidden="true" className="fill-current text-green w-18 h-22 mr-8">
              <use href="/svg/svg.svg#padlock" />
            </svg>
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: t("footer.encryption") }} />
          </div>
          <div className="sm:hidden">
            <div className="text-green flex justify-end items-center">
              <div className="grid place-items-center w-14 h-14 mr-10 relative">
                <i className="rounded-circle h-full w-full bg-green bg-opacity-15 animate-pulse" />
                <i className="block rounded-circle w-4 h-4 bg-green absolute-center" />
              </div>
              {online.toLocaleString("en-US")}
            </div>
          </div>
        </div>
        <div className="text-gray-dark prose leading-tight">
          <p>{t("footer.copyright")}</p>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  children: PropTypes.node.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
  online: PropTypes.number.isRequired,
};

export default Footer;
