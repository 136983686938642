import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setRound, setBets, newBet, newHistory, finishBets } from "@/actions/rouletteAction";
import { useSubscribe } from "@/libs/centrifuge";
import {
  ROULETTE_FINISH_STATUS,
  ROULETTE_BETTING_STATUS,
  ROULETTE_ROLLING_STATUS,
} from "@/constants/rouletteStatuses";
import useSound from "@/helpers/useSound";

const RouletteSubscribeContainer = () => {
  const subscribe = useSubscribe();
  const dispatch = useDispatch();
  const sound = useSound();

  useEffect(() => {
    const unsubscribe = subscribe("roulette", ({ type, ...payload }) => {
      switch (type) {
        case "update": {
          dispatch(setRound(payload.round));
          if (payload.round.status === ROULETTE_FINISH_STATUS) {
            dispatch(newHistory(payload.round));
            dispatch(finishBets(payload.round));
            sound.rouletteEnd();
          }

          if (payload.round.status === ROULETTE_BETTING_STATUS) {
            dispatch(setBets([]));
          }

          if (payload.round.status === ROULETTE_ROLLING_STATUS) {
            sound.rouletteStart();
          }

          break;
        }
        case "newBet": {
          dispatch(newBet(payload.bet));
          break;
        }
        default:
          break;
      }
    });

    return () => unsubscribe();
  });

  return <></>;
};

export default RouletteSubscribeContainer;
