import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "@/api";
import CasinopubGame from "@/components/games/casinopub/CasinopubGame";

const CasinopubGameContainer = () => {
  const { casinopubCreateSession } = useApi();
  const { game } = useParams();

  useEffect(() => {
    casinopubCreateSession(game).then(result => {
      const success = gameDispatcher => {
        console.log({ gameDispatcher });
      };
      const error = error => {
        console.log({ error });
      };

      const gameLaunchOptions = {
        target_element: "game-wrapper-casinopub",
        launch_options: result.launch_options,
      };
      window.sg.launch(gameLaunchOptions, success, error);
    });
  }, [game]);

  return <CasinopubGame />;
};

export default CasinopubGameContainer;
