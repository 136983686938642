const AUTH_TOKEN_KEY = "auth-token";
const AUTH_TOKEN_EXPIRES_IS_KEY = "auth-token-expires-is";

const authToken = {
  set: token => localStorage.setItem(AUTH_TOKEN_KEY, token),
  get: () => localStorage.getItem(AUTH_TOKEN_KEY),
  setExpiresIn: expiresIn => localStorage.setItem(AUTH_TOKEN_EXPIRES_IS_KEY, expiresIn),
  getExpiresIn: () => localStorage.getItem(AUTH_TOKEN_EXPIRES_IS_KEY),
  remove: () => localStorage.removeItem(AUTH_TOKEN_KEY),
};

export default authToken;
