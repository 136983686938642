import {
  SET_CHAT_OPEN,
  SET_CHAT_MESSAGES,
  ADD_NEW_CHAT_MESSAGE,
  REMOVE_CHAT_MESSAGES_BY_USER_ID,
  REMOVE_CHAT_MESSAGE,
  CLEAR_CHAT,
  SET_CHAT_HIDE_STICKERS,
  SET_CHAT_ONLINE,
  SET_CHAT_PIN_MESSAGE,
} from "@/constants";

const initialState = {
  online: 0,
  open: false,
  messages: [],
  pinMessage: null,
  hideStickers: false,
};

const chat = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_CHAT_OPEN: {
      return {
        ...state,
        open: action.payload,
      };
    }
    case SET_CHAT_ONLINE:
      return {
        ...state,
        online: action.payload,
      };
    case SET_CHAT_MESSAGES: {
      return {
        ...state,
        messages: [...action.payload],
      };
    }
    case ADD_NEW_CHAT_MESSAGE: {
      const { messages } = state;

      messages.push(action.payload);
      if (messages.length > 30) {
        messages.shift();
      }

      return {
        ...state,
        messages: [...messages],
      };
    }
    case REMOVE_CHAT_MESSAGES_BY_USER_ID: {
      const messages = state.messages.filter(({ user }) => user.id !== action.payload);
      return {
        ...state,
        messages: [...messages],
      };
    }
    case REMOVE_CHAT_MESSAGE: {
      const messages = state.messages.filter(({ id }) => id !== action.payload);
      return {
        ...state,
        messages: [...messages],
      };
    }
    case CLEAR_CHAT: {
      return {
        ...state,
        messages: [],
      };
    }
    case SET_CHAT_HIDE_STICKERS: {
      return {
        ...state,
        hideStickers: action.payload,
      };
    }
    case SET_CHAT_PIN_MESSAGE: {
      return {
        ...state,
        pinMessage: action.payload,
      }
    }
    default: {
      return state;
    }
  }
};

export default chat;
