import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setOpenAuth } from "@/actions/mainAction";
import AuthModal from "@/components/auth/AuthModal";
import LoginContainer from "@/containers/auth/LoginContainer";
import RegisterContainer from "@/containers/auth/RegisterContainer";
import SocialLoginContainer from "@/containers/auth/SocialLoginContainer";

const AuthContainer = () => {
  const modalRef = useRef();
  const [triggerClose, setTriggerClose] = useState(false);
  const dispatch = useDispatch();
  const totalWager = useSelector(({ live }) => live.count);
  const openAuth = useSelector(({ main }) => main.openAuth);

  useEffect(() => {
    if (openAuth && modalRef && modalRef.current) {
      modalRef.current.open();
      dispatch(setOpenAuth(false));
    }
  }, [openAuth, modalRef]);

  return (
    <AuthModal
      modalRef={modalRef}
      login={<LoginContainer successCallback={() => setTriggerClose(!triggerClose)} />}
      register={<RegisterContainer successCallback={() => setTriggerClose(!triggerClose)} />}
      socials={<SocialLoginContainer />}
      triggerClose={triggerClose}
      totalWager={totalWager}
    />
  );
};

export default AuthContainer;
