const config = {
  url: process.env.REACT_APP_URL,
  apiUrl: process.env.REACT_APP_API_URL,
  cdnUrl: process.env.REACT_APP_CDN_URL,
  buildNumber: process.env.REACT_APP_BUILD_NUMBER,
  centrifugeUrl: process.env.REACT_APP_CENTRIFUGE_URL,
  socials: {
    vk: process.env.REACT_APP_VK_URL,
    telegram: process.env.REACT_APP_TELEGRAM_URL,
    twitter: process.env.REACT_APP_TWITTER_URL,
    discord: process.env.REACT_APP_DISCORD_URL || "https://discord.com/invite/z7ZPRuB",
  },
  reCaptchaKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  itemsUrl: process.env.REACT_APP_ITEMS_URL || `${process.env.REACT_APP_CDN_URL}items.json`,
  coinbaseCheckoutId: process.env.REACT_APP_COINBASE_CHECKOUT_ID
};

export default config;
