import React from "react";
import PropTypes from "prop-types";

const SocialButtons = ({ buttons, onClick }) => {
  return (
    <>
      {buttons.map(({ id, icon, getUrl }) => (
        <button className="soc-auth__link" type="button" key={id} onClick={() => onClick(getUrl)}>
          {icon}
        </button>
      ))}
    </>
  );
};

SocialButtons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      icon: PropTypes.node.isRequired,
      getUrl: PropTypes.func.isRequired,
    }),
  ).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SocialButtons;
