import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUser, setNotifications } from "@/actions/authAction";
import {
  setCentrifugeToken,
  setIsMobile,
  setCurrency,
  setPaymentMethods,
} from "@/actions/mainAction";
import { setLive } from "@/actions/liveAction";
import pageLoading from "@/helpers/pageLoading";
import { useApi } from "@/api";
import useWindowSize from "@/helpers/useWindowSize";

const CurrentStateContainer = () => {
  const windowSize = useWindowSize();
  const { currentState } = useApi();
  const dispatch = useDispatch();
  const token = useSelector(({ auth }) => auth.token);
  const [loading, setLoading] = useState();

  useEffect(() => {
    dispatch(setIsMobile(windowSize.width < 1024));
  }, [windowSize.width]);

  const loadState = async () => {
    if (loading) {
      return;
    }
    setLoading(true);

    currentState()
      .then(({ user, currency, centrifugeToken, live, paymentMethods, notifications }) => {
        dispatch(setUser(user));
        dispatch(setCentrifugeToken(centrifugeToken));
        dispatch(setCurrency(currency));
        if (live) {
          dispatch(setLive(live));
        }
        dispatch(setPaymentMethods(paymentMethods));
        dispatch(setNotifications(notifications));
      })
      .finally(() => {
        pageLoading.hide();
        setLoading(false);
      });
  };

  useEffect(() => {
    pageLoading.hide();
  }, []);

  useEffect(() => {
    loadState();
  }, [token]);

  return <></>;
};

export default CurrentStateContainer;
