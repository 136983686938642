import React from "react";
import { useSelector } from "react-redux";
import CrashHistory from "@/components/games/crash/CrashHistory";

const CrashHistoryContainer = () => {
  const history = useSelector(({ crash }) => crash.history);

  return <CrashHistory games={history} />;
};

export default CrashHistoryContainer;
