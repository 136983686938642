import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  DICE_ROUND_BETTING_STATUS,
  DICE_ROUND_ROLLING_STATUS,
  DICE_ROUND_FINISH_STATUS,
} from "@/constants/diceRoundStatuses";
import {
  DICE_ROUND_BET_WIN_STATUS,
  DICE_ROUND_BET_WAITING_STATUS,
} from "@/constants/diceRoundBetStatuses";
import AnimateNumber from "@/components/AnimateNumber";
import Countdown from "@/components/Countdown";
import DiceRoomPlace from "./DiceRoomPlace";

const DiceRoom = ({ room, authUserId, onJoin, onLeave }) => {
  const { t } = useTranslation();

  const { users, round } = room;
  const { status, bettingTime, endTime, bets } = round;

  const totalBank = bets.reduce((sum, { deposit }) => sum + deposit, 0);

  let winnerUser;
  let winnerBet;

  if (status === DICE_ROUND_FINISH_STATUS) {
    const winnerIndex = bets.findIndex(({ status }) => status === DICE_ROUND_BET_WIN_STATUS);
    if (winnerIndex > -1) {
      winnerBet = bets[winnerIndex];
      winnerUser = users[winnerIndex];
    }
  }

  const userBet = authUserId ? bets.find(({ userId }) => userId === authUserId) : null;

  return (
    <div className="game-wrapper__main">
      <div className="lg:h-620 pt-20 md:pt-50 pb-110 px-15 md:px-30 text-center">
        {winnerUser ? (
          <div>
            <div className="text-4xl sm:text-5xl font-medium">{t("dice.winner")}</div>
            <div className="text-2xl sm:text-3xl text-green mt-6 sm:mt-8">{winnerUser.name}</div>

            <div className="w-300 mx-auto mt-10 text-left">
              <div className="flex justify-center space-x-10 mb-10">
                <svg aria-hidden="true" className="w-30 h-30 fill-current text-white">
                  <use href={`/svg/svg.svg#dice-${winnerBet.result[0]}`} />
                </svg>
                <svg aria-hidden="true" className="w-30 h-30 fill-current text-white">
                  <use href={`/svg/svg.svg#dice-${winnerBet.result[1]}`} />
                </svg>
              </div>
              <div className="h-2 bg-primary-100 bg-opacity-10 w-full">
                <div className="h-full bg-green relative w-150 mx-auto" />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="text-4xl sm:text-5xl font-medium">
              {t(`dice.status.${status}.title`)}
            </div>
            <div className="text-2xl sm:text-3xl text-gray-600 mt-6 sm:mt-8">
              {t(`dice.status.${status}.desc`)}
            </div>
            {status === DICE_ROUND_BETTING_STATUS && bettingTime && <Countdown date={bettingTime} />}
            {status === DICE_ROUND_ROLLING_STATUS && endTime && <Countdown date={endTime} />}
          </div>
        )}

        <div className="max-w-full w-470 mx-auto mt-30 relative text-center">
          <div
            style={{ paddingBottom: "calc(275 / 470 * 100%)" }}
            className="bg-primary-100 bg-opacity-5 rounded-circle"
          />

          <svg className="w-65 h-65 sm:w-90 sm:h-90 fill-current text-black absolute-center">
            <use href="/svg/svg.svg#logo" />
          </svg>

          <div className="absolute inset-x-0 top-0 mt-15 sm:mt-30">
            <div className="text-xl text-white mb-5">{t("dice.totalBank")}</div>
            <div className="flex items-center justify-center">
              <div className="w-25 h-2 bg-primary-100 bg-opacity-5 mr-20" />
              <div className="currency-symbol bg-green text-black mr-10">$</div>
              <span className="text-green">
                $ <AnimateNumber number={totalBank} decimals={2} />
              </span>
              <div className="w-25 h-2 bg-primary-100 bg-opacity-5 ml-20" />
            </div>
          </div>

          <div className="absolute inset-x-0 bottom-0 mb-45 sm:mb-50 text-sm sm:text-base">
            <span className="text-gray-550">{t("dice.deckDesc")}</span>
          </div>

          {userBet && userBet.status !== DICE_ROUND_BET_WAITING_STATUS && (
            <div className="absolute-center">
              <div className="mt-10 space-x-80 sm:space-x-110 flex justify-center">
                <svg
                  aria-hidden="true"
                  className="w-40 sm:w-60 h-40 sm:h-60 fill-current text-white"
                >
                  <use href={`/svg/svg.svg#dice-${userBet.result[0]}`} />
                </svg>
                <svg
                  aria-hidden="true"
                  className="w-40 sm:w-60 h-40 sm:h-60 fill-current text-white"
                >
                  <use href={`/svg/svg.svg#dice-${userBet.result[1]}`} />
                </svg>
              </div>
            </div>
          )}

          {[
            {
              id: 0,
              style: { left: "1.5rem", top: "22%", transform: "translateX(-50%)" },
            },
            {
              id: 1,
              style: { left: "5rem", top: "74%", transform: "translateX(-50%)" },
            },
            {
              id: 2,
              style: { left: "50%", top: "calc(100% - 2.5rem)", transform: "translateX(-50%)" },
            },
            {
              id: 3,
              style: { right: "5rem", top: "74%", transform: "translateX(50%)" },
            },
            {
              id: 4,
              style: { right: "1.5rem", top: "22%", transform: "translateX(50%)" },
            },
          ].map(({ id, style }) => {
            return (
              <DiceRoomPlace
                key={id}
                user={users[id]}
                bet={users[id] ? bets.find(bet => users[id].id === bet.userId) : null}
                style={style}
                authUserId={authUserId}
                onJoin={() => onJoin(id)}
                onLeave={() => onLeave(id)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

DiceRoom.propTypes = {
  room: PropTypes.shape({
    id: PropTypes.number.isRequired,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
      }),
    ),
    round: PropTypes.shape({
      status: PropTypes.number.isRequired,
      bettingTime: PropTypes.number,
      endTime: PropTypes.number,
      rollingAt: PropTypes.string.isRequired,
      finishAt: PropTypes.string.isRequired,
      bets: PropTypes.arrayOf(
        PropTypes.shape({
          userId: PropTypes.number,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
  authUserId: PropTypes.number,
  onJoin: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
};

DiceRoom.defaultProps = {
  authUserId: null,
};

export default DiceRoom;
