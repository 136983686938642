import {
  SET_TOWERS_ROUND,
  SET_TOWERS_BETS,
  NEW_TOWERS_BET,
  SET_TOWERS_BOMB_CELLS,
  SET_TOWERS_ROUND_STATUS,
  SET_TOWERS_ROUND_WITHDRAW,
  SET_TOWERS_ROUND_DIFFICULTY,
  SET_TOWERS_ROUND_DEPOSIT,
  SET_TOWERS_DEMO,
  SET_TOWERS_GAMEBOARD,
  SET_TOWERS_STEPS,
  SET_TOWERS_ROUND_SERVER_SEED,
} from "@/constants";

export const setRound = payload => {
  return {
    type: SET_TOWERS_ROUND,
    payload,
  };
};

export const setRoundDemo = payload => {
  return {
    type: SET_TOWERS_DEMO,
    payload,
  };
};

export const setGameboard = payload => {
  return {
    type: SET_TOWERS_GAMEBOARD,
    payload,
  };
};

export const setRoundStatus = payload => {
  return {
    type: SET_TOWERS_ROUND_STATUS,
    payload,
  };
};

export const setRoundSteps = payload => {
  return {
    type: SET_TOWERS_STEPS,
    payload,
  };
};

export const setRoundBombCells = payload => {
  return {
    type: SET_TOWERS_BOMB_CELLS,
    payload,
  };
};

export const setRoundWithdraw = payload => {
  return {
    type: SET_TOWERS_ROUND_WITHDRAW,
    payload,
  };
};

export const setRoundDeposit = payload => {
  return {
    type: SET_TOWERS_ROUND_DEPOSIT,
    payload,
  };
};

export const setRoundDifficulty = payload => {
  return {
    type: SET_TOWERS_ROUND_DIFFICULTY,
    payload,
  };
};

export const setRoundServerSeed = payload => {
  return {
    type: SET_TOWERS_ROUND_SERVER_SEED,
    payload,
  };
};

export const setBets = payload => {
  return {
    type: SET_TOWERS_BETS,
    payload,
  };
};

export const newBet = payload => {
  return {
    type: NEW_TOWERS_BET,
    payload,
  };
};
