import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useApi } from "@/api";
import noty from "@/libs/noty";

const ProfileChangeEmailConfirmContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { changeEmailConfirm } = useApi();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const newEmailToken = searchParams.get("newEmailToken");
    const oldEmailToken = searchParams.get("oldEmailToken");

    const data = newEmailToken
      ? { paramName: "newEmailToken", token: newEmailToken }
      : { paramName: "oldEmailToken", token: oldEmailToken };

    changeEmailConfirm(data)
      .then(() => {
        noty.success(t("profile.changeEmail.successConfirm"));
      })
      .catch(error => {
        const senError = key => noty.error(t(`profile.changeEmail.${key}`));

        if (error.response && error.response.status === 422) {
          senError("notValidConfirm");
        } else if (error.response && error.response.status === 403) {
          senError(error.response.data.error);
        } else {
          senError("failConfirm");
        }
      })
      .finally(() => {
        history.replace("/");
      });
  });

  return <></>;
};

export default ProfileChangeEmailConfirmContainer;
