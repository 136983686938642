import React, { useState, useRef } from "react";
import useOutsideClickHandler from "@/helpers/useOutsideClickHandler";

const LanguageSelector = ({ current, languages, onChange }) => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);

  const close = () => setOpen(false);

  useOutsideClickHandler(wrapperRef, close);

  return (
    <div ref={wrapperRef} className="relative ml-20 xxl:ml-60 lg:block hidden">
      <button
        type="button"
        className="btn btn--white btn--text btn--rounded btn--md btn--alpha-bg dropdown-toggle-arrow"
        onClick={() => setOpen(!open)}
      >
        <img src={current.image} className="lang-icon mr-10 -ml-7" alt="" />
        <span className="text-white">{current.tag}</span>
      </button>
      <ul className="dropdown-menu dropdown-menu-end" style={{display: open ? 'block': 'none'}}>
        {languages.map(({ id, label, tag, image }) => (
        <li key={id}>
          <a className="dropdown-item" href="#"
             onClick={(e) => {
               e.preventDefault();
               onChange(id);
               close();
               return false;
             }}>
            <img src={image}  className="lang-icon mr-10" alt="" />
            {tag}
          </a>
        </li>
        ))}
      </ul>
    </div>
  );
};

export default LanguageSelector;
