import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import noty from "@/libs/noty";
import ProfileLinkAccountsContainer from "@/containers/profile/ProfileLinkAccountsContainer";
import ProfileGeneral from "@/components/profile/ProfileGeneral";

const ProfileGeneralContainer = () => {
  const user = useSelector(({ auth }) => auth.user);
  const { changeReferrer } = useApi();
  const { t } = useTranslation();
  const [pending, setPending] = useState(false);

  const onChangeReferrer = code => {
    setPending(true);
    if (pending) {
      return false;
    }
    return changeReferrer(code)
      .then(() => {
        noty.success(t("profile.referrer.changeSuccess"));
        return true;
      })
      .catch(() => {
        noty.error("profile.referrer.changeFail");
        return false;
      })
      .finally(() => setPending(false));
  };

  return (
    <ProfileGeneral user={user} onChangeReferrer={onChangeReferrer}>
      <ProfileLinkAccountsContainer />
    </ProfileGeneral>
  );
};

export default ProfileGeneralContainer;
