import React from "react";

const ProfileUsersBlocked = () => {
  return (
    <div>
      <div className="account-modal-title mb-20">Personal Info</div>

      <div className="text-gray-600">
        <p>Here you can see the entire list of users that you hooked up in the chat on the site</p>
      </div>
      <div className="mt-30">
        <ul className="flex flex-wrap -m-5">
          <li className="p-5">
            <button className="btn btn--gray btn--rounded btn--sm pr-15 cursor-pointer">
              <svg aria-hidden="true" className="w-16 h-16 fill-current text-gray-500 mr-25 -ml-5">
                <use href="/svg/svg.svg#cross" />
              </svg>
              <span>Username</span>
            </button>
          </li>
          <li className="p-5">
            <button className="btn btn--gray btn--rounded btn--sm pr-15 cursor-pointer">
              <svg aria-hidden="true" className="w-16 h-16 fill-current text-gray-500 mr-25 -ml-5">
                <use href="/svg/svg.svg#cross" />
              </svg>
              <span>Username</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProfileUsersBlocked;
