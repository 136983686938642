import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Modal from "@/components/Modal";

const AuthModal = ({ modalRef, login, register, socials, totalWager, triggerClose }) => {
  const { t } = useTranslation();
  const [isLogin, setIsLogin] = useState(true);

  const open = () => {
    modalRef.current.open();
  };

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.open();
      setIsLogin(false);
    }
  }, [modalRef]);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.close();
    }
  }, [triggerClose]);

  return (
    <>
      <button
        type="button"
        className="btn btn--md btn--primary btn--rounded ml-10"
        onClick={() => {
          setIsLogin(false);
          open();
        }}
      >
        {t("auth.registerButton")}
      </button>
      <button
        type="button"
        className="btn btn--md btn--primary btn--outlined btn--rounded"
        onClick={() => {
          setIsLogin(true);
          open();
        }}
      >
        {t("auth.loginButton")}
      </button>

      <Modal ref={modalRef} showCloseButton dialogClassName="modal-dialog-centered" contentClassName="auth-form">
        <div className="flex items-center justify-between mt-20 lg:mt-0 mb-25 sm:mb-45">
          <div className="logo">
            <svg aria-hidden="true">
              <use href="/svg/svg.svg#logo" />
            </svg>
            <span>{t("auth.titleModal")}</span>
          </div>
          <div className="flex space-x-10">
            <button
              type="button"
              onClick={() => setIsLogin(true)}
              className={`btn btn--sm btn--outlined btn--rounded px-20 text-lg ${
                isLogin
                  ? "[ border-green pointer-events-none ]"
                  : "[ border-black-600 text-gray-light hover:text-white ]"
              }`}
            >
              {t("auth.loginButton")}
            </button>
            <button
              type="button"
              onClick={() => setIsLogin(false)}
              className={`btn btn--sm btn--outlined btn--rounded px-20 text-lg ${
                !isLogin
                  ? "[ border-green pointer-events-none ]"
                  : "[ border-black-600 text-gray-light hover:text-white ]"
              }`}
            >
              {t("auth.registerButton")}
            </button>
          </div>
        </div>
        <div className="w-full sm:w-300 max-w-full ml-auto">
          {isLogin ? login : register}
          <div className="line-devider line-devider--has-text mt-55 mb-30">
            <span>{t("auth.orContinue")}</span>
          </div>
        </div>
        <div className="sm:flex flex-row-reverse justify-between items-center text-center">
          <div className="text-center sm:w-300 max-w-full">
            <div className="soc-auth">{socials}</div>
          </div>
          <div className="mt-20 sm:mt-0">
            <span className="text-gray mr-5">{t("auth.wagerAllTime")}</span>
            <span className="text-gray-light">{totalWager.toLocaleString("en-US")}</span>
          </div>
        </div>
        <div className="text-gray-light text-opacity-50 prose leading-tight text-tiny mt-35">
          <p>{t("auth.textFooter")}</p>
        </div>
      </Modal>
    </>
  );
};

AuthModal.propTypes = {
  modalRef: PropTypes.shape({}).isRequired,
  login: PropTypes.node.isRequired,
  register: PropTypes.node.isRequired,
  socials: PropTypes.node.isRequired,
  totalWager: PropTypes.number.isRequired,
  triggerClose: PropTypes.bool.isRequired,
};

export default AuthModal;
