import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import noty from "@/libs/noty";
import referralCode from "@/helpers/referralCode";
import RegisterForm from "@/components/auth/RegisterForm";

const RegisterContainer = ({ successCallback }) => {
  const [pending, setPending] = useState(false);
  const { i18n, t } = useTranslation();
  const { registration } = useApi();

  const lang = i18n.language.slice(0, 2);

  const submit = (data, cb) => {
    if (pending) {
      return;
    }
    setPending(true);

    const code = referralCode.get();
    if (code) {
      // eslint-disable-next-line no-param-reassign
      data.referralCode = code;
    }

    registration({ ...data, lang })
      .then(() => {
        noty.success(t("auth.register.success"));
        referralCode.remove();
        successCallback();
        cb();
      })
      .catch(() => {
        noty.error(t("auth.register.error"));
      })
      .finally(() => {
        setPending(false);
      });
  };

  return <RegisterForm onSubmit={submit} pending={pending} />;
};

RegisterContainer.propTypes = {
  successCallback: PropTypes.func.isRequired,
};

export default RegisterContainer;
