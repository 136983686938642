export const PAYMENT_TYPE_MONEY = "money";
export const PAYMENT_TYPE_ENOT = "enot";
export const PAYMENT_TYPE_SKINS = "skins";
export const PAYMENT_TYPE_QIWI = "qiwi";
export const PAYMENT_TYPE_GAMEMONEY = "gamemoney";
export const PAYMENT_TYPE_BUY_CODE = "buy-code";
export const PAYMENT_TYPE_PIASTRIX = "piastrix";
export const PAYMENT_TYPE_EXBASE = "exbase";
export const PAYMENT_TYPE_TEST = "test";

