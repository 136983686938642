import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AnimateNumber from "@/components/AnimateNumber";

const CrashStats = ({ statistic }) => {
  const { t } = useTranslation();
  const { count, totalDeposit } = statistic;

  return (
    <div className="flex items-center text-gray-600 mb-10 px-20">
      <span>
        <AnimateNumber number={count} /> {t("crash.stats.count")}
      </span>
      <span className="ml-auto">
        $ <AnimateNumber number={Number(totalDeposit)} decimals={2} />
      </span>
    </div>
  );
};

CrashStats.propTypes = {
  statistic: PropTypes.shape({
    count: PropTypes.number.isRequired,
    totalDeposit: PropTypes.string.isRequired,
  }).isRequired,
};

export default CrashStats;
