import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Fair = ({ items }) => {
  const [active, setActive] = useState(items[0]);
  const { t } = useTranslation();

  return (
    <div className="bg-black rounded">
      <div className="px-15 sm:px-30 py-20 border-b-4 border-black-800">{t("fair.page.title")}</div>
      <div className="px-15 sm:px-30 pb-20">
        <nav className="secondary-nav secondary-nav--has-marker -mt-4">
          <ul
            style={{
              "--marker-offset-top": "0px",
              "--marker-offset-left": "0px",
              "--marker-width": "99.3594px",
              "--marker-height": "62px",
            }}
          >
            {items.map(item => (
              <li key={item}>
                <button
                  type="button"
                  className={`secondary-nav__link text-xl ${active === item ? "active" : ""}`}
                  onClick={() => setActive(item)}
                >
                  {t(`fair.page.item.${item}.name`)}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        <div
          className="prose text-gray-700"
          dangerouslySetInnerHTML={{
            __html: t(`fair.page.item.${active}.content`),
          }}
        />
      </div>
    </div>
  );
};

Fair.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Fair;
