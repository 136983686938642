import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSubscribe } from "@/libs/centrifuge";
import { newLineBet } from "@/actions/minesAction";

const MinesSubscriberContainer = () => {
  const dispatch = useDispatch();
  const subscribe = useSubscribe();

  useEffect(() => {
    const unsubscribe = subscribe("mines-end-round", round => {
      dispatch(newLineBet(round));
    });

    return () => unsubscribe();
  });

  return <></>;
};

export default MinesSubscriberContainer;
