import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

const RouletteGameboardCountdown = ({ date }) => {
  const text = useRef();
  const progress = useRef();

  useEffect(() => {
    let timei = date;
    const timer = setInterval(() => {
      timei -= 10;
      const time = timei / 1000;

      text.current.textContent = `${time.toFixed(2)}s`;
      progress.current.style.width = `${time * 10}%`;

      if (time <= 0.01) {
        clearInterval(timer);
        text.current.textContent = "";
      }
    }, 10);
    return () => {
      clearInterval(timer);
    };
  }, [text, progress, date]);

  return (
    <>
      <div className="w-300 mx-auto mt-15 text-left">
        <div className="text-2xl text-white mb-10 h-18" ref={text} />
        <div className="h-2 bg-primary-100 bg-opacity-10 w-full">
          <div className="h-full bg-primary relative" ref={progress}>
            <div className="h-6 absolute top-1/2 right-0 transform -translate-y-1/2 w-2 bg-inherit" />
          </div>
        </div>
      </div>
    </>
  );
};

RouletteGameboardCountdown.propTypes = {
  date: PropTypes.number.isRequired,
};

export default RouletteGameboardCountdown;
