import React from "react";
import { useSelector } from "react-redux";
import MinesLastWins from "@/components/games/mines/MinesLastWins";

const MinesLastWinsContainer = () => {
  const line = useSelector(({ mines }) => mines.line);

  return <MinesLastWins rounds={line} />;
};

export default MinesLastWinsContainer;
