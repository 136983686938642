import _ from "lodash";
import {
  TOWERS_EASY_DIFFICULTY,
  TOWERS_MEDIUM_DIFFICULTY,
  TOWERS_HARD_DIFFICULTY,
} from "@/constants/towersDifficulty";

export default level => {
  let demo = new Array(8).fill(null);

  if (level === TOWERS_EASY_DIFFICULTY) {
    demo = demo.map(() => {
      const isPoop = _.random(0, 2);
      return new Array(3).fill(null).map((v, index) => (isPoop === index ? 1 : 0));
    });
  }

  if (level === TOWERS_MEDIUM_DIFFICULTY) {
    demo = demo.map(() => {
      const isPoop = _.random(0, 1);
      return new Array(2).fill(null).map((v, index) => (isPoop === index ? 1 : 0));
    });
  }

  if (level === TOWERS_HARD_DIFFICULTY) {
    demo = demo.map(() => {
      const first = _.random(0, 2);
      const getUniq = () => {
        const cellId = _.random(0, 2);
        if (first === cellId) {
          return getUniq();
        }
        return cellId;
      };

      const second = getUniq();

      return new Array(3)
        .fill(null)
        .map((v, index) => (first === index || second === index ? 1 : 0));
    });
  }

  return demo;
};
