import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ProfileLinkAccounts = ({ accounts, onLink, onUnlink }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="account-modal-title">{t("profile.linkAccounts.title")}</div>
      <div className="text-gray-600 mt-20">{t("profile.linkAccounts.desc")}</div>

      <div className="mt-30">
        <ul className="space-y-15">
          {accounts.map(({ icon, type, name }) => {
            return (
              <li key={type}>
                <div className="flex items-center">
                  <div className="w-45 h-45 rounded-circle border-2 border-current text-gray-600 text-opacity-75 relative">
                    {icon}
                  </div>
                  <div className="pl-15">
                    —<span className="pl-10">{t(`profile.linkAccounts.types.${type}`)}</span>
                  </div>
                  {name ? (
                    <button
                      type="button"
                      onClick={() => onUnlink(type)}
                      className="btn btn--green btn--rounded btn--sm pr-15 cursor-pointer ml-25"
                    >
                      <svg aria-hidden="true" className="w-16 h-16 fill-current mr-25 -ml-5">
                        <use href="/svg/svg.svg#cross" />
                      </svg>
                      <span>{name}</span>
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => onLink(type)}
                      className="btn btn--green btn--sm btn--text btn--alpha-bg ml-auto"
                    >
                      {t(`profile.linkAccounts.linkButton.${type}`)}
                    </button>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

ProfileLinkAccounts.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.number.isRequired,
      icon: PropTypes.node.isRequired,
      name: PropTypes.string,
    }),
  ).isRequired,
  onLink: PropTypes.func.isRequired,
  onUnlink: PropTypes.func.isRequired,
};

export default ProfileLinkAccounts;
