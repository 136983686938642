import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  DICE_ROUND_BET_WAITING_STATUS,
  DICE_ROUND_BET_WIN_STATUS,
} from "@/constants/diceRoundBetStatuses";

const DiceRoomPlace = ({ authUserId, user, bet, onJoin, onLeave, style }) => {
  const { t } = useTranslation();

  const showResult = bet && bet.status !== DICE_ROUND_BET_WAITING_STATUS;
  const isWinner = bet && bet.status === DICE_ROUND_BET_WIN_STATUS;

  let content = (
    <>
      <button
        type="button"
        onClick={onJoin}
        className="w-50 h-50 bg-primary rounded-full mx-auto relative flex items-center justify-center"
      >
        <svg aria-hidden="true" className="fill-current text-white w-20 h-20">
          <use href="/svg/svg.svg#add" />
        </svg>
      </button>

      <div className="text-gray-600 mt-10 sm:mt-15">{t("dice.place.free")}</div>
    </>
  );

  if (showResult) {
    content = (
      <>
        <div
          className={`w-50 h-50 bg-primary rounded-full mx-auto relative bg-clip-content p-2 border-3 border-primary flex items-center justify-center text-2xl ${
            isWinner ? "bg-green border-green" : ""
          }`}
        >
          {bet.points}
          {authUserId === user.id && (
            <div className="w-20 h-20 bg-green rounded-full absolute left-0 top-0 -mt-4 -ml-4">
              <svg aria-hidden="true" className="w-10 h-10 fill-current text-white absolute-center">
                <use href="/svg/svg.svg#basic-tick" />
              </svg>
            </div>
          )}
        </div>
        <div className="text-white mt-10 sm:mt-15">{user.name}</div>
        <div className="mt-10 space-x-6 flex justify-center">
          <svg aria-hidden="true" className="w-25 h-25 fill-current text-white">
            <use href={`/svg/svg.svg#dice-${bet.result[0]}`} />
          </svg>
          <svg aria-hidden="true" className="w-25 h-25 fill-current text-white">
            <use href={`/svg/svg.svg#dice-${bet.result[1]}`} />
          </svg>
        </div>
      </>
    );
  }

  if (user && !showResult) {
    content = (
      <div className="dice-place relative">
        <div className="w-50 h-50 bg-gray-370 rounded-full mx-auto relative bg-clip-content p-2 border-3 border-gray-370 flex items-center justify-center">
          {authUserId === user.id && (
            <>
            <button
              type="button"
              className="absolute top-0 left-0 w-50 h-50 bg-gray-370 rounded-full flex items-center justify-center"
              onClick={onLeave}
            >
              <svg aria-hidden="true" className="fill-current text-primary-100 w-15 h-15">
                <use href="/svg/svg.svg#close" />
              </svg>
            </button>
            <div className="w-20 h-20 bg-green rounded-full absolute left-0 top-0 -mt-4 -ml-4">
              <svg aria-hidden="true" className="w-10 h-10 fill-current text-white absolute-center">
                <use href="/svg/svg.svg#basic-tick" />
              </svg>
            </div>
            </>
          )}
          <svg aria-hidden="true" className="fill-current text-white w-20 h-20">
            <use href="/svg/svg.svg#man-user" />
          </svg>
        </div>

        <div className="text-white mt-10 sm:mt-15">{user.name || t("hidden")}</div>
      </div>
    );
  }

  return (
    <div
      className={`absolute ${(user && !bet) || (showResult && !isWinner) ? "opacity-50" : ""}`}
      style={style}
    >
      {content}
    </div>
  );
};

DiceRoomPlace.propTypes = {
  authUserId: PropTypes.number,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }),
  bet: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.number.isRequired,
    result: PropTypes.arrayOf(PropTypes.number),
    points: PropTypes.number,
  }),
  onJoin: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
};

DiceRoomPlace.defaultProps = {
  authUserId: null,
  user: null,
  bet: null,
};

export default DiceRoomPlace;
