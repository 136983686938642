const inLangDefault = "ru";

const inLangs = {
  1: "ru",
  2: "en",
  3: "es",
  4: "de",
};

const outLangDefault = 1;

const outLangs = {
  ru: 1,
  en: 2,
  es: 3,
  de: 4,
};

export default {
  in: id => inLangs[id] || inLangDefault,
  out: id => outLangs[id] || outLangDefault,
};
