export default {
  nav: {
    terms: "Terms",
    tos: "ToS",
    privacy: "Privacy",
    fair: "Fair",
    vip: "VIP Club",
    aml: "AML",
    responsible: "Responsible Gaming",
  },
  encryption: '<span class="text-green-dark">69</span><span>- bit encryption</span>',
  copyright: `midasly.com is owned and operated by Blackhole N.V., a company registered and established under the laws of Curaçao. Blackhole N.V is licensed and regulated by Gaming Services Provider N.V. Blackhole N.V.'s registration number is 156046 and its registered address is Abraham de Veerstraat 9, Willemstad, Curaçao.
Qroqo Limited is a subsidiary of Blackhole N.V., acting as an Agent on behalf of Blackhole N.V., registered in Cyprus with registration number ΗΕ 429083 and registered address Georgiou Gennadiou st. 10, flat 203, 3041, Limassol, Сyprus.`,
};
