import {
  SET_LIVE_COUNT,
  SET_LIVE_ALL,
  SET_LIVE_HIGH,
  SET_LIVE_LUCKY,
  SET_LIVE,
  ADD_LIVE_HIGH,
  ADD_LIVE_LUCKY,
  ADD_LIVE_ALL,
} from "@/constants";

const initialState = {
  count: 0,
  all: [],
  high: [],
  lucky: [],
};

const live = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_LIVE_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    case SET_LIVE:
      return {
        ...action.payload,
      };

    case SET_LIVE_ALL:
      return {
        ...state,
        all: action.payload,
      };

    case SET_LIVE_HIGH:
      return {
        ...state,
        high: action.payload,
      };

    case SET_LIVE_LUCKY:
      return {
        ...state,
        lucky: action.payload,
      };

    case ADD_LIVE_ALL:
      return {
        ...state,
        all: [action.payload, ...state.all].slice(0, 10),
      }
    case ADD_LIVE_HIGH:
      return {
        ...state,
        high: [action.payload, ...state.high].slice(0, 10),
      }
    case ADD_LIVE_LUCKY:
      return {
        ...state,
        lucky: [action.payload, ...state.lucky].slice(0, 10),
      }

    default:
      return state;
  }
};

export default live;
