import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ReferralLevel = ({ levelId, levels, total }) => {
  const { t } = useTranslation();

  const level = levels[levelId];

  let nextLevelId = levelId + 1;
  let nextLevel = levels[nextLevelId];

  if (!nextLevel) {
    nextLevel = level;
    nextLevelId = levelId;
  }

  const progress = (total * 100) / nextLevel.deposit;

  return (
    <div className="mt-20">
      <div className="flex items-center justify-between px-7 mb-15">
        <div>
          <span className="text-black-900">{t("referral.level")} {levelId}</span>
          <span className="text-gray-500">- {level.percent}% {t("referral.commission")}</span>
        </div>
        <div>
          <span className="text-black-900">{t("referral.level")} {nextLevelId}</span>
          <span className="text-gray-150">- {nextLevel.percent}% {t("referral.commission")}</span>
        </div>
      </div>
      <div className="h-3 bg-gray-100 w-full">
        <div
          className="h-full bg-green relative"
          style={{ width: `${(progress > 100 ? 100 : progress).toFixed(2)}%` }}
        >
          <div className="h-10 absolute top-1/2 right-0 transform -translate-y-1/2 w-2 bg-inherit" />
        </div>
      </div>
      <div className="flex items-center justify-between px-7 text-gray-150 text-sm mt-15">
        <span>$ {level.deposit}</span>
        <span>$ {nextLevel.deposit}</span>
      </div>
    </div>
  );
};

ReferralLevel.propTypes = {
  levels: PropTypes.shape({
    1: PropTypes.shape({
      percent: PropTypes.number.isRequired,
      bonus: PropTypes.number.isRequired,
      deposit: PropTypes.number.isRequired,
    }),
  }).isRequired,
  total: PropTypes.number.isRequired,
  levelId: PropTypes.number.isRequired,
};

export default ReferralLevel;
