import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const DEFAULT_LIMIT_LENGTH = 300;

const ChatForm = ({ formRef, onSubmit, value, onChange, disabled, noneLimit }) => {
  const { t } = useTranslation();

  const limitLength = noneLimit ? 30000 : DEFAULT_LIMIT_LENGTH;

  const limit = limitLength - value.length;
  const hasLimit = limitLength ? limit < 0 : false;

  const isDisabled = disabled || hasLimit || value.length < 1;

  const submit = () => {
    if (!isDisabled) {
      onSubmit();
    }
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      submit();
    }
  };

  const change = e => {
    const newText = e.target.value;

    if (newText.length > limitLength) {
      return;
    }

    onChange(newText);
  };

  return (
    <div className="pt-15 lg:pt-30">
      <div className="block w-full bg-black-800 rounded-t mb-3 relative">
        <input
          id="chat-message-field"
          type="text"
          className="text-2xl lg:text-base appearance-none bg-transparent outline-none w-full resize-none h-60 px-15 pt-15 pb-25 placeholder-gray-700"
          placeholder={t("chat.placeholder")}
          ref={formRef}
          onKeyUp={handleKeyPress}
          value={value}
          onChange={change}
          onBlur={change}
          autoComplete="off"
        />
        <div className="absolute left-0 bottom-0 pl-15 pb-12 text-tiny text-gray-450">
          {value.length}/{limitLength}
        </div>
        <button
          type="button"
          className="btn btn--md btn--white btn--icon absolute bottom-0 right-0 mb-12 mr-12"
          disabled={isDisabled}
          onClick={submit}
        >
          <svg aria-hidden="true" className="fill-current w-22 h-22">
            <use href="/svg/svg.svg#send" />
          </svg>
        </button>
      </div>
      <div className="bg-black-800 rounded-b h-35 px-10 flex items-center text-sm">
        <a href="#" className="btn p-5 text-gray-700 hover:text-gray-light">
          {t("chat.rain")}
        </a>
        <a href="#" className="btn p-5 text-gray-700 hover:text-gray-light">
          {t("chat.rules.button")}
        </a>
      </div>
    </div>
  );
};

ChatForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  formRef: PropTypes.shape({}).isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  noneLimit: PropTypes.bool.isRequired,
};

export default ChatForm;
