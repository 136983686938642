import React, { useState } from "react";
import PropTypes from "prop-types";

const UserMenuLink = ({ link, className, children }) => {
  const [ref, setRef] = useState();
  const { id, icon, component, onClick } = link;

  return (
    <>
      <button
        type="button"
        className={className}
        onClick={
          onClick || (() => {
                if (ref.current) {
                  ref.current.open();
                }
              })
        }
      >
        {children}
      </button>
      {component &&
        component({
          onSetRef: modalRef => setRef(modalRef),
        })}
    </>
  );
};

UserMenuLink.propTypes = {
  link: PropTypes.shape({
    id: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    component: PropTypes.func,
    onClick: PropTypes.func,
  }).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};


export default UserMenuLink;
