export default {
  title: "Affiliate panel",
  link: "Your referral link",
  copyLink: "Copy URL",
  isCopy: "Copied",
  refresh: "Refresh",
  withdraw: "Claim",
  stats: {
    referralsCount: "Referrals",
    earnsCount: "Depositors",
    total: "Deposits",
  },
  level: "Level",
  commission: "Commission",
  earns: {
    title: "Referral Earnings",
    chartTitle: "Earnings",
    days: "days",
    range: "Period",
  }
}
