import React from "react";
import GameList from "@/components/GameList";
import { useSelector } from "react-redux";

const GameListContainer = () => {
  const user = useSelector(({ auth }) => auth.user);

  const isAuth = !!user;

  const games = [
    {
      id: "crash",
      path: "/crash",
      show: true,
      children: (
        <div className="crash-preview">
          <span />
          <span />
          <span />
          <span />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
        </div>
      ),
    },
    {
      id: "gameshow",
      show: true,
      path: "/casino/evolution:crazytime",
      children: (
        <div className="gameshow-preview">
          <span />
          <span />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
        </div>
      ),
    },
    {
      id: "blackjack",
      show: true,
      games: [
        {
          id: "softswiss:WildTexas",
          image: "/img/game-preview.jpg",
          path: "/casino/softswiss:WildTexas",
          type: "WildTexas",
        },
        {
          id: "softswiss:ScratchDice",
          image: "/img/game-preview.jpg",
          path: "/casino/softswiss:ScratchDice",
          type: "Evolution",
        },
        {
          id: "softswiss:JacksOrBetter",
          image: "/img/game-preview.jpg",
          path: "/casino/softswiss:JacksOrBetter",
          type: "JacksOrBetter",
        },
      ],
      children: (
        <div className="blackjack-preview">
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
        </div>
      ),
    },
    {
      id: "roulette",
      path: "/roulette",
      show: isAuth,
      children: (
        <div className="roulette-preview">
          <span />
          <span />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
        </div>
      ),
    },
    {
      id: "slots",
      show: true,
      games: [
        {
          id: "acceptance:secondary_test",
          image: "/img/game-preview.jpg",
          path: "/casino/acceptance:secondary_test",
        },
        {
          id: "acceptance:test",
          image: "/img/game-preview.jpg",
          path: "/casino/acceptance:test",
        },
        {
          id: "softswiss:AllLuckyClover",
          image: "/img/game-preview.jpg",
          path: "/casino/softswiss:AllLuckyClover",
        },
      ],
      children: (
        <div className="slots-preview">
          <span />
          <span />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
        </div>
      ),
    },
    {
      id: "coinflip",
      path: "/coinflip",
      show: isAuth,
      children: (
        <div className="coinflip-preview">
          <span />
          <span />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
        </div>
      ),
    },
    {
      id: "mines",
      path: "/mines",
      show: isAuth,
      children: (
        <div className="mines-preview">
          <span />
          <span />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
        </div>
      ),
    },
    {
      id: "dice",
      path: "/dice",
      show: isAuth,
      children: (
        <div className="dice-preview">
          <span />
          <span />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
        </div>
      ),
    },
    {
      id: "towers",
      path: "/towers",
      show: isAuth,
      children: (
        <div className="towers-preview">
          <span />
          <span />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
        </div>
      ),
    },
  ];

  return <GameList games={games} />;
};

export default GameListContainer;
