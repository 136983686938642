import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActiveRoom } from "@/actions/diceAction";
import DiceSelectRoom from "@/components/games/dice/DiceSelectRoom";
import DiceControlContainer from "@/containers/games/dice/DiceControlContainer";

const DiceSidebarContainer = () => {
  const dispatch = useDispatch();
  const rooms = useSelector(({ dice }) => dice.rooms);
  const activeRoom = useSelector(({ dice }) => dice.activeRoom);

  return (
    <div className="game-wrapper__sidebar">
      <div className="game-sidebar-inner">
        <div>
          {rooms.length && (
            <DiceSelectRoom
              rooms={rooms}
              setActiveRoom={room => {
                if (!activeRoom || (activeRoom && activeRoom.id !== room.id)) {
                  dispatch(setActiveRoom(room));
                }
              }}
              activeRoomId={activeRoom ? activeRoom.id : null}
            />
          )}
          <DiceControlContainer />
        </div>
      </div>
    </div>
  );
};

export default DiceSidebarContainer;
