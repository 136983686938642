import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import RouletteNumberImage from "@/components/games/roulette/RouletteNumberImage";

const RouletteHistory = ({ rounds }) => {
  const { t } = useTranslation();

  return (
    <div className="flex overflow-hidden items-center lg:pl-20 text-lg">
      <div className="text-gray-250 mr-10 flex-shrink-0">{t("roulette.history")}</div>
      <svg
        aria-hidden="true"
        className="fill-current w-10 h-10 text-gray-250 mr-20 opacity-40 flex-shrink-0"
      >
        <use href="/svg/svg.svg#arrow-right" />
      </svg>
      <ul className="flex overflow-hidden font-medium">
        {rounds.map(({ id, winnerNumber }) => (
          <li key={id} className="px-5 text-green flex-shrink-0">
            <RouletteNumberImage
              number={winnerNumber}
              className="w-35 h-35 rounded-full object-contain mr-10"
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

RouletteHistory.propTypes = {
  rounds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      winnerNumber: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default RouletteHistory;
