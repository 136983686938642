import React from "react";
import GameListContainer from "@/containers/GameListContainer";

const HomeContainer = () => {
  return (
    <>
      <GameListContainer />
    </>
  );
};

export default HomeContainer;
