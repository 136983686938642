export const AUTH_SET_TOKEN = "AUTH_SET_TOKEN";
export const AUTH_CLEAR = "AUTH_CLEAR";

export const SET_USER = "SET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const SET_USER_LANG = "SET_USER_LANG";
export const SET_USER_BALANCE = "SET_USER_BALANCE";
export const SET_USER_CLIENT_SEED = "SET_USER_CLIENT_SEED";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const NEW_NOTIFICATION = "NEW_NOTIFICATION";

export const SET_CURRENCY = "SET_CURRENCY";
export const SET_SOUNDS_ON = "SET_SOUNDS_ON";
export const SET_IS_MOBILE = "SET_IS_MOBILE";
export const SET_CENTRIFUGE_TOKEN = "SET_CENTRIFUGE_TOKEN";
export const SET_OPEN_CASHIER = "SET_OPEN_CASHIER";
export const SET_OPEN_REFERRAL = "SET_OPEN_REFERRAL";
export const SET_OPEN_AUTH = "SET_OPEN_AUTH";
export const SET_PAYMENT_METHODS = "SET_PAYMENT_METHODS";

export const SET_CHAT_OPEN = "SET_CHAT_OPEN";
export const SET_CHAT_ONLINE = "SET_CHAT_ONLINE";
export const SET_CHAT_MESSAGES = "SET_CHAT_MESSAGES";
export const ADD_NEW_CHAT_MESSAGE = "ADD_NEW_CHAT_MESSAGE";
export const REMOVE_CHAT_MESSAGES_BY_USER_ID = "REMOVE_CHAT_MESSAGES_BY_USER_ID";
export const REMOVE_CHAT_MESSAGE = "REMOVE_CHAT_MESSAGE";
export const CLEAR_CHAT = "CLEAR_CHAT";
export const SET_CHAT_HIDE_STICKERS = "SET_CHAT_HIDE_STICKERS";
export const SET_CHAT_PIN_MESSAGE = "SET_CHAT_PIN_MESSAGE";

export const SET_TOTAL_WAGER = "SET_TOTAL_WAGER";

export const SET_MINES_ROUND = "SET_MINES_ROUND";
export const SET_MINES_ROUND_DEMO = "SET_MINES_ROUND_DEMO";
export const SET_MINES_ROUND_AMOUNT = "SET_MINES_BET_AMOUNT";
export const SET_MINES_ROUND_BOMBS = "SET_MINES_ROUND_BOMBS";
export const SET_MINES_ROUND_BOMB_CELLS = "SET_MINES_ROUND_BOMB_CELLS";
export const SET_MINES_ROUND_ID = "SET_MINES_ROUND_ID";
export const SET_MINES_ROUND_STEPS = "SET_MINES_STEPS";
export const SET_MINES_ROUND_STATUS = "SET_MINES_STATUS";
export const SET_MINES_ROUND_WITHDRAW = "SET_MINES_ROUND_WITHDRAW";
export const SET_MINES_ROUND_SERVER_SEED = "SET_MINES_ROUND_SERVER_SEED";
export const SET_MINES_LINE = "SET_MINES_LINE";
export const NEW_MINES_LINE_BET = "NEW_MINES_LINE_BET";

export const SET_CRASH_STATE = "SET_CRASH_STATE";
export const SET_CRASH_BET = "SET_CRASH_BET";
export const SET_CRASH_STATUS = "SET_CRASH_STATUS";
export const SET_CRASH_HISTORY = "SET_CRASH_HISTORY";
export const ADD_CRASH_HISTORY = "ADD_CRASH_HISTORY";
export const SET_CRASH_STATISTIC = "SET_CRASH_STATISTIC";
export const SET_CRASH_BETS = "SET_CRASH_BETS";
export const SET_CRASH_DELTA = "SET_CRASH_DELTA";
export const SET_CRASH_CRASH = "SET_CRASH_CRASH";
export const NEW_CRASH_BET = "NEW_CRASH_BET";
export const UPDATE_CRASH_BET = "UPDATE_CRASH_BET";
export const CRASH_CRASH_BETS = "CRASH_CRASH_BETS";
export const UPDATE_USER_CRASH_BET = "UPDATE_USER_CRASH_BET";
export const SET_CRASH_HASH = "SET_CRASH_HASH";
export const SET_CRASH_COEFFICIENT = "SET_CRASH_COEFFICIENT";
export const SET_CRASH_AUTO_COEFFICIENT = "SET_CRASH_AUTO_COEFFICIENT";
export const SET_CRASH_AUTO_COEFFICIENT_SETTINGS = "SET_CRASH_AUTO_COEFFICIENT_SETTINGS";

export const SET_TOWERS_ROUND = "SET_TOWERS_ROUND";
export const SET_TOWERS_ROUND_DEPOSIT = "SET_TOWERS_ROUND_DEPOSIT";
export const SET_TOWERS_ROUND_DIFFICULTY = "SET_TOWERS_ROUND_DIFFICULTY";
export const SET_TOWERS_ROUND_WITHDRAW = "SET_TOWERS_ROUND_WITHDRAW";
export const SET_TOWERS_ROUND_STATUS = "SET_TOWERS_ROUND_STATUS";
export const SET_TOWERS_ROUND_SERVER_SEED = "SET_TOWERS_ROUND_SERVER_SEED";
export const SET_TOWERS_GAMEBOARD = "SET_TOWERS_GAMEBOARD";
export const SET_TOWERS_BETS = "SET_TOWERS_BETS";
export const NEW_TOWERS_BET = "NEW_TOWERS_BET";
export const SET_TOWERS_DEMO = "SET_TOWERS_DEMO";
export const SET_TOWERS_STEPS = "SET_TOWERS_STEPS";
export const SET_TOWERS_BOMB_CELLS = "SET_TOWERS_BOMB_CELLS";

export const SET_ROULETTE_ROUND = "SET_ROULETTE_ROUND";
export const SET_ROULETTE_BETS = "SET_ROULETTE_BETS";
export const NEW_ROULETTE_BET = "NEW_ROULETTE_BET";
export const SET_ROULETTE_HISTORY = "SET_ROULETTE_HISTORY";
export const NEW_ROULETTE_HISTORY = "NEW_ROULETTE_HISTORY";
export const FINISH_ROULETTE_BETS = "FINISH_ROULETTE_BETS";

export const SET_DICE_ROOMS = "SET_DICE_ROOMS";
export const SET_DICE_ACTIVE_ROOM = "SET_DICE_ACTIVE_ROOM";
export const SET_DICE_ROOM_USERS_COUNT = "SET_DICE_ROOM_USERS_COUNT";
export const SET_DICE_USER_TO_ACTIVE_ROOM = "SET_DICE_USER_TO_ACTIVE_ROOM";
export const SET_DICE_ACTIVE_ROOM_ROUND = "SET_DICE_ACTIVE_ROOM_ROUND";
export const SET_DICE_ACTIVE_ROOM_ROUND_BET = "SET_DICE_ACTIVE_ROOM_ROUND_BET";

export const SET_COINFLIP_ROUNDS = "SET_COINFLIP_ROUNDS";
export const SET_COINFLIP_ACTIVE_ROUND = "SET_COINFLIP_ACTIVE_ROUND";
export const SET_COINFLIP_BETS = "SET_COINFLIP_BETS";
export const NEW_COINFLIP_ROUND = "NEW_COINFLIP_ROUND";
export const UPDATE_COINFLIP_ROUND = "UPDATE_COINFLIP_ROUND";
export const REMOVE_COINFLIP_ROUND = "REMOVE_COINFLIP_ROUND";
export const NEW_COINFLIP_BET = "NEW_COINFLIP_BET";

export const SET_FULL_SCREEN = "SET_FULL_SCREEN";

export const SET_LIVE = "SET_LIVE";
export const SET_LIVE_COUNT = "SET_LIVE_COUNT";
export const SET_LIVE_ALL = "SET_LIVE_ALL";
export const ADD_LIVE_ALL = "ADD_LIVE_ALL";
export const SET_LIVE_HIGH = "SET_LIVE_HIGH";
export const ADD_LIVE_HIGH = "ADD_LIVE_HIGH";
export const SET_LIVE_LUCKY = "SET_LIVE_LUCKY";
export const ADD_LIVE_LUCKY = "ADD_LIVE_LUCKY";

export const SET_REFERRAL_STATE = "SET_REFERRAL_STATE";
export const SET_REFERRAL_BALANCE = "SET_REFERRAL_BALANCE";
