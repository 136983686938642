import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Centrifuge from "centrifuge";
import config from "@/config";
import CentrifugeContext from "./CentrifugeContext";

const centrifuge = new Centrifuge(config.centrifugeUrl);

const subscriptions = [];

const centrifugeSub = ({ channel, cb }) => {
  const index = subscriptions.findIndex(findSubscription => findSubscription.channel === channel);

  let subscription = subscriptions[index];

  if (subscription) {
    subscription.cb = cb;
    subscription.isSubscribe = true;
    return subscription;
  }

  subscription = {};

  const el = centrifuge.subscribe(channel, message => {
    if (subscription.isSubscribe) {
      subscription.cb(message.data);
    }
  });


  subscription = {
    channel,
    cb,
    isSubscribe: true,
    el,
    unsubscribe: () => {
      if (subscription) {
        subscription.isSubscribe = false;
      }
    },
  };

  subscriptions.push(subscription);

  return subscription;
};

const centrifugeUnSub = subscription => {
  subscription.unsubscribe();
};

const subscribe = (channel, cb) => {
  const subscriber = {
    channel,
    cb,
  };

  const subscription = centrifugeSub(subscriber);
  return () => centrifugeUnSub(subscription);
};

const CentrifugeProvider = ({ children }) => {
  const centrifugeToken = useSelector(({ main }) => main.centrifugeToken);

  useEffect(() => {
    if (centrifugeToken) {
      centrifuge.setToken(centrifugeToken);
      centrifuge.connect();
      centrifuge.on("error", e => {
        console.log(e);
      });
    }
  }, [centrifugeToken]);

  return <CentrifugeContext.Provider value={subscribe}>{children}</CentrifugeContext.Provider>;
};

CentrifugeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CentrifugeProvider;
