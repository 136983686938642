import {
  USER_ACCOUNT_STEAM_TYPE,
  USER_ACCOUNT_GOOGLE_TYPE,
} from "@/constants/userAccountTypes";

export default {
  title: "Preferences",
  links: {
    general: "General",
    security: "Security",
    privacy: "Privacy",
    verification: "Verification",
    usersBlocked: "Users Blocked",
  },
  referrer: {
    title: "Referrer",
    na: "N/A",
    hoursLimit: "Your account is over 12 hours old and cannot update the referrer anymore",
    enterCode: "Enter referrer code",
    setNewReferrer: "Change referrer",
    changeFail: "Failed to change referrer",
    changeSuccess: "Changed the referrer successfully",
  },
  linkAccounts: {
    title: "Alternate Login Methods",
    desc:
      "Login even faster by connecting your account to one or more of the available services below",
    types: {
      [USER_ACCOUNT_STEAM_TYPE]: "Steam",
      [USER_ACCOUNT_GOOGLE_TYPE]: "Google",
    },
    linkButton: {
      [USER_ACCOUNT_STEAM_TYPE]: "Link Steam Account",
      [USER_ACCOUNT_GOOGLE_TYPE]: "Link Google Account",
    },
    unlinkFail: "Failed to unpin account! This is the only way to enter.",
    unlinkSuccess: "Account successfully unlinked",
    linkFail: "Failed to link account",
    linkSuccess: "Account successfully linked",
  },
  changeEmail: {
    title: "Email address",
    placeholder: "Not in set",
    verified: "Email has been verified",
    notVerified: "Email not verified",
  },
  changePassword: {
    title: "Change password",
    oldPassword: "Old password",
    newPassword: "Enter a new password. The password must be at least 6 characters long.",
    newPasswordConfirmation: "Confirm new password",
    notConfirmed: "Password mismatch",
    submit: "Save",
    success: "Password set successfully",
    notValid: "Invalid password format",
    fail: "Failed to change password",
    WRONG_OLD_PASSWORD: "The old password was entered incorrectly",
  },
  twoFactor: {
    title: "Two-factor authentication",
    labelOff: "On",
    labelOn: "Off",
    desc:
      "Enabling two-factor authentication will require a code when updating or viewing sensitive information",
  },
  privacy: {
    title: "Your Profile",
    displayTotalWagers: {
      label: "Display Total Wagers",
      desc:
        "Allow other players to view the total amount of wagers you've ever made",
    },
    displayAmountWagers: {
      label: "Display Amount Wagered",
      desc: "Allow other players to view your all time wagered amount",
    },
    incognito: {
      label: "Incognito Mode",
      desc: "Hide your identity when placing a bet and when being displayed on the live game feed",
    },
    gameFeed: "Game Feed",
  },
  verification: {
    upload: {
      title: "Documents",
      desc:
        "Please upload two documents below. For Proof of Identity, please send us a photo of either of your ID card, driver's license or passport. For Proof of Address, please send us a utility bill or similar with your name and address on it from the past 3 months.",
      fileTypes: "File types accepted: .png, .jpg, .pdf",
      photoIdentity: "Proof of Identity",
      photoAddress: "Proof of Address",
      success: "Uploaded successfully and sent for review.",
      fail: "Failed to load, please try later",
      notValid: "Incorrect data, please try later",
      ALREADY_VERIFIED: "The data has already been verified",
      ALREADY_UPLOADED: "This document has already been uploaded.",
      NOT_VALID_MIMETYPE: "Invalid file type.",
      THE_FILE_IS_TOO_BIG: "The file is too large.",
      FILE_DO_NOT_UPLOAD: "An attempt to download failed. try later",
      isUploaded: "Document uploaded and awaiting review",
      isVerified: "The document has been verified.",
    },
    form: {
      title: "Personal Info",
      placeholder: {
        firstName: "Name",
        lastName: "Lastname",
        country: "Country",
        city: "City",
        address: "Address",
        birthday: "Birthday"
      },
      submit: "Save",
      isVerified: "Verified",
      success: "Saved",
      fail: "Failed to save. Check the data and try again",
    }
  },
};
