import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import LiveBets from "@/components/LiveBets";
import { useSubscribe } from "@/libs/centrifuge";
import {
  addLiveHigh,
  addLiveLucky,
  setLiveCount,
  addLiveAll,
} from "@/actions/liveAction";

const LiveBetsContainer = () => {
  const dispatch = useDispatch();
  const count = useSelector(({ live }) => live.count);
  const all = useSelector(({ live }) => live.all);
  const high = useSelector(({ live }) => live.high);
  const lucky = useSelector(({ live }) => live.lucky);
  const subscribe = useSubscribe();

  useEffect(() => {
    const unsubscribe = subscribe("live", ({ type, count, bet }) => {
      switch (type) {
        case "count": {
          dispatch(setLiveCount(count));
          break;
        }
        case "all": {
          dispatch(addLiveAll(bet));
          break;
        }
        case "high": {
          dispatch(addLiveHigh(bet));
          break;
        }
        case "lucky": {
          dispatch(addLiveLucky(bet));
          break;
        }
        default:
          break;
      }
    });

    return () => unsubscribe();
  });

  return <LiveBets count={count} all={all} high={high} lucky={lucky} />;
};

export default LiveBetsContainer;
