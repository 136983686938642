import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AutoCoefficientSettings from "./AutoCoefficientSettings";

const CrashControl = ({
  disabled,
  disabledInput,
  defaultAutoCoefficient,
  isUpgrade,
  isStarting,
  upgrade,
  onCreate,
  autoCoefficientSettings,
  onChangeAutoCoefficient,
  onChangeAutoCoefficientSettings,
}) => {
  const { t } = useTranslation();
  const [deposit, setDeposit] = useState("0.00");
  const [autoCoefficient, setAutoCoefficient] = useState(defaultAutoCoefficient);

  return (
    <div>
      <div className="text-lg">{t("crash.create.amount")}</div>
      <label htmlFor="crash-deposit" className="block relative mb-20">
        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 -mt-1 text-gray-600">
          $
        </span>
        <input
          type="number"
          id="crash-deposit"
          className="text-field pl-20"
          placeholder="0.00"
          value={deposit}
          onChange={e => {
            if (!disabledInput) {
              setDeposit(e.target.value);
            }
          }}
          onBlur={e => setDeposit(Number(e.target.value).toFixed(2))}
        />
      </label>

      <div className="text-lg">{t("crash.create.autoCoefficient")}</div>
      <label htmlFor="crash-auto-coefficient" className="block relative mb-20">
        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 -mt-1 text-gray-600">
          X
        </span>
        <input
          type="number"
          id="crash-auto-coefficient"
          className="text-field pl-20"
          placeholder="0.00"
          value={autoCoefficient}
          onChange={e => {
            if (!disabledInput) {
              setAutoCoefficient(e.target.value);
            }
          }}
          onBlur={e => {
            const newValue = Number(e.target.value).toFixed(2);
            setAutoCoefficient(newValue);
            onChangeAutoCoefficient(newValue);
          }}
        />
      </label>

      <div className="text-gray-600 mb-15">{t("crash.create.selectAutoCoefficient")}</div>

      <AutoCoefficientSettings
        value={autoCoefficient}
        onSelect={coefficient => {
          if (!disabledInput) {
            setAutoCoefficient(coefficient);
          }
        }}
        defaultCoefficients={autoCoefficientSettings}
        onSetNewCoefficients={onChangeAutoCoefficientSettings}
      />

      {isUpgrade && upgrade}
      {!isUpgrade && !isStarting && (
        <button
          type="button"
          className="btn btn--md btn--rounded w-full justify-between mt-22 btn--primary"
          onClick={() => {
            if (!disabled) {
              onCreate({ deposit, autoCoefficient });
            }
          }}
        >
          <span>{t("crash.create.button")}</span>
          <span>$ {Number(deposit).toFixed(2)}</span>
        </button>
      )}
      {isStarting && (
        <button type="button" className="btn btn--md btn--rounded w-full justify-between mt-22">
          <span>{t("crash.create.starting")}</span>
        </button>
      )}

      {!isStarting && !isUpgrade && (
        <div className="text-sm text-gray-400 mt-12 flex items-center justify-center">
          <svg aria-hidden="true" className="fill-current w-12 h-12 mr-7">
            <use href="/svg/svg.svg#info" />
          </svg>
          {t("crash.create.demo")}
        </div>
      )}
    </div>
  );
};

CrashControl.propTypes = {
  defaultAutoCoefficient: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired,
  autoCoefficientSettings: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeAutoCoefficientSettings: PropTypes.func.isRequired,
  onChangeAutoCoefficient: PropTypes.func.isRequired,
  isUpgrade: PropTypes.bool.isRequired,
  upgrade: PropTypes.node.isRequired,
  isStarting: PropTypes.bool.isRequired,
  disabledInput: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CrashControl;
