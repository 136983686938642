import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import authToken from "@/helpers/authToken";
import { setToken } from "@/actions/authAction";

const useSetToken = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return ({ token, expiresIn }) => {
    authToken.set(token);
    dispatch(setToken(token));
    authToken.setExpiresIn(expiresIn);
    history.replace("/");
  };
};

export default useSetToken;
