import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { MINE_PENDING_STATUS } from "@/constants/minesStatuses";
import InputDeposit from "@/components/games/InputDeposit";
import MinesBombsInput from "./MinesBombsInput";

const MinesRoundControl = ({
  onSubmit,
  onCashOut,
  withdraw,
  status,
  startedDeposit,
  startedBombs,
}) => {
  const [deposit, setDeposit] = useState(startedDeposit);
  const [bombs, setBombs] = useState(startedBombs);
  const { t } = useTranslation();

  const submit = () => {
    onSubmit({
      deposit,
      bombs,
    });
  };

  useEffect(() => {
    setDeposit(startedDeposit);
  }, [startedDeposit]);

  useEffect(() => {
    setBombs(startedBombs);
  }, [startedBombs]);

  const isPending = status === MINE_PENDING_STATUS;

  return (
    <div>
      <InputDeposit
        value={deposit}
        onChange={value => {
          if (!isPending) {
            setDeposit(value);
          }
        }}
      />
      <div className="text-gray-600 mb-15">{t("mines.create.bombs")}</div>
      <MinesBombsInput value={bombs} onChange={setBombs} disabled={isPending} />
      <button
        type="submit"
        className={`btn btn--md btn--rounded w-full justify-between mt-22 ${
          isPending ? "btn--green" : "btn--primary"
        }`}
        onClick={() => {
          if (isPending) {
            onCashOut();
          } else {
            submit();
          }
        }}
      >
        {isPending && <span>{t("mines.cashOut.button")}</span>}
        {!isPending && <span>{t(`mines.create.${deposit > 0 ? "submit" : "demo"}`)}</span>}
        <span>$ {isPending ? withdraw.toFixed(2) : Number(deposit || 0).toFixed(2)}</span>
      </button>

      <div className="text-sm text-gray-400 mt-12 flex items-center justify-center">
        <svg aria-hidden="true" className="fill-current w-12 h-12 mr-7">
          <use href="/svg/svg.svg#info" />
        </svg>
        {t("mines.create.description")}
      </div>
    </div>
  );
};

MinesRoundControl.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCashOut: PropTypes.func.isRequired,
  withdraw: PropTypes.number,
  startedDeposit: PropTypes.number,
  startedBombs: PropTypes.number,
  status: PropTypes.number,
};

MinesRoundControl.defaultProps = {
  withdraw: 0,
  startedDeposit: 0,
  startedBombs: 0,
  status: null,
};

export default MinesRoundControl;
