import { useSelector } from "react-redux";

const PATH = "/sounds/";

const useSound = () => {
  const { soundsOn } = useSelector(({ main }) => main);

  const play = name => {
    if (!soundsOn) {
      return false;
    }
    const audio = new Audio(`${PATH}${name}.mp3`);
    try {
      audio.volume = 0.2;
      audio.play();
    } catch (e) {}

    return true;
  };

  return {
    newGame: () => play("push"),
    success: () => play("ok"),
    winner: () => play("select"),
    notification: () => play("notification"),
    bet: () => play("bet"),
    minesBomb: () => play("mines_bomb"),
    minesTile: () => play("mines_tile"),
    minesWin: () => play("mines_win"),
    rouletteStart: () => play("colors_start"),
    rouletteEnd: () => play("colors_end"),
    diceStart: () => play("colors_start"),
    diceEnd: () => play("colors_end"),
    diceRoll: () => play("dice_win"),
  };
};

export default useSound;
