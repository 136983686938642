import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CopyToClipboard from "react-copy-to-clipboard";
import Modal from "@/components/Modal";
import config from "@/config";
import toLocaleDate from "@/helpers/toLocaleDate";
import ReferralLevel from "@/components/referral/ReferralLevel";
import ReferralEarns from "@/components/referral/ReferralEarns";

const ReferralModal = ({ onSetRef, state, onWithdraw, onRefresh }) => {
  const modalRef = useRef();
  const { t } = useTranslation();
  const [isCopy, setIsCopy] = useState(false);

  const { code, referrals, earns, total, balance } = state;

  const referralUrl = `${config.url}ref/${code}`;

  useEffect(() => {
    onSetRef(modalRef);
  }, [modalRef]);

  const copyReferralUrl = () => {
    setIsCopy(true);
    setTimeout(() => {
      setIsCopy(false);
    }, 2000);
  };

  return (
    <Modal
      ref={modalRef}
      dialogClassName="modal-dialog-centered"
      contentClassName="account-form"
      showCloseButton
    >
      <div className="account-form__inner">
        <div className="account-form__main">
          <div>
            <div className="account-modal-title">{t("referral.title")}</div>
            <div className="field-wrapper">
              <label className="field-wrapper__title text-black-900" htmlFor="client-seeds">
                {t("referral.link")}
              </label>
              <div className="relative">
                <input
                  type="text"
                  id="client-seeds"
                  className="text-field text-field--light text-primary-light"
                  value={referralUrl}
                  onChange={() => {}}
                  readOnly=""
                />
                <CopyToClipboard text={referralUrl} onCopy={copyReferralUrl}>
                  <button
                    className="btn btn--tiny btn--rounded btn--primary-light absolute right-0 top-1/2 transform -translate-y-1/2 z-3 -mt-3"
                    type="button"
                  >
                    {t("referral.copyLink")}
                  </button>
                </CopyToClipboard>
              </div>
              <div
                className="mt-20 text-gray-150 leading-tight"
                dangerouslySetInnerHTML={{ __html: t("") }}
              />
              <div className="text-right mt-10">
                {isCopy && (
                  <div className="text-green inline-flex items-center">
                    <svg aria-hidden="true" className="fill-current w-12 h-12 mr-10">
                      <use href="/svg/svg.svg#basic-tick" />
                    </svg>
                    {t("referral.isCopy")}
                  </div>
                )}
              </div>
            </div>

            <div className="h-200 overflow-y-auto">
              {referrals.map(referral => (
                <div className="mt-10" key={referral.id}>
                  <div className="text-black-900 text-xl">{referral.name}</div>
                  <div className="text-gray-150">{toLocaleDate(referral.referralAt)}</div>
                </div>
              ))}
            </div>

            <div className="flex justify-between items-center mt-20">
              <div className="text-green font-medium flex items-center">
                <svg aria-hidden="true" className="fill-current w-24 h-24 mr-10">
                  <use href="/svg/svg.svg#coins" />
                </svg>
                $ {balance}
              </div>
              <div className="flex space-x-10">
                <button
                  type="button"
                  className="btn btn--tiny btn--rounded btn--gray"
                  onClick={() => onRefresh()}
                >
                  <svg aria-hidden="true" className="fill-current w-16 h-16 mr-10">
                    <use href="/svg/svg.svg#refresh" />
                  </svg>
                  {t("referral.refresh")}
                </button>
                <button
                  className="btn btn--tiny btn--rounded btn--green"
                  type="button"
                  onClick={() => onWithdraw()}
                >
                  {t("referral.withdraw")}
                </button>
              </div>
            </div>

            <div className="grid sm:grid-cols-3 gap-10 sm:gap-20 mt-20">
              {[
                {
                  id: "referralsCount",
                  icon: "",
                  value: referrals.length,
                },
                {
                  id: "earnsCount",
                  icon: "",
                  value: earns.length,
                },
                {
                  id: "total",
                  icon: "",
                  value: `${total} $`,
                },
              ].map(({ icon, id, value }) => (
                <div
                  className="border-2 border-gray-100 rounded py-5 px-15 h-65 text-right flex flex-col justify-center items-right leading-tight relative"
                  key={id}
                >
                  <svg
                    aria-hidden="true"
                    className="fill-current text-gray-150 text-opacity-75 w-25 h-25 absolute left-0 ml-20 top-1/2 transform -translate-y-1/2"
                  >
                    <use href={`/svg/svg.svg#${icon}`} />
                  </svg>
                  <div className="text-black-900 font-medium text-lg">
                    {t(`referral.stats.${id}`)}
                  </div>
                  <div className="text-gray-500">{value}</div>
                </div>
              ))}
            </div>

            {state.levels[1] && <ReferralLevel levels={state.levels} total={state.total} levelId={state.level} />}

            <ReferralEarns earns={earns} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

ReferralModal.propTypes = {
  onSetRef: PropTypes.func.isRequired,
  state: PropTypes.shape({
    earns: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    ).isRequired,
    referrals: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        referralAt: PropTypes.string.isRequired,
      }),
    ).isRequired,
    code: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    balance: PropTypes.number.isRequired,
    levels: PropTypes.shape({
      1: PropTypes.shape({
        percent: PropTypes.number.isRequired,
        bonus: PropTypes.number.isRequired,
        deposit: PropTypes.number.isRequired,
      }),
    }).isRequired,
    level: PropTypes.number.isRequired,
  }).isRequired,
  onWithdraw: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export default ReferralModal;
