import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const SecondaryNav = ({ items, activeItem, setActiveItem }) => {
  const menuRef = useRef();

  useEffect(() => {
    if (!menuRef.current) {
      return;
    }
    const menu = menuRef.current;
    const activeLink = menu.querySelector(".active-link");

    if (!activeLink) {
      return;
    }

    const menuRect = menu.getBoundingClientRect();
    const linkRect = activeLink.getBoundingClientRect();

    menu.style.setProperty("--marker-offset-top", `${linkRect.top - menuRect.top}px`);
    menu.style.setProperty("--marker-offset-left", `${linkRect.left - menuRect.left}px`);
    menu.style.setProperty("--marker-width", `${linkRect.width}px`);
    menu.style.setProperty("--marker-height", `${linkRect.height}px`);
  }, [menuRef, activeItem]);

  return (
    <nav
      className="secondary-nav secondary-nav--has-marker secondary-nav--marker-bottom secondary-nav--narrow -my-2"
      style={{ "--link-padding": "1rem" }}
    >
      <ul ref={menuRef} className="justify-between">
        {items.map(item => {
          const { id, label } = item;
          const isActive = activeItem.id === id;

          return (
            <li key={id} className={`${isActive ? "active-link" : ""}`}>
              <button
                type="button"
                className={`secondary-nav__link ${isActive ? "active" : ""}`}
                onClick={() => setActiveItem(item)}
              >
                {label}
              </button>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

const itemProp = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
});

SecondaryNav.propTypes = {
  items: PropTypes.arrayOf(itemProp).isRequired,
  activeItem: itemProp.isRequired,
  setActiveItem: PropTypes.func.isRequired,
};

export default SecondaryNav;
