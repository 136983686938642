import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useApi } from "@/api";
import LanguageSelector from "@/components/LanguageSelector";
import { setUserLang } from "@/actions/authAction";

const languages = [
  {
    id: "en",
    tag: "ENG",
    label: "English",
    image: "/img/flags/262-united-kingdom.svg",
  },
];

const LanguageChangeContainer = () => {
  const { updateLang } = useApi();

  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  // const isMobile = useSelector(({ main }) => main.isMobile);
  const { i18n } = useTranslation();

  const currentLang = user ? user.lang : i18n.language.slice(0, 2);

  useEffect(() => {
    if (user && currentLang !== user.lang) {
      i18n.changeLanguage(user.lang);
    }
  }, [user, i18n]);

  const onChange = lang => {
    i18n.changeLanguage(lang);
    if (user) {
      updateLang(lang).then(() => {
        dispatch(setUserLang(lang));
      });
    }
  };

  const current = languages.find(({ id }) => id === currentLang);


  return (
    <LanguageSelector current={current || languages[0]} onChange={onChange} languages={languages} />
  );
};

export default LanguageChangeContainer;
