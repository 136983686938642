import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  ROULETTE_BET_RESOLVE_STATUS,
  ROULETTE_BET_REJECT_STATUS,
  ROULETTE_BET_WAITING_STATUS,
} from "@/constants/rouletteBetStatuses";
import RouletteNumberImage from "@/components/games/roulette/RouletteNumberImage";

const RouletteBets = ({ bets }) => {
  const { t } = useTranslation();

  return (
    <div className="pt-25 -mx-20 overflow-hidden flex flex-col mt-8 max-h-300">
      <div className="flex items-center text-gray-600 mb-10 px-20">
        <svg aria-hidden="true" className="fill-current text-gray-400 w-12 h-12 mr-10">
          <use href="/svg/svg.svg#info" />
        </svg>
        {t("roulette.bets")}
      </div>

      <ul
        className="overflow-auto focus-outline-inset disable-scrollbars lg:max-h-full"
        style={{ height: "24.5rem" }}
      >
        {bets.map(({ id, userName, deposit, number, withdraw, status }) => {
          let classNameSum = "";
          let classNameSymbol = "";

          if (status === ROULETTE_BET_RESOLVE_STATUS) {
            classNameSum = "text-green-200";
            classNameSymbol = "bg-green";
          }
          if (status === ROULETTE_BET_REJECT_STATUS) {
            classNameSum = "text-red";
            classNameSymbol = "bg-red";
          }

          return (
            <li key={id} className="flex items-center justify-between px-20 py-5 odd:bg-black-600">
              <RouletteNumberImage number={number} />
              {userName ? (
                <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
                  {userName}
                </div>
              ) : (
                <div className="text-gray-200">[{t("hidden")}]</div>
              )}
              <div className="flex items-center ml-auto pl-10">
                <span className={classNameSum}>
                  {status !== ROULETTE_BET_WAITING_STATUS &&
                    (status === ROULETTE_BET_RESOLVE_STATUS ? "+" : "-")}
                  {(status === ROULETTE_BET_RESOLVE_STATUS ? withdraw : deposit).toFixed(2)}
                </span>
                <div className={`currency-symbol ml-7 ${classNameSymbol}`}>$</div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

RouletteBets.propTypes = {
  bets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      number: PropTypes.number.isRequired,
      userName: PropTypes.string.isRequired,
      deposit: PropTypes.number.isRequired,
      withdraw: PropTypes.number,
      status: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default RouletteBets;
