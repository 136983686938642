import React from "react";
import SocialLinks from "@/components/SocialLinks";
import config from "@/config";

const { telegram, twitter } = config.socials;

const SocialLinksContainer = () => {
  const links = [
    {
      id: "telegram",
      link: telegram,
      icon: (
        <svg aria-hidden="true" className="fill-current w-18 h-18">
          <use href="/svg/svg.svg#telegram" />
        </svg>
      ),
    },
    {
      id: "twitter",
      link: twitter,
      icon: (
        <svg aria-hidden="true" className="fill-current w-22 h-22">
          <use href="/svg/svg.svg#twitter" />
        </svg>
      ),
    },
  ];

  return <SocialLinks links={links} />;
};

export default SocialLinksContainer;
