import React from "react";

const Withdraw = () => {
  return (
    <>
      <ul className="payment-methods">
        <li>
          <button type="button" className="payment-methods__btn selected">
            <img src="/img/payment-visa.png" alt="" />
          </button>
        </li>
      </ul>
      <div className="space-y-20 sm:space-y-35 mt-30">
        <label className="field-wrapper">
          <div className="field-wrapper__title text-black-900">Number Qiwi Wallet</div>
          <input type="text" className="text-field text-field--light" placeholder="+7" />
        </label>

        <label className="field-wrapper">
          <div className="field-wrapper__title text-black-900">Amount</div>
          <input type="text" className="text-field text-field--light" placeholder="$ 0.00" />
          <div className="field-wrapper__description text-right text-gray-150">
            Minimum withdrawal amount is $10.00
          </div>
        </label>
      </div>
      <button className="btn btn--green btn--bold btn--md btn--rounded mt-20 sm:mt-10">
        Withdraw <span className="ml-15">-</span>
      </button>
      <div className="text-gray-600 mt-25">
        The Commission is <span className="text-gray-500">3%</span>, you will get
        <span className="text-gray-500">$ 30.20</span>
      </div>
    </>
  );
};

export default Withdraw;
