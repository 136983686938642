import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import Field from "@/components/ui/Field";

const LoginForm = ({ onSubmit, pending }) => {
  const { t } = useTranslation();

  const registerSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("form.validations.email"))
      .required(t("form.validations.required")),
    password: Yup.string()
      .min(6, t("form.validations.minLength", { length: 6 }))
      .required(t("form.validations.required")),
  });

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={(values, { resetForm }) => onSubmit(values, () => resetForm())}
      validationSchema={registerSchema}
    >
      {({ handleSubmit, handleChange, handleBlur, values }) => (
        <form className="grid gap-25 sm:gap-35" onSubmit={handleSubmit}>
          <Field
            name="email"
            label={t("form.label.email")}
            placeholder={t("form.placeholder.email")}
            required
            icon={
              <svg aria-hidden="true" className="w-16 h-16">
                <use href="/svg/svg.svg#man-user" />
              </svg>
            }
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.email}
          />
          <div className="grid gap-10">
            <Field
              name="password"
              type="password"
              label={t("form.label.password")}
              placeholder={t("form.placeholder.password")}
              required
              icon={
                <svg aria-hidden="true" className="w-16 h-16">
                  <use href="/svg/svg.svg#padlock" />
                </svg>
              }
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={values.password}
            />
          </div>
          <div className="flex justify-between items-center">
            <button
              type="submit"
              className="btn btn--md btn--primary btn--rounded px-30"
              disabled={pending}
            >
              {t("auth.loginButton")}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  pending: PropTypes.bool.isRequired,
};

export default LoginForm;
