import {
  SET_TOWERS_ROUND,
  SET_TOWERS_BETS,
  NEW_TOWERS_BET,
  SET_TOWERS_BOMB_CELLS,
  SET_TOWERS_ROUND_STATUS,
  SET_TOWERS_ROUND_WITHDRAW,
  SET_TOWERS_ROUND_DEPOSIT,
  SET_TOWERS_ROUND_DIFFICULTY,
  SET_TOWERS_GAMEBOARD,
  SET_TOWERS_DEMO,
  SET_TOWERS_STEPS,
  SET_TOWERS_ROUND_SERVER_SEED,
} from "@/constants";
import {TOWERS_PENDING_STATUS } from '@/constants/towersStatus';
import { TOWERS_EASY_DIFFICULTY } from "@/constants/towersDifficulty";

const initialState = {
  demo: null,
  gameboard: null,
  round: {
    id: null,
    deposit: 0,
    difficulty: TOWERS_EASY_DIFFICULTY,
    status: TOWERS_PENDING_STATUS,
    withdraw: 0,
    bombCells: [],
    steps: [],
    serverSeed: "",
    nonce: 0,
    clientSeed: "",
  },
  bets: [],
};

const towers = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_TOWERS_ROUND: {
      return {
        ...state,
        round: {
          ...action.payload,
        },
      };
    }
    case SET_TOWERS_DEMO: {
      return {
        ...state,
        demo: action.payload,
      };
    }
    case SET_TOWERS_GAMEBOARD: {
      return {
        ...state,
        gameboard: action.payload,
      };
    }
    case SET_TOWERS_STEPS:
      return {
        ...state,
        round: {
          ...state.round,
          steps: [...action.payload],
        },
      };
    case SET_TOWERS_ROUND_STATUS:
      return {
        ...state,
        round: {
          ...state.round,
          status: action.payload,
        },
      };
    case SET_TOWERS_BOMB_CELLS:
      return {
        ...state,
        round: {
          ...state.round,
          bombCells: action.payload,
        },
      };
    case SET_TOWERS_ROUND_WITHDRAW:
      return {
        ...state,
        round: {
          ...state.round,
          withdraw: action.payload,
        },
      };
    case SET_TOWERS_ROUND_DEPOSIT:
      return {
        ...state,
        round: {
          ...state.round,
          deposit: action.payload,
        },
      };
    case SET_TOWERS_ROUND_DIFFICULTY:
      return {
        ...state,
        round: {
          ...state.round,
          difficulty: action.payload,
        },
      };
    case SET_TOWERS_ROUND_SERVER_SEED:
      return {
        ...state,
        round: {
          ...state.round,
          serverSeed: action.payload,
        },
      };
    case SET_TOWERS_BETS:
      return {
        ...state,
        bets: [...action.payload],
      };
    case NEW_TOWERS_BET:
      return {
        ...state,
        bets: [action.payload, ...state.bets].slice(0, 10),
      };
    default:
      return state;
  }
};

export default towers;
