import React from "react";
import { useTranslation } from "react-i18next";
import GameHeaderContainer from "@/containers/games/GameHeaderContainer";
import GameFooterContainer from "@/containers/games/GameFooterContainer";
import TowersSidebarContainer from "@/containers/games/towers/TowersSidebarContainer";
import TowersRoundContainer from "@/containers/games/towers/TowersRoundContainer";
import TowersCurrentStateContainer from "@/containers/games/towers/TowersCurrentStateContainer";
import TowerBetsSubscriberContainer from "@/containers/games/towers/TowerBetsSubscriberContainer";
import TowersFairModalContainer from "@/containers/games/towers/TowersFairModalContainer";

const TowersMainContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <GameHeaderContainer title={t("towers.title")}>
        <TowersFairModalContainer />
      </GameHeaderContainer>
      <div className="game-wrapper game-wrapper--has-sidebar">
        <TowersRoundContainer />
        <TowersSidebarContainer />
      </div>
      <GameFooterContainer />
      <TowersCurrentStateContainer />
      <TowerBetsSubscriberContainer />
    </>
  );
};

export default TowersMainContainer;
