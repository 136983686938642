export default {
  buttons: {
    all: "All",
    high: "High Wins",
    lucky: "Lucky Wins",
  },
  wagerAllTimes: "Wager all times",
  table: {
    gameType: "Game",
    username: "User",
    time: "Time",
    deposit: "Wager",
    coefficient: "Mult",
    withdraw: "Payout",
    type: {
      towers: "Towers",
      mines: "Mines",
      crash: "Crash",
      roulette: "Roulette",
      coinflip: "Coinflip",
      dice: "Dice",

    }
  }
}