import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import {
  setRound,
  setRoundDemo,
  setRoundDeposit,
  setRoundDifficulty,
  setRoundBombCells,
  setRoundStatus,
} from "@/actions/towersAction";
import { setOpenAuth } from "@/actions/mainAction";
import { useApi } from "@/api";
import useSound from "@/helpers/useSound";
import noty from "@/libs/noty";
import {
  TOWERS_EASY_DIFFICULTY,
  TOWERS_MEDIUM_DIFFICULTY,
  TOWERS_HARD_DIFFICULTY,
} from "@/constants/towersDifficulty";
import { TOWERS_PENDING_STATUS, TOWERS_RESOLVE_STATUS } from "@/constants/towersStatus";
import TowersControl from "@/components/games/towers/TowersControl";
import createDemoCells from "./createDemoCells";

const TowersControlContainer = () => {
  const { t } = useTranslation();
  const { towersCreate, towersCashOut } = useApi();
  const id = useSelector(({ towers }) => towers.round.id);
  const status = useSelector(({ towers }) => towers.round.status);
  const deposit = useSelector(({ towers }) => towers.round.deposit);
  const difficulty = useSelector(({ towers }) => towers.round.difficulty);
  const withdraw = useSelector(({ towers }) => towers.round.withdraw);
  const demo = useSelector(({ towers }) => towers.demo);
  const user = useSelector(({ auth }) => auth.user);
  const dispatch = useDispatch();
  const sound = useSound();

  const createDemo = difficulty => {
    sound.bet();
    const demoCells = createDemoCells(difficulty);
    dispatch(setRoundDemo(demoCells));

    dispatch(
      setRound({
        id: 0,
        deposit: 0,
        withdraw: 0,
        steps: [],
        status: TOWERS_PENDING_STATUS,
        bombCells: [],
        difficulty,
      }),
    );
  };

  const create = ({ level, deposit }) => {
    if (!user) {
      dispatch(setOpenAuth(true));
      return;
    }

    if (!deposit) {
      createDemo(level);
      return;
    }
    dispatch(setRoundDemo(null));
    towersCreate({ difficulty: level, deposit })
      .then(newRound => {
        dispatch(setRound(newRound));
      })
      .catch(() => {
        noty.error(t("towers.create.error"));
      });
  };

  const cashOut = () => {
    if (demo) {
      sound.minesWin();
      dispatch(setRoundStatus(TOWERS_RESOLVE_STATUS));
      dispatch(setRoundBombCells(demo));
      return;
    }

    towersCashOut()
      .then(updatedRound => {
        sound.minesWin();
        dispatch(setRound(updatedRound));
      })
      .catch(() => {
        noty.error(t("towers.cashOut.error"));
      });
  };

  return (
    <TowersControl
      isStarting={typeof id === "number" && status === TOWERS_PENDING_STATUS}
      levels={[TOWERS_EASY_DIFFICULTY, TOWERS_MEDIUM_DIFFICULTY, TOWERS_HARD_DIFFICULTY]}
      onCreate={create}
      onCashOut={cashOut}
      deposit={deposit || 0}
      withdraw={withdraw}
      level={difficulty || 1}
      onChangeDeposit={newDeposit => dispatch(setRoundDeposit(newDeposit))}
      onChangeLevel={newDifficulty => dispatch(setRoundDifficulty(newDifficulty))}
    />
  );
};

export default TowersControlContainer;
