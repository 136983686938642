import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setFullScreen } from "@/actions/mainAction";
import GameFooter from "@/components/games/GameFooter";
import Tooltip from "@/components/ui/Tooltip";

const GameFooterContainer = ({ children, maxProfit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fullScreen = useSelector(({ main }) => main.fullScreen);

  return (
    <GameFooter
      onClickFullSize={() => dispatch(setFullScreen(!fullScreen))}
      onClickFullScreen={() => {
        function toggleFullscreen(event) {
          let element = document.body;

          if (event instanceof HTMLElement) {
            element = event;
          }

          const isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;

          element.requestFullScreen =
            element.requestFullScreen ||
            element.webkitRequestFullScreen ||
            element.mozRequestFullScreen ||
            (() => false);
          document.cancelFullScreen =
            document.cancelFullScreen ||
            document.webkitCancelFullScreen ||
            document.mozCancelFullScreen ||
            (() => false);

          if (isFullscreen) {
            document.cancelFullScreen();
          } else {
            element.requestFullScreen();
          }
        }

        toggleFullscreen(document.querySelector("#game-wrapper"));
      }}
    >
      {children || <Tooltip title={t("maxProfit")} content={`$${maxProfit}`} />}
    </GameFooter>
  );
};

GameFooterContainer.propTypes = {
  children: PropTypes.node,
  maxProfit: PropTypes.string,
};

GameFooterContainer.defaultProps = {
  children: null,
  maxProfit: "50 000",
};

export default GameFooterContainer;
