const paymentTypes = {
  2: "unitpay",
  3: "skinsback",
};

const analytics = {
  transaction: ({ id, paymentType, amount }) => {
    window.dataLayer.push({
      transactionId: `${id}`,
      transactionAffiliation: paymentTypes[paymentType] || paymentType,
      transactionTotal: amount,
    });
    window.dataLayer.push({ event: "transaction" });
    window.yandexDataLayer.push({
      ecommerce: {
        purchase: {
          actionField: {
            id,
          },
          products: [
            {
              name: paymentTypes[paymentType] || paymentType,
              price: amount,
            },
          ],
        },
      },
    });

    window.fbq("track", "Purchase");
  },

  successRegister: UID => {
    window.dataLayer.push({
      event: "RegisterSuccess",
      UID,
    });
  },

  getClientId: () => {
    let match = document.cookie.match("(?:^|;)\\s*_ga=([^;]*)");
    const raw = match ? decodeURIComponent(match[1]) : null;
    if (raw) {
      match = raw.match(/(\d+\.\d+)$/);
    }

    return match ? match[1] : null;
  },
};

export default analytics;
