import React from "react";
import { useApi } from "@/api";
import {
  USER_ACCOUNT_STEAM_TYPE,
  USER_ACCOUNT_GOOGLE_TYPE,
} from "@/constants/userAccountTypes";
import SocialButtons from "@/components/auth/SocialButtons";

const SocialLoginContainer = () => {
  const { getSteamAuthUrl } = useApi();

  const buttons = [
    {
      id: USER_ACCOUNT_STEAM_TYPE,
      icon: (
        <svg aria-hidden="true" className="w-20 h-20">
          <use href="/svg/svg.svg#steam" />
        </svg>
      ),
      getUrl: getSteamAuthUrl,
    },
    {
      id: USER_ACCOUNT_GOOGLE_TYPE,
      icon: (
        <svg aria-hidden="true" className="w-20 h-20">
          <use href="/svg/svg.svg#twitter" />
        </svg>
      ),
    },

  ];

  const onClick = getUrl => {
    getUrl().then(({ url }) => {
      window.location.href = url;
    });
  };

  return <SocialButtons buttons={buttons} onClick={onClick} />;
};

export default SocialLoginContainer;
