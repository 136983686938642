import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ProfileChangePassword = ({ withoutPassword, onSubmit }) => {
  const { t } = useTranslation();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");

  const isConfirm = newPassword === newPasswordConfirmation;

  const submit = () => {
    if (isConfirm && (withoutPassword ? true : !!oldPassword)) {
      onSubmit({
        oldPassword: oldPassword || null,
        newPassword,
      }).then(result => {
        if (result) {
          setOldPassword("");
          setNewPassword("");
          setNewPasswordConfirmation("");
        }
      });
    }
  };

  return (
    <>
      {!withoutPassword && (
        <label className="field-wrapper" htmlFor="change-password-old-password">
          <div className="field-wrapper__title text-black-900">
            {t("profile.changePassword.title")}
          </div>
          <input
            type="password"
            id="change-password-old-password"
            className="text-field text-field--light"
            value={oldPassword}
            onChange={e => setOldPassword(e.target.value)}
            onBlur={e => setOldPassword(e.target.value)}
            placeholder={t("profile.changePassword.oldPassword")}
          />
        </label>
      )}

      <label className="field-wrapper" htmlFor="change-password-new-password">
        <div className={`field-wrapper__title text-black-900 ${withoutPassword ? "" : "sr-only"}`}>
          {t("profile.changePassword.title")}
        </div>
        <input
          type="password"
          id="change-password-new-password"
          className="text-field text-field--light"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          onBlur={e => setNewPassword(e.target.value)}
          placeholder={t("profile.changePassword.newPassword")}
        />
      </label>

      <div className="field-wrapper mt-10">
        <label
          className="field-wrapper__title sr-only"
          htmlFor="change-password-new-password-confirmation"
        >
          {t("profile.changePassword.newPasswordConfirmation")}
        </label>
        <input
          type="text"
          id="change-password-new-password-confirmation"
          className="text-field text-field--light"
          value={newPasswordConfirmation}
          onChange={e => setNewPasswordConfirmation(e.target.value)}
          onBlur={e => setNewPasswordConfirmation(e.target.value)}
          placeholder={t("profile.changePassword.newPasswordConfirmation")}
        />
        {!isConfirm && newPassword && (
          <div className="field-wrapper__description text-right">
            <div className="text-red inline-flex items-center">
              {t("profile.changePassword.notConfirmed")}
            </div>
          </div>
        )}
        <div className="field-wrapper__description text-right">
          <button
            type="button"
            className="btn btn--primary-light btn--text btn--offset-r btn--md"
            onClick={submit}
          >
            {t("profile.changePassword.submit")}
          </button>
        </div>
      </div>
    </>
  );
};

ProfileChangePassword.propTypes = {
  withoutPassword: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ProfileChangePassword;
