import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setRound, setBets, setHistory } from "@/actions/rouletteAction";
import { useApi } from "@/api";
import PageLoader from "@/components/PageLoader";

const RouletteCurrentStateContainer = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { rouletteState } = useApi();

  useEffect(() => {
    rouletteState().then(({ round, history }) => {
      dispatch(setRound(round));
      dispatch(setHistory(history));
      dispatch(setBets(round.bets));
      setLoading(false);
    });
  });

  if (loading) {
    return <PageLoader />;
  }

  return <></>;
};

export default RouletteCurrentStateContainer;
