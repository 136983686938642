import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const MinesLastWins = ({ rounds }) => {
  const { t } = useTranslation();

  return (
    <div className="pt-25 -mx-20 overflow-hidden flex flex-col mt-8 max-h-300">
      <div className="flex items-center text-gray-600 mb-10 px-20">
        <svg aria-hidden="true" className="fill-current text-green w-30 h-25 mr-5 -ml-5 -mt-2">
          <use href="/svg/svg.svg#crown" />
        </svg>
        <span>{t("mines.lastRounds")}</span>
      </div>

      <ul
        className="overflow-auto focus-outline-inset disable-scrollbars lg:max-h-full"
        style={{ height: "24rem" }}
      >
        {rounds.map(({ id, userName, prize, coefficient }) => {
          const isWin = prize > 0;
          return (
            <li key={id} className="flex items-center justify-between px-20 py-5 odd:bg-black-600">
              <div className={!userName ? "text-gray-200" : ""}>
                {isWin && <span className="text-green-200 mr-5">{coefficient.toFixed(2)}x</span>}
                {userName || t("hidden")}
              </div>
              <div className="flex items-center">
                <span className={isWin ? "text-green-200" : "text-red"}>
                  {isWin && "+"}
                  {prize.toFixed(2)}
                </span>
                <div className={`currency-symbol ml-7 ${isWin ? "bg-green" : "bg-red"}`}>$</div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

MinesLastWins.propTypes = {
  rounds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      userName: PropTypes.string,
      prize: PropTypes.number.isRequired,
      coefficient: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default MinesLastWins;
