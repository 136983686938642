import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import { useApi } from "@/api";
import Field from "@/components/ui/Field";

const RegisterForm = ({ onSubmit, pending }) => {
  const { t } = useTranslation();
  const { validationCheckEmailUnique } = useApi();

  const registerSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("form.validations.email"))
      .test({
        name: "uniq-email",
        message: t("form.validations.uniqEmail"),
        test: email => {
          const schema = Yup.string()
            .required()
            .email();
          if (!schema.isValidSync(email)) {
            return false;
          }
          return new Promise(resolve => {
            validationCheckEmailUnique(email).then(({ isUnique }) => resolve(isUnique));
          });
        },
        exclusive: true,
      })
      .required(t("form.validations.required")),
    name: Yup.string()
      .min(2, t("form.validations.minLength", { length: 2 }))
      .max(50, t("form.validations.maxLength", { length: 50 }))
      .required(t("form.validations.required")),
    birthday: Yup.date()
      .test("birthday", t("form.validations.birthday"), value => {
        const years = moment().diff(moment(value), "years");
        return years > 14 && years < 120;
      })
      .required(t("form.validations.required")),
    password: Yup.string()
      .min(6, t("form.validations.minLength", { length: 6 }))
      .required(t("form.validations.required")),
    confirmPassword: Yup.string()
      .min(6, t("form.validations.minLength", { length: 6 }))
      .required(t("form.validations.required")),
  });

  return (
    <Formik
      initialValues={{
        email: "",
        name: "",
        birthday: "",
        password: "",
        confirmPassword: "",
      }}
        onSubmit={(values, { resetForm }) => onSubmit(values, () => resetForm())}
      validationSchema={registerSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, handleChange, handleBlur, values }) => (
        <form className="grid gap-25 sm:gap-35" onSubmit={handleSubmit}>
          <Field
            name="name"
            label={t("form.label.name")}
            placeholder={t("form.placeholder.name")}
            icon={
              <svg aria-hidden="true" className="w-14 h-14">
                <use href="/svg/svg.svg#man-user" />
              </svg>
            }
            required
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.name}
          />
          <Field
            name="email"
            label={t("form.label.email")}
            placeholder={t("form.placeholder.email")}
            required
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.email}
          />

          <Field
            name="birthday"
            type="date"
            label={t("form.label.birthday")}
            placeholder={t("form.placeholder.birthday")}
            required
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.birthday}
          />
          <div className="grid gap-10">
            <Field
              name="password"
              type="password"
              label={t("form.label.password")}
              placeholder={t("form.placeholder.password")}
              required
              icon={
                <svg aria-hidden="true" className="w-16 h-16">
                  <use href="/svg/svg.svg#padlock" />
                </svg>
              }
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={values.password}
            />
            <Field
              name="confirmPassword"
              type="password"
              placeholder={t("form.placeholder.confirmPassword")}
              required
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={values.confirmPassword}
            />
          </div>
          <div className="flex justify-between items-center">
            <button
              type="submit"
              className="btn btn--md btn--primary btn--rounded px-30"
              disabled={pending}
            >
              {t("auth.registerButton")}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

RegisterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  pending: PropTypes.bool.isRequired,
};

export default RegisterForm;
