import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  COINFLIP_JOINED_STATUS,
  COINFLIP_ROLLING_STATUS,
  COINFLIP_FINISH_STATUS,
} from "@/constants/coinflipStatuses";

const CoinflipListRound = ({ userId, round, onJoin, disabled, onWatch }) => {
  const { t } = useTranslation();
  const progress = useRef();

  const { id, player1, player2, deposit, status, waitingTime } = round;

  useEffect(() => {
    if (!progress.current) {
      return () => {};
    }
    let time = waitingTime;

    const timer = setInterval(() => {
      const timei = time / 1000;
      progress.current.textContent = `${timei.toFixed(0)}`;
      progress.current.style.setProperty("--progress", `${100 - (timei * 10)}%`);

      time -= 10;

      if (timei <= 0.01) {
        clearInterval(timer);
        progress.current.textContent = "";
      }
    }, 10);

    return () => {
      clearInterval(timer);
    };
  }, [progress, waitingTime]);

  if (!id) {
    return (
      <li className="p-15 md:px-20 md:py-15 rounded flex flex-wrap justify-between items-center" />
    );
  }

  return (
    <li className="bg-primary-100 bg-opacity-5 hover:bg-opacity-8 p-15 md:px-20 md:py-15 rounded flex flex-wrap justify-between items-center transition duration-100">
      <div className="flex items-center">
        <div className="logo-circle">
          <svg aria-hidden="true">
            <use href="/svg/svg.svg#logo" />
          </svg>
          <svg aria-hidden="true">
            <use href="/svg/svg.svg#logo" />
          </svg>
        </div>

        <div className="px-20 mr-auto text-left">
          <div className="text-lg mb-5">{player1.name}</div>
          <div className="flex items-center">
            <div className="currency-symbol bg-green mr-12">$</div>
            <div className="text-green mr-12">{deposit.toFixed(2)}</div>
          </div>
        </div>
      </div>

      {[COINFLIP_JOINED_STATUS, COINFLIP_ROLLING_STATUS, COINFLIP_FINISH_STATUS].includes(
        status,
      ) && (
        <div className="flex justify-center space-x-10 mx-auto w-full md:w-auto my-20 md:my-0">
          <div
            className="progress-circle text-2xl text-white"
            ref={progress}
            style={{ "--bg": "#7d48cc", "--color": "#2b2746", "--progress": "100%" }}
          >
            9
          </div>
          <button
            type="button"
            className="btn btn--primary btn--lg btn--rounded"
            onClick={() => onWatch(round)}
          >
            {t("coinflip.watch")}
          </button>
        </div>
      )}

      {player2 ? (
        <div className="flex items-center ml-auto">
          <div className="px-20 text-right">
            <div className="text-lg mb-5">{player2.name}</div>
            <div className="flex items-center flex-row-reverse">
              <div className="currency-symbol bg-green">$</div>
              <div className="text-green mx-12">{deposit}</div>
            </div>
          </div>

          <div className="logo-circle bg-red">
            <svg aria-hidden="true">
              <use href="/svg/svg.svg#logo" />
            </svg>
            <svg aria-hidden="true">
              <use href="/svg/svg.svg#logo" />
            </svg>
          </div>
        </div>
      ) : (
        <button
          type="button"
          className="btn btn--lg btn--green btn--rounded mx-auto md:mr-0 mt-10 md:mt-0"
          disabled={disabled || userId === player1.id}
          onClick={() => onJoin()}
        >
          {t("coinflip.join.submit")}
        </button>
      )}
    </li>
  );
};

CoinflipListRound.propTypes = {
  userId: PropTypes.number,
  round: PropTypes.shape({
    id: PropTypes.number,
    deposit: PropTypes.number,
    player1: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    player2: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    status: PropTypes.number,
    waitingTime: PropTypes.number,
  }).isRequired,
  onJoin: PropTypes.func.isRequired,
  onWatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

CoinflipListRound.defaultProps = {
  userId: null,
};

export default CoinflipListRound;
