import React from "react";

const AmlPage = () => {
  return (
    <div className="bg-black rounded">
      <div className="px-15 sm:px-30 py-20 border-b-4 border-black-800">AML KYC Policy</div>
      <div className="px-15 sm:px-30 py-20 prose text-gray-700">
        <p>Blackhole N.V.</p>
        <p>Last updated: 14.10.2021</p>
        <p><br/></p>
        <p>Introduction</p>
        <p><br/></p>
        <p>Midasly.com is operated by Blackhole N.V. having its office at Abraham de Veerstraat 9 Willemstad, Curacao. Company Registration number 156046.&nbsp;</p>
        <p><br/></p>
        <p>Blackhole N.V. is licensed and authorized by the Government of Curacao and operates under the Master License of Gaming Services Provider, N.V. #365/JAZ as an Information Service Provider.&nbsp;</p>
        <p><br/></p>
        <p>Objective of the AML Policy</p>
        <p><br/></p>
        <p>We seek to offer the highest security to all of our users and customers on midasly.com.. for that a three step account verification is done in order to insure the identity of our customers.&nbsp;</p>
        <p><br/></p>
        <p>The reason behind this is to prove that the details of the person registered are correct and the deposit methods used are not stolen or being used by someone else, which is to create the general framework for the fight against money laundering. We also take into account that depending on the nationality and origin, the way of payment and for withdrawing different safety measurements must be taken.&nbsp;</p>
        <p><br/></p>
        <p>Blackhole N.V. also puts reasonable measures in place to control and limit ML risk, including dedicating the appropriate means. &nbsp;</p>
        <p><br/></p>
        <p>Blackhole N.V. is committed to high standards of anti-money laundering (AML) according to the EU guidelines, compliance and requires management &amp; employees to enforce these standards in preventing the use of its services for money laundering purposes.&nbsp;</p>
        <p>The AML program of Blackhole N.V. is designed to be compliant with :&nbsp;</p>
        <p><br/></p>
        <p>EU : &ldquo;Directive 2015/849 of the European Parliament and of The Council of 20 May 2015 on the prevention of the use of the financial system for the purposes of money laundering&rdquo;&nbsp;</p>
        <p>EU : &ldquo;Regulation 2015/847 on information accompanying transfers of funds&rdquo;&nbsp;</p>
        <p>EU : Various regulations imposing sanctions or restrictive measures against persons and embargo on certain goods and technology, including all dual-use goods&nbsp;</p>
        <p>BE : &ldquo;Law of 18 September 2017 on the prevention of money laundering limitation of the use of cash</p>
        <p><br/></p>
        <p>Definition of money laundering</p>
        <p><br/></p>
        <p>Money Laundering is understood as:&nbsp;</p>
        <p>&bull; The conversion or transfer of property, especially money, &nbsp;knowing that such property is derived from criminal activity or from taking part in such activity, for the purpose of concealing or disguising the illegal origin of the property or of helping any person who is involved in the commission of such an activity to evade the legal consequences of that person&apos;s or companies action;&nbsp;</p>
        <p>&bull; The concealment or disguise of the true nature, source, location, disposition, movement, rights with respect to, or ownership of, property, knowing that such property is derived from criminal activity or from an act of participation in such an activity;&nbsp;</p>
        <p>&bull; The acquisition, possession or use of property, knowing, at the time of receipt, that such property was derived from criminal activity or from assisting in such an activity;&nbsp;</p>
        <p>&bull; Participation in, association to commit, attempts to commit and aiding, abetting, facilitating and counselling the commission of any of the actions referred to in points before. &nbsp;</p>
        <p><br/></p>
        <p>Money laundering shall be regarded as such even when the activities which generated the property to be laundered were carried out in the territory of another Member State or in that of a third country.&nbsp;</p>
        <p><br/></p>
        <p>Organization of the AML for Blackhole N.V.</p>
        <p><br/></p>
        <p>In accordance with the AML legislation Blackhole N.V. has appointed the &ldquo;highest level&rdquo; for the prevention of ML: The full management of Blackhole N.V. are in charge. &nbsp;</p>
        <p><br/></p>
        <p>Furthermore, an MLRO (Money Laundering Reporting Officer) is in charge of the enforcement of the AML policy and procedures within the System.&nbsp;</p>
        <p><br/></p>
        <p>The AMLCO is placed under the direct responsibility of the general Management.</p>
        <p><br/></p>
        <p>AML policy changes and implementation requirements</p>
        <p><br/></p>
        <p>Each major change of midasly.com.. AML policy is subject to approval by the general management of Blackhole N.V. and the Anti money laundering compliance officer.&nbsp;</p>
        <p><br/></p>
        <p>Three step Verification</p>
        <p><br/></p>
        <p>Step one verification</p>
        <p><br/></p>
        <p>Step one verification must be done by every user and customer to withdraw.</p>
        <p><br/></p>
        <p>Regarding the choice of payment, the amount of payment, the amount of withdraw, the choice of withdraw and nationality of the user/customer step one verification must be done first.&nbsp;</p>
        <p><br/></p>
        <p>Step one verification is a document that must be filled out by the user/customer himself. Following information must be filled in: first name, second name, date of birth, country of usual residence, gender and full address.&nbsp;</p>
        <p><br/></p>
        <p>The customer is also obliged to provide &nbsp;the scan of his passport or id. Sometimes, a selfie with passport hold in hands will be required.&nbsp;</p>
        <p><br/></p>
        <p>Step two verification</p>
        <p><br/></p>
        <p>Step two verification must be done by every user who wants to make a withdrawal to a card or electronic money wallet.&nbsp;</p>
        <p><br/></p>
        <p>Until step two verification is done the withdraw, tip or deposit will be hold.&nbsp;</p>
        <p><br/></p>
        <p>Step two verification will lead the user or customer to a subpage where he must send the following confirmation:</p>
        <p><br/></p>
        <p>If the user wishes to withdraw to a bank card, he will required to send a photo of the bank card he wishes to withdraw to. A photo of the front and back of the card will be necessary. The first 6 digits and &nbsp;the last 4 digits of the card should be visible. CVV code should be blurred.&nbsp;</p>
        <p><br/></p>
        <p>If the card does not contain the name of the card holder, we would require a screenshot from the mobile banking profile.&nbsp;</p>
        <p><br/></p>
        <p>If the users wishes to withdraw to an electronic money wallet, we would require a screenshot of the users electronic money wallet profile.&nbsp;</p>
        <p><br/></p>
        <p>Step three verification</p>
        <p><br/></p>
        <p>Step three verification must be done by every user which deposit over 5000 EUR (five thousand Euro) or withdraws over 5000 EUR (five thousand Euro) or sends another user over 3000 EUR (three thousand EUR) Until step three verification is done the withdraw, tip or deposit will be hold. For step 3 a user/customer will be asked for a source of wealth.&nbsp;</p>
        <p><br/></p>
        <p>Customer identification and verification (KYC)&nbsp;</p>
        <p><br/></p>
        <p>The formal identification of customers on entry into commercial relations is a vital element, both for the regulations relating to money laundering and for the KYC policy.&nbsp;</p>
        <p><br/></p>
        <p>This identification relies on the following fundamental principles:&nbsp;</p>
        <p><br/></p>
        <p>A copy of your passport, ID card or driving license, each shown alongside a handwritten note mentioning six random generated numbers.&nbsp;</p>
        <p><br/></p>
        <p>Also, a second picture with the face of the user/customer is required. The user/customer may blur out every information, besides date of birth, nationality, gender, first name, second name and the picture. To secure their privacy.&nbsp;</p>
        <p><br/></p>
        <p>Please note that all four corners of the ID have to be visible in the same image and all details has to be clearly readable besides the named above. We might ask for all details if necessary.&nbsp;</p>
        <p>An employee may do additional checks if necessary, based on the situation.</p>
        <p><br/></p>
        <p>Sanctions list and PEP (politically exposed person) check</p>
        <p><br/></p>
        <p>A Politically Exposed Person (PEP), is an individual who is in a notable public position such as a President, Politician, Mayor, etc.&nbsp;</p>
        <p><br/></p>
        <p>Transactions involved with PEPs are considered as high-risk transactions, Blackhole N.V. needs to be aware of your status to minimise this risk.&nbsp;</p>
        <p><br/></p>
        <p>&nbsp;We divide PEP&rsquo;s into the following categories:</p>
        <p><br/></p>
        <p>Foreign PEPs includes Heads of State or of government, senior politicians, senior government, judicial or military officials, senior executives of state owned corporations, important political party officials in a foreign country.</p>
        <p>Domestic PEPs like foreign PEPs are individuals who are or have been entrusted domestically with prominent public functions.</p>
        <p>International organization PEPs are persons who are or have been entrusted with a prominent position by an international organization. This may be directors, deputy directors and members of the board or equivalent functions.</p>
        <p>Family members are individuals who are related to a PEP either directly (consanguinity) or through marriage or similar (civil) forms of partnership.</p>
        <p>Close associates are individuals who are closely connected to a PEP, either socially or professionally.</p>
        <p><br/></p>
        <p>Sources of information for PEPs databases are almost exclusively official government websites (presidency, government, legislative bodies, supreme courts of justice, military administration etc.) and official gazettes where laws, decrees, investitures and depositions are published.</p>
        <p><br/></p>
        <p>The lists we check you comprises the following sanctions lists: &nbsp;</p>
        <p><br/></p>
        <p>United Nations;</p>
        <p>European Union;</p>
        <p>US Treasury Department;</p>
        <p>The United Kingdom Ministry of Finance;</p>
        <p>Switzerland SECO;</p>
        <p>Australian sanctions.</p>
        <p><br/></p>
        <p>PEP lists are always dynamic and are constantly being updated.</p>
        <p>&nbsp;</p>
        <p>Blackhole N.V. does not provide services to individuals listed in the PEP lists and sanctions lists.&nbsp;</p>
        <p><br/></p>
        <p>Proof of Address</p>
        <p><br/></p>
        <p>Proof of address will be done via to different electronic checks, which use two different databases. If an electronic test fails, the user/customer has the option to make a manually proof.&nbsp;</p>
        <p><br/></p>
        <p>A recent utility bill sent to your registered address, issued within the last 3 months or an official document made by the government that proofs your state of residence.&nbsp;</p>
        <p><br/></p>
        <p>To make the approval process as speedy as possible, please make sure the document is sent with a clear resolution where all four corners of the document is visible, and all text is readable.</p>
        <p><br/></p>
        <p>For example: An electricity bill, water bill, bank statement or any governmental post addressed to you.</p>
        <p><br/></p>
        <p>An employee may do additional checks if necessary, based on the situation.</p>
        <p><br/></p>
        <p>Source of funds</p>
        <p><br/></p>
        <p>If a player deposits over a five thousand euro there is a process of understandings the source of wealth (SOW)</p>
        <p><br/></p>
        <p>Examples of SOW are:</p>
        <p>● &nbsp; &nbsp; &nbsp;Ownership of business</p>
        <p>● &nbsp; &nbsp; &nbsp;Employment</p>
        <p>● &nbsp; &nbsp; &nbsp;Inheritance&nbsp;</p>
        <p>● &nbsp; &nbsp; &nbsp;Investment&nbsp;</p>
        <p>● &nbsp; &nbsp; &nbsp;Family&nbsp;</p>
        <p><br/></p>
        <p>It is critical that the origin and legitimacy of that wealth is clearly understood. If this is not possible an employee may ask for an additional document or prove. &nbsp;</p>
        <p><br/></p>
        <p>The account will be frozen if the same user deposits either this amount in one go or multiple transactions which amount to this. An email will be sent to them manually to go through the above and an information on the website itself.&nbsp;</p>
        <p><br/></p>
        <p>Blackhole N.V. also asks for a bank wire/credit card to further insure the Identity of the user/customer. It also gives additional information about the financial situation of the user/customer.&nbsp;</p>
        <p><br/></p>
        <p>Basic document for step one:&nbsp;</p>
        <p>The basic document will be accessible via the setting page on Blackhole N.V.. Every user has to fill out the following information:&nbsp;</p>
        <p>● &nbsp; &nbsp; &nbsp;First name&nbsp;</p>
        <p>● &nbsp; &nbsp; &nbsp;Second name</p>
        <p>● &nbsp; &nbsp; &nbsp;Nationality</p>
        <p>● &nbsp; &nbsp; &nbsp;Gender&nbsp;</p>
        <p>● &nbsp; &nbsp; &nbsp;Date of Birth&nbsp;</p>
        <p><br/></p>
        <p>The document will be saved and created by an AI, an employee may do additional checks if necessary based on the situation.</p>
        <p><br/></p>
        <p>Risk management</p>
        <p><br/></p>
        <p>In order to deal with the different risks and different states of wealth in different regions on the earth Blackhole N.V. will categorize every nation in three different regions of risk.</p>
        <p><br/></p>
        <p>Region one: Low risk:</p>
        <p>For every nation from the region one the three-step verification is done as described earlier.&nbsp;</p>
        <p><br/></p>
        <p>Region two: Medium risk:</p>
        <p>For every nation from the region two the three-step verification will be done at lower deposit, withdraw and tip amounts. Step one will be done as usually. Step two will be done after depositing 1000 EUR (one thousand Euro), withdrawing 1000 EUR (one thousand Euro) or tipping another user/customer 500 EUR (five hundred Euro.) Step three will be done after depositing 2500 EUR (two thousand five hundred Euro), withdrawing 2500 EUR (two thousand five hundred Euro) or tipping another user/customer 1000 EUR (one thousand Euro). Also, users from a low risk region that change crypto currency in any other currency will be treated like user/customers from a medium risk region.&nbsp;</p>
        <p><br/></p>
        <p>Region three: High risk:</p>
        <p>Regions of high risks will be banned. High risk regions will be regularly updated to keep up with the changing environment of a fast-changing world.&nbsp;</p>
        <p><br/></p>
        <p>Additional measurements.&nbsp;</p>
        <p><br/></p>
        <p>In addition, an AI which is overseen by the AML compliance officer will look for any unusual behaviour and report it right away to a employee of Blackhole N.V.</p>
        <p><br/></p>
        <p>According to a risk based few and general experience the human employees will recheck all checks which are done before by the AI or other employees and may redo or do additional checks according to the situation.&nbsp;</p>
        <p>In addition, a data Scientist supported by modern, electronic, analytic systems will look for unusual behaviour like: Depositing and withdrawing without longer Betting sessions.&nbsp;</p>
        <p><br/></p>
        <p>Attempts to use a different Bank account to for Deposit and Withdraw, nationality changes, currency changes, behaviour and activity changes as well as checks, if an account is used by it&acute;s original owner.&nbsp;</p>
        <p><br/></p>
        <p>Also a User has to use the same method for Withdraw as he used for Deposit, for the amount of the initial Deposit to prevent any Money Laundering.&nbsp;</p>
        <p><br/></p>
        <p>Enterprise-wide risk assessment&nbsp;</p>
        <p><br/></p>
        <p>As part of its risk-based approach, Blackhole N.V. has conducted an AML &ldquo;Enterprise-wide risk assessment&rdquo; (EWRA) to identify and understand risks specific to Blackhole N.V. and its business lines. The AML risk policy is determined after identifying and documenting the risks inherent to its business lines such as the services the website offers. The Users to whom services are offered, transactions performed by these Users, delivery channels used by the bank, the geographic locations of the bank&rsquo;s operations, customers and transactions and other qualitative and emerging risks.&nbsp;</p>
        <p><br/></p>
        <p>The identification of AML risk categories is based on Blackhole N.V. understanding of regulatory requirements, regulatory expectations and industry guidance. Additional safety measures are taken to take care of the additional risks the world wide web brings with it.&nbsp;</p>
        <p>The EWRA is yearly reassessed.&nbsp;</p>
        <p><br/></p>
        <p>Ongoing transaction monitoring&nbsp;</p>
        <p><br/></p>
        <p>AML-Compliance ensures that an &ldquo;ongoing transaction monitoring&rdquo; is conducted to detect transactions which are unusual or suspicious compared to the customer profile.</p>
        <p>This transaction monitoring is conducted on two levels:&nbsp;</p>
        <p><br/></p>
        <p>1) The first Line of Control:&nbsp;</p>
        <p><br/></p>
        <p>Blackhole N.V. works solely with trusted Payment Service Providers whom all have effective AML policies in place as to prevent the large majority of suspicious deposits onto Blackhole N.V. from taking place without proper execution of KYC procedures onto the potential customer.&nbsp;</p>
        <p><br/></p>
        <p>2) The second Line of Control:</p>
        <p><br/></p>
        <p>Blackhole N.V. makes its network aware so that any contact with the customer or player or authorized representative must give rise to the exercise of due diligence on transactions on the account concerned. In particular these include:&nbsp;</p>
        <p>&bull; &nbsp; &nbsp; &nbsp; Requests for the execution of financial transactions on the account;&nbsp;</p>
        <p>&bull; &nbsp; &nbsp; &nbsp; Requests in relation to means of payment or services on the account;&nbsp;</p>
        <p><br/></p>
        <p>Also, the three-step verification with adjusted risk management should provide all necessary information&rsquo;s about all costumers of Blackhole N.V. at all time.&nbsp;</p>
        <p><br/></p>
        <p>Also, all transaction must be overseen by employees over watched by the AML compliance officer who is over watched by the general management.&nbsp;</p>
        <p><br/></p>
        <p>The specific transactions submitted to the customer support manager, possibly through their Compliance Manager must also be subject to due diligence.&nbsp;</p>
        <p><br/></p>
        <p>Determination of the unusual nature of one or more transactions essentially depends on a subjective assessment, in relation to the knowledge of the customer (KYC), their financial behaviour and the transaction counterparty.&nbsp;</p>
        <p><br/></p>
        <p>These checks will be done by an automated System, while an Employee crosschecks them for additional security.&nbsp;</p>
        <p><br/></p>
        <p>The transactions observed on customer accounts for which it is difficult to gain a proper understanding of the lawful activities and origin of funds must therefore rapidly be considered atypical (as they are not directly justifiable).&nbsp;</p>
        <p><br/></p>
        <p>Any Blackhole N.V. staff member must inform the AML division of any atypical transactions which they observe and cannot attribute to a lawful activity or source of income known of the customer.&nbsp;</p>
        <p><br/></p>
        <p>3) The third Line of Control:&nbsp;</p>
        <p>As a last line of defence against AML Blackhole N.V. will do manually checks on all suspicious and higher risk users in order to fully prevent money laundering.&nbsp;</p>
        <p>If fraud or Money Laundering is found the authorities will be informed.&nbsp;</p>
        <p><br/></p>
        <p>Reporting of Suspicious transactions on Midasly.com..</p>
        <p><br/></p>
        <p>In its internal procedures, Blackhole N.V. describes in precise terms, for the attention of its staff members, when it is necessary to report and how to proceed with such reporting.&nbsp;</p>
        <p>Reports of atypical transactions are analysed within the AML team in accordance with the precise methodology fully described in the internal procedures.&nbsp;</p>
        <p>Depending on the result of this examination and on the basis of the information gathered, the AML team:&nbsp;</p>
        <p><br/></p>
        <p>&bull; &nbsp;will decide whether it is necessary or not to send a report to the FIU, in accordance with the legal obligations provided in the Law of 18 September 2017;&nbsp;</p>
        <p>&bull; &nbsp;will decide whether or not it is necessary to terminate the business relations with the customer.&nbsp;</p>
        <p><br/></p>
        <p>Procedures</p>
        <p><br/></p>
        <p>The AML rules, including minimum KYC standards will be translated into operational guidance or procedures that are available on the Intranet site of Blackhole N.V..</p>
        <p><br/></p>
        <p>Record keeping&nbsp;</p>
        <p><br/></p>
        <p>Records of data obtained for the purpose of identification must be kept for at least ten years after the business relationship has ended.&nbsp;</p>
        <p><br/></p>
        <p>Records of all transaction data must be kept for at least ten years following the carrying-out of the transactions or the end of the business relationship.&nbsp;</p>
        <p><br/></p>
        <p>These data will be safely, encrypted stored offline and online.&nbsp;</p>
        <p><br/></p>
        <p>Training</p>
        <p><br/></p>
        <p>Blackhole N.V. human employees will make manual controls on a risk based approve for which they get special training.&nbsp;</p>
        <p><br/></p>
        <p>The training and awareness program is reflected by its usage:&nbsp;</p>
        <p><br/></p>
        <p>- A mandatory AML training program in accordance with the latest regulatory evolutions, for all in touch with finances&nbsp;</p>
        <p>- &nbsp;Academic AML learning sessions for all new employees&nbsp;</p>
        <p>The content of this training program has to be established in accordance with the kind of business the trainees are working for and the posts they hold. These sessions are given by an AML-specialist working in Blackhole N.V. &nbsp;AML team.</p>
        <p><br/></p>
        <p>Employees shall be instructed as following:</p>
        <p><br/></p>
        <p>Definition</p>
        <p><br/></p>
        <p>Suspicious transaction means a transaction conducted or attempted by, at, or through the Gaming Operation that the Gaming Facility Operator knows, suspects, or has reason to suspect. A transaction which is inconsistent with a customer&apos;s known legitimate business or personal activities or with the normal business for the type of securities holdings or contract which the customer holds.</p>
        <p><br/></p>
        <p>Failure to comply with the requirements of this section may constitute a violation of the reporting rules of the Curacao (formerly Netherland Antilles) Gaming Authority and will result in immediate revocation of License.</p>
        <p><br/></p>
        <p>How to identify a Suspicion?</p>
        <p><br/></p>
        <p>An effective systemic approach to identifying suspicious financial activity may safeguard you from the risk of being involved with terrorist financing and money laundering crimes. Use the &ldquo;SAFE&rdquo; approach which may assist you in legal compliance and staff training.</p>
        <p>S: Screen</p>
        <p>A: Ask customer&nbsp;</p>
        <p>F: Find</p>
        <p>E: Evaluate</p>
        <p><br/></p>
        <p>Each of the four steps of the systemic approach to suspicious activity identification are discussed in more detail in the following paragraphs.</p>
        <p><br/></p>
        <p>Screen: Screen the account for suspicious indicators</p>
        <p><br/></p>
        <p>The recognition of an indicator, or better still indicators, of suspicious activity is the first step in the suspicious activity identification system. The following are some of the suspicious activity indicators most commonly associated with money laundering.</p>
        <p>Large or frequent cash transaction, either deposits or withdrawals.</p>
        <p><br/></p>
        <p>&bull; Suspicious activity based on transaction pattern, i.e.</p>
        <p>&bull; Involvement of one or more of the following entities which are commonly involved in money laundering,</p>
        <p>&bull; Currencies, countries or national of countries, commonly associated with international crime or drug trafficking or identified as having serious deficiencies in their AML / CFT regimes,</p>
        <p>&bull; Customer refuses, or is unwilling, to provide an explanation of financial activity, or provides an explanation assessed to be untrue.</p>
        <p>&bull; Activity is incommensurate with that expected from the customer considering the information already known to you about the customer and the customers previous financial activity</p>
        <p>&bull; Countries or nationals of countries, commonly associated with terrorist activities or the persons or organizations designated as terrorists or their associates. Details can be found on the website about sanction on terrorists or their associates.</p>
        <p>&bull; Politically Exposed Persons (PEPs)</p>
        <p><br/></p>
        <p>Ask: Ask the customer appropriate questions</p>
        <p><br/></p>
        <p>If your staff carries out a transaction or transactions for a customer, bearing one or more suspicious activity indicators then they should question the customer on the reason for conducting the transaction and the &nbsp;identity of the source and ultimate beneficiary of the money being transacted.</p>
        <p><br/></p>
        <p>On occasions some of the staff may be reluctance to ask questions of the type mentioned above. Grounds &nbsp;for this reluctance are that the customer may realize that he, or she, is suspected of illegal activity, or &nbsp;regards such questions as none of the questioner&apos;s business.</p>
        <p><br/></p>
        <p>Appropriate questions to ask in order to obtain an explanation of the reason for conducting a transaction &nbsp;bearing suspicious activity indicators will depend upon the circumstances of the financial activity observed.</p>
        <p><br/></p>
        <p>Another example is when a customer receives &quot;structured&quot; remittances from overseas. In such &nbsp;circumstances staff could question the customer on the reason for receiving numerous remittances within &nbsp;a short period of time on the grounds that one larger remittance would be quicker, cheaper for the sender to send, and less time consuming for the recipient to handle.</p>
        <p><br/></p>
        <p>Persons engaged in legitimate business generally have no objection to, or hesitation in answering such questions. Persons involved in illegal activity are more likely to refuse to answer, give only a partial explanation or give an explanation which is unlikely to be true.</p>
        <p>If a customer is unwilling, or refuses, to answer questions or gives replies which staff suspect are incorrect or untrue, this may be taken as a further indication of the suspicious nature of the financial activity.</p>
        <p><br/></p>
        <p>Find: Find out the customer&rsquo;s records</p>
        <p>The third stage in the systemic approach to suspicious activity identification is to review the information already known about the customer and his, or her, previous transactions and consider this information to decide if the apparently suspicious activity is to be expected from the customer. This stage is commonly &nbsp;known as the &quot;know your customer principle&quot;.</p>
        <p><br/></p>
        <p>Institutions, businesses or professions hold various pieces of information on their customers which can be useful when considering if the customers&apos; financial activity is to be expected or is unusual. Examples of some of these information items and the conclusions which may be drawn from them are listed below.</p>
        <p><br/></p>
        <p>Evaluate: Evaluate all the above information.</p>
        <p><br/></p>
        <p>The final step in the suspicious activity identification system is the decision whether or not to make an STR. Due to the fact that suspicion is difficult to quantify, it is not possible to give exact guidelines on the circumstances in which an STR should, or should not, be made. However, such a decision will be of the &nbsp; highest quality when all the relevant circumstances are known to, and considered by, the decision maker, i.e. when all three of the preceding steps in the suspicious transaction identification system have been completed and are considered. If, having considered all the circumstances, staff find the activity genuinely &nbsp;suspicious then an STR should be made.</p>
        <p><br/></p>
        <p>Staff will seldom, if ever, encounter a situation in which a customer carries out apparently suspicious activity which they have reason to suspect is linked to a specific crime or type of crime. Institutions, businesses or professions are therefore encouraged to make STRs in circumstances where, although they do not know which specific crime or type of crime is connected to the suspicious transaction, there are one or more suspicious activity indicators present, together with an inability or unwillingness on the part of the customer</p>
        <p>to give a reasonable and legitimate explanation and inconsistency between the financial activity undertaken and that which is to be expected from the customer.</p>
        <p><br/></p>
        <p>What to Report in an STR?</p>
        <p><br/></p>
        <p>A STR should include the following details:</p>
        <p>&bull; personal particulars (name, identity card or passport number, date of birth, address, telephone number,</p>
        <p>&nbsp;bank account number) of the person(s) or company involved in the suspicious transaction;</p>
        <p>&bull; details of the suspicious financial activity;</p>
        <p>&bull; the reason why the transaction is suspicious - which suspicious activity indicators are present?</p>
        <p>&bull; the explanation, if any, given by the person about the transaction</p>
        <p><br/></p>
        <p>Auditing</p>
        <p><br/></p>
        <p>Internal audit regularly establishes missions and reports about AML activities.&nbsp;</p>
        <p><br/></p>
        <p>Data Security</p>
        <p><br/></p>
        <p>All data given by any user/customer will be kept secure, will not be sold or given to anyone else. Only if forced by law, or to prevent money laundering data may be shared with the AML-authority of the affected state.&nbsp;</p>
        <p>Blackhole N.V. will follow all guidelines and rules of the data protection directive (officially Directive 95/46/EC)&nbsp;</p>
        <p><br/></p>
        <p>Money Laundering Reporting Officer (MLRO)</p>
        <p><br/></p>
        <p>Blackhole N.V. will appoint a senior employee to be the Money Laundering Reporting Officer (MLRO) as required by the Money Laundering Regulation. The MLRO will have the responsibility for the establishment and maintenance of AML/CTF framework of the company and underlying systems and controls and will provide reports to the Board of the company.</p>
        <p><br/></p>
        <p>Blackhole N.V.will ensure that the MLRO is of sufficient seniority within the company and has the relevant experience and understanding of AML/CTF to carry out their duties. Blackhole N.V. will fully support and ensure the MLRO has necessary resources and will provide ongoing support and development for the MLRO.</p>
        <p><br/></p>
        <p>The MLRO, with the support of the Board, is responsible for ensuring that the Company meets its AML compliance requirements in accordance with applicable legislation. The MLRO will oversee the AML systems and controls and ensure they are fit for purpose. The main activities of the MLRO comprise, but are not limited to, the following:</p>
        <p><br/></p>
        <p>- &nbsp;oversight of all aspects of the company&rsquo;s AML and CTF activities;</p>
        <p>- &nbsp;focal point for all activities within the company relating to AML and CTF;</p>
        <p>- &nbsp; provision of AML training to all staff;</p>
        <p>- &nbsp;receiving all internal suspicious activity reports and, where deemed applicable, reporting to relevant authorities on the same;</p>
        <p>- &nbsp; be the focal point for law enforcement and other regulatory bodies;</p>
        <p>- establishing the basis on which a risk-based approach to the prevention of money laundering and terrorist financing is put into practice;</p>
        <p>- supporting and coordinating senior management focus on managing the money laundering/terrorist financing risk in individual business areas; and</p>
        <p>- &nbsp; advising the business on new products / processes from an AML perspective.</p>
        <p><br/></p>
        <p>The MLRO is also required to produce reports for Board meetings including, but not limited to, the following items:</p>
        <p><br/></p>
        <p>- Confirmation that adequate customer due diligence information is being collected and that ongoing monitoring is taking place;</p>
        <p>- &nbsp;Summary data relating to complex or unusual transactions;</p>
        <p>- Number of internal consents / Suspicious Activity Reports received from staff members;</p>
        <p>- &nbsp; Number of Suspicious Activity Reports sent externally;</p>
        <p>- &nbsp;Information on status of staff training within the company;</p>
        <p>- Confirmation that all business records have been properly stored and are retained according to regulatory requirements;</p>
        <p>- &nbsp;Changes in the law/operating environment which do or might impact the business;</p>
        <p>- &nbsp;Changes in the risk matrix affecting the business; and</p>
        <p>- &nbsp;Contacts with the regulator in regards of any Suspicious activity reports.</p>
        <p><br/></p>
        <p>In accordance with our license requirements Blackhole N.V. is obliged to report any suspicious transactions to the license holder Gaming Services Provider, N.V..</p>
        <p><br/></p>
        <p><br/></p>
      </div>
    </div>
  );
};

export default AmlPage;