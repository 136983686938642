import React from "react";
import { useSelector } from "react-redux";
import CrashStats from "@/components/games/crash/CrashStats";

const CrashStatsContainer = () => {
  const statistic = useSelector(({ crash }) => crash.statistic);

  if (!statistic) {
    return <></>;
  }

  return <CrashStats statistic={statistic} />;
};

export default CrashStatsContainer;
