import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { Portal } from "react-portal";
import useOutsideClickHandler from "@/helpers/useOutsideClickHandler";

class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.ref = createRef();
  }

  open() {
    this.setState({
      open: true,
    });
  }

  toggle() {
    this.setState(({ open }) => ({
      open: !open,
    }));
  }

  close() {
    this.setState({
      open: false,
    });
  }

  render() {
    const {
      className,
      openClassName,
      closeClassName,
      dialogClassName,
      contentClassName,
      showCloseButton,
      overlayClose,
      children,
    } = this.props;
    const { open } = this.state;

    return (
      <Portal node={document && document.getElementById("modal-portal")}>
        <div
          className={`modal ${className} ${open ? `modal--active ${openClassName}` : closeClassName}`}
          onClick={event => {
            if (overlayClose) {
              if (this.ref.current && !this.ref.current.contains(event.target)) {
                this.close();
              }
            }
          }}
        >
          <div className={`modal-dialog ${dialogClassName}`} ref={this.ref}>
            <div className={`modal-content ${contentClassName}`}>
              {showCloseButton && (
                <button
                  type="button"
                  className="modal-close btn btn--icon btn--md text-gray-150 z-4"
                  onClick={() => this.close()}
                >
                  <svg aria-hidden="true" className="fill-current w-12 h-12">
                    <use href="/svg/svg.svg#close" />
                  </svg>
                </button>
              )}
              {children}
            </div>
          </div>
        </div>
      </Portal>
    );
  }
}

Modal.propTypes = {
  className: PropTypes.string,
  dialogClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  openClassName: PropTypes.string,
  closeClassName: PropTypes.string,
  showCloseButton: PropTypes.bool,
  overlayClose: PropTypes.bool,
  children: PropTypes.node,
};

Modal.defaultProps = {
  className: "",
  openClassName: "",
  closeClassName: "",
  dialogClassName: "",
  contentClassName: "",
  showCloseButton: false,
  overlayClose: true,
  children: null,
};

export default Modal;
