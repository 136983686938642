import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSubscribe } from "@/libs/centrifuge";
import {
  newMessage,
  removeMessagesByUserId,
  removeMessage,
  clear,
  setPinMessage,
} from "@/actions/chatAction";
import { CHAT_MESSAGE_TYPE_PLAY } from "@/constants/chatMessageTypes";

const ChatSubscriberContainer = () => {
  const subscribe = useSubscribe();
  const dispatch = useDispatch();

  const playAudio = url => {
    const audio = new Audio(url);
    try {
      audio.volume = 0.5;
      audio.play();
    } catch (e) {}
  };

  const normalizeMessage = ({ i, c, t, u, type, payload }) => {
    return {
      id: i,
      content: c,
      createdAt: t,
      user: {
        id: u.i,
        name: u.n,
        steamId: u.s,
        avatar: u.a,
        role: u.r,
      },
      type,
      payload,
    };
  };

  useEffect(() => {
    const unsubscribe = subscribe(`c`, ({ a, p }) => {
      switch (a) {
        case "new": {
          const { c, type } = p;

          if (type === CHAT_MESSAGE_TYPE_PLAY) {
            playAudio(c);
            break;
          }

          dispatch(newMessage(normalizeMessage(p)));

          break;
        }

        case "pinMessage": {
          dispatch(setPinMessage(p ? normalizeMessage(p) : null));

          break;
        }

        case "mute": {
          dispatch(removeMessagesByUserId(p));
          break;
        }
        case "delete": {
          dispatch(removeMessage(p));
          break;
        }
        case "clear": {
          dispatch(clear());
          break;
        }
        default: {
          break;
        }
      }
    });

    return () => unsubscribe();
  }, []);

  return <></>;
};

export default ChatSubscriberContainer;
