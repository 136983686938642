import React from "react";

const TosPage = () => {
  return (
    <div className="bg-black rounded">

    </div>
  );
};

export default TosPage;