import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import noty from "@/libs/noty";

const FileSelector = ({ id, loading, onSubmit }) => {
  const [file, setFile] = useState();
  const { t } = useTranslation();

  const submit = () => {
    if (file) {
      onSubmit(file).then(success => {
        if (success) {
          setFile(null);
        }
      });
    }
  };

  const onChange = e => {
    const file = e.target.files[0];
    if (file.size > 2097152) {
      noty.error(t("fileSelector.bigFile"));
      return;
    }

    setFile(file)
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <label htmlFor={id}>
          <input
            id={id}
            type="file"
            className="sr-only"
            accept="image/jpeg,image/png,application/pdf"
            onChange={onChange}
          />
          <div className="btn btn--gray btn--rounded btn--sm px-20 cursor-pointer">
            {file ? file.name : t("fileSelector.chooseFile")}
          </div>
        </label>
        <button
          type="button"
          disabled={!file}
          className={`btn btn--green btn--sm btn--rounded ${loading ? "loading" : ""}`}
          onClick={submit}
        >
          {t("fileSelector.button")}
        </button>
      </div>
    </>
  );
};

FileSelector.propTypes = {
  id: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default FileSelector;
