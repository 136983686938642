import React from "react";
import PropTypes from "prop-types";

const CasinopubGame = () => {
  return (
    <div className="game-wrapper">
      <div className="game-wrapper__main">
        <div className="relative" style={{ paddingBottom: "calc(620 / 1100 * 100%)" }}>
          <div
            className="absolute top-0 left-0 w-full h-full bg-black-dark casinopub-iframe"
            id="game-wrapper-casinopub"
          />
        </div>
      </div>
    </div>
  );
};

export default CasinopubGame;
