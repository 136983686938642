import { SET_REFERRAL_STATE, SET_REFERRAL_BALANCE } from "@/constants";

export const setState = payload => {
  return {
    type: SET_REFERRAL_STATE,
    payload,
  };
};

export const setBalance = payload => {
  return {
    type: SET_REFERRAL_BALANCE,
    payload,
  };
};
