import React from "react";
import PropTypes from "prop-types";

const Header = ({ children }) => {
  return (
    <div className="header-wrapper">
      <div className="header">
        <div className="header__inner">
          <a href="/" className="logo mr-auto lg:mr-0" aria-label="home">
            <svg aria-hidden="true">
              <use href="/svg/svg.svg#logo" />
            </svg>
            <span>Midasly</span>
          </a>
          {children}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Header;
