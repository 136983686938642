import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useOutsideClickHandler from "@/helpers/useOutsideClickHandler";

const GameListItem = ({ id, path, children, games, className }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const ref = useRef();
  useOutsideClickHandler(ref, () => {
    setOpen(false);
  });

  return (
    <div className={`game-preview ${className}`} ref={ref}>
      {games && (
        <div className="game-preview__toggler">
          <svg aria-hidden="true">
            <use href="/svg/svg.svg#arrow-down" />
          </svg>
        </div>
      )}
      {path ? (
        <div className="game-preview__ill">
          <Link to={path} className="game-preview__ill-inner">
            {children}
          </Link>
        </div>
      ) : (
        <button type="button" onClick={() => setOpen(!open)} className="game-preview__ill">
          <div className="game-preview__ill-inner">
            {children}
          </div>
        </button>
      )}

      {games && (
        <div
          className="game-preview__dropdown dropdown-menu dropdown-menu--no-tail"
          style={open ? {} : { display: "none" }}
        >
          <div className="font-medium text-gray text-lg py-10 pl-30">{t(`gameList.${id}`)}</div>
          <ul className="w-300">
            {games.map(({ id: gameChildId, type, path, image }) => (
              <li key={gameChildId}>
                <Link to={path} className="dropdown-item">
                  <div className="w-50 h-50 overflow-hidden relative rounded-circle">
                    <img src={image} alt="" className="image-full object-center" />
                  </div>
                  <div className="ml-12">
                    <div className="text-black-900 font-medium text-lg">
                      {t(`gameList.${gameChildId}`)}
                    </div>
                    <div className="text-gray uppercase text-sm">{type}</div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="game-preview__footer">
        <div className="game-preview__title">{t(`gameList.${id}`)}</div>
        {games && (
          <div className="game-preview__includes">
            {t("gameList.includes", { count: games.length })}
          </div>
        )}
      </div>
    </div>
  );
};

GameListItem.propTypes = {
  id: PropTypes.string.isRequired,
  path: PropTypes.string,
  children: PropTypes.node.isRequired,
  games: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string,
      path: PropTypes.string.isRequired,
      image: PropTypes.string,
    }),
  ),
  className: PropTypes.string,
};

GameListItem.defaultProps = {
  path: null,
  games: null,
  className: "",
};

export default GameListItem;
