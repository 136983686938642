import {
  SET_LIVE_COUNT,
  SET_LIVE_HIGH,
  SET_LIVE_LUCKY,
  SET_LIVE_ALL,
  ADD_LIVE_ALL,
  ADD_LIVE_HIGH,
  ADD_LIVE_LUCKY,
  SET_LIVE,
} from "@/constants";

export const setLive = payload => {
  return {
    type: SET_LIVE,
    payload,
  };
};

export const setLiveCount = count => {
  return {
    type: SET_LIVE_COUNT,
    payload: count,
  };
};

export const setLiveHigh = high => {
  return {
    type: SET_LIVE_HIGH,
    payload: high,
  };
};

export const setLiveLucky = lucky => {
  return {
    type: SET_LIVE_LUCKY,
    payload: lucky,
  };
};

export const setLiveAll = all => {
  return {
    type: SET_LIVE_ALL,
    payload: all,
  };
};

export const addLiveAll = bet => {
  return {
    type: ADD_LIVE_ALL,
    payload: bet,
  };
};

export const addLiveHigh = bet => {
  return {
    type: ADD_LIVE_HIGH,
    payload: bet,
  };
};

export const addLiveLucky = bet => {
  return {
    type: ADD_LIVE_LUCKY,
    payload: bet,
  };
};
