import { clear } from "@/actions/authAction";
import noty from "@/libs/noty";
import authToken from "@/helpers/authToken";

let lockedSendError = false;

const setup = (axios, store, i18n) => {
  const { dispatch } = store;

  const setHeader = req => {
    const token = authToken.get();
    if (token) {
      req.headers.Authorization = `JWT ${token}`;
    } else {
      delete req.headers.Authorization;
    }
    return req;
  };

  axios.interceptors.request.use(setHeader);

  const modifyResponse = request => {
    if (request.data) {
      return request.data.data;
    }

    return request;
  };

  const errorHandler = error => {
    if (error.message === "Network Error") {
      if (!lockedSendError) {
        noty.error(i18n.t("networkError"));
        lockedSendError = true;
        setTimeout(() => {
          lockedSendError = false;
        }, 2000);
      }
    }

    if (error.response) {
      if (authToken.get() && error.response.status === 401) {
        dispatch(clear());
        authToken.remove();
      }

      if (error.response.status === 418) {
        noty.error(i18n.t("systemLockedError"));
      }
    }

    throw error;
  };

  axios.interceptors.response.use(modifyResponse, errorHandler);
};

export default setup;
