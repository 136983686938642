import React from "react";
import ChatToggleContainer from "@/containers/chat/ChatToggleContainer";
import ChatSubscriberContainer from "@/containers/chat/ChatSubscriberContainer";
import ChatMainContainer from "@/containers/chat/ChatMainContainer";
import ChatStateContainer from "@/containers/chat/ChatStateContainer";

const ChatContainer = () => {
  return (
    <>
      <ChatToggleContainer />
      <ChatMainContainer />
      <ChatStateContainer />
      <ChatSubscriberContainer />
    </>
  );
};

export default ChatContainer;
