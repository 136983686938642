import {
  AUTH_SET_TOKEN,
  AUTH_CLEAR,
  SET_USER,
  SET_USER_LANG,
  SET_USER_BALANCE,
  SET_USER_CLIENT_SEED,
  SET_NOTIFICATIONS,
  NEW_NOTIFICATION,
  UPDATE_USER,
} from "@/constants";

export const clear = () => {
  return {
    type: AUTH_CLEAR,
  };
};

export const setToken = token => {
  return {
    type: AUTH_SET_TOKEN,
    payload: token,
  };
};

export const setUser = user => {
  return {
    type: SET_USER,
    payload: user,
  };
};

export const updateUser = user => {
  return {
    type: UPDATE_USER,
    payload: user,
  };
};

export const setBalance = balance => {
  return {
    type: SET_USER_BALANCE,
    payload: balance,
  };
};

export const setUserLang = lang => {
  return {
    type: SET_USER_LANG,
    payload: lang,
  };
};

export const setUserClientSeed = clientSeed => {
  return {
    type: SET_USER_CLIENT_SEED,
    payload: clientSeed,
  };
};

export const setNotifications = notifications => {
  return {
    type: SET_NOTIFICATIONS,
    payload: notifications,
  };
};

export const newNotification = notification => {
  return {
    type: NEW_NOTIFICATION,
    payload: notification,
  };
};
