import axios from "axios";
import config from "@/config";
import setup from "@/api/setup";

const createClient = (store, i18n) => {
  const client = axios.create({
    baseURL: config.apiUrl,
    responseType: "json",
  });

  setup(client, store, i18n);

  return client;
};

export default createClient;
