import React from "react";
import Navigation from "@/components/Navigation";

const NavigationContainer = () => {
  const links = [
    {
      id: "main",
      path: "/",
    },
    {
      id: "fair",
      path: "/fair",
    },
    {
      id: "support",
      path: "/support",
    },
    {
      id: "cooperation",
      path: "/cooperation",
    },
  ];


  return <Navigation links={links} />;
};

export default NavigationContainer;
