import moment from "moment";

const toLocaleDate = (date, format, parseFormat) => {
  const stillUtc = moment.utc(date, parseFormat || null).toDate();
  return moment(stillUtc)
    .local()
    .format(format || "DD.MM.YYYY HH:mm:ss");
};

export default toLocaleDate;
