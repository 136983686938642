import React from "react";
import PropTypes from "prop-types";

const PaymentMethods = ({ paymentMethods, activePaymentMethod, onSelect }) => {
  return (
    <ul className="payment-methods">
      {paymentMethods.map(paymentMethod => {
        const { id, img } = paymentMethod;

        return (
          <li key={id}>
            <button
              type="button"
              className={`payment-methods__btn ${
                activePaymentMethod && activePaymentMethod.id === paymentMethod.id ? "selected" : ""
              }`}
              onClick={() => onSelect(paymentMethod)}
            >
              <img src={img} alt="" />
            </button>
          </li>
        );
      })}
    </ul>
  );
};

PaymentMethods.propTypes = {
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      img: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  activePaymentMethod: PropTypes.shape({
    id: PropTypes.number.isRequired,
    img: PropTypes.string.isRequired,
  }),
};

PaymentMethods.defaultProps = {
  activePaymentMethod: null,
};

export default PaymentMethods;
