import {
  SET_CHAT_OPEN,
  SET_CHAT_MESSAGES,
  ADD_NEW_CHAT_MESSAGE,
  REMOVE_CHAT_MESSAGES_BY_USER_ID,
  REMOVE_CHAT_MESSAGE,
  CLEAR_CHAT,
  SET_CHAT_HIDE_STICKERS,
  SET_CHAT_ONLINE,
  SET_CHAT_PIN_MESSAGE,
} from "@/constants";

export const setOpen = open => {
  return {
    type: SET_CHAT_OPEN,
    payload: open,
  };
};

export const setOnline = online => {
  return {
    type: SET_CHAT_ONLINE,
    payload: online,
  };
};


export const setMessages = messages => {
  return {
    type: SET_CHAT_MESSAGES,
    payload: messages,
  };
};

export const newMessage = message => {
  return {
    type: ADD_NEW_CHAT_MESSAGE,
    payload: message,
  };
};

export const removeMessagesByUserId = userId => {
  return {
    type: REMOVE_CHAT_MESSAGES_BY_USER_ID,
    payload: userId,
  };
};

export const removeMessage = messageId => {
  return {
    type: REMOVE_CHAT_MESSAGE,
    payload: messageId,
  };
};

export const clear = () => {
  return {
    type: CLEAR_CHAT,
    payload: null,
  };
};

export const setHideStickers = isHide => {
  return {
    type: SET_CHAT_HIDE_STICKERS,
    payload: isHide,
  };
};

export const setPinMessage = pinMessage => {
  return {
    type: SET_CHAT_PIN_MESSAGE,
    payload: pinMessage,
  }
}