import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ProfileChangeEmail = ({ onSubmit, email, isVerified }) => {
  const { t } = useTranslation();
  const [newEmail, setNewEmail] = useState(email);

  useEffect(() => {
    if (!newEmail && email) {
      setNewEmail(email);
    }
  }, [email, newEmail, setNewEmail]);

  return (
    <label className="field-wrapper" htmlFor="change-email">
      <div className="field-wrapper__title text-black-900">{t("profile.changeEmail.title")}</div>
      <input
        type="text"
        id="change-email"
        disabled
        className="text-field text-field--light"
        value={newEmail}
        onChange={e => setNewEmail(e.target.value)}
        onBlur={e => setNewEmail(e.target.value)}
        placeholder={t("profile.changeEmail.placeholder")}
      />
      <>
        {isVerified && (
          <div className="field-wrapper__description text-right">
            <div className="text-green inline-flex items-center">
              <svg aria-hidden="true" className="fill-current w-12 h-12 mr-10">
                <use href="/svg/svg.svg#basic-tick" />
              </svg>
              {t("profile.changeEmail.verified")}
            </div>
          </div>
        )}
        {!isVerified && (
          <div className="field-wrapper__description text-right">
            <div className="text-red inline-flex items-center">
              {t("profile.changeEmail.notVerified")}
            </div>
          </div>
        )}
      </>
    </label>
  );
};

ProfileChangeEmail.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  email: PropTypes.string,
  isVerified: PropTypes.bool,
};

ProfileChangeEmail.defaultProps = {
  email: null,
  isVerified: null,
};

export default ProfileChangeEmail;
