import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TabModal from "@/components/TabModal";
import ProfileGeneralContainer from "@/containers/profile/ProfileGeneralContainer";
import ProfileSecurityContainer from "@/containers/profile/ProfileSecurityContainer";
import ProfilePrivacyContainer from "@/containers/profile/ProfilePrivacyContainer";
import ProfileVerificationContainer from "@/containers/profile/ProfileVerificationContainer";
import ProfileUsersBlockedContainer from "@/containers/profile/ProfileUsersBlockedContainer";

const links = [
  {
    id: "general",
    icon: (
      <svg aria-hidden="true" className="w-18 h-16">
        <use href="/svg/svg.svg#man-user" />
      </svg>
    ),
    component: <ProfileGeneralContainer />,
  },
  {
    id: "security",
    icon: (
      <svg aria-hidden="true" className="w-16 h-16">
        <use href="/svg/svg.svg#gear" />
      </svg>
    ),
    component: <ProfileSecurityContainer />,
  },
  {
    id: "privacy",
    icon: (
      <svg aria-hidden="true" className="w-16 h-18">
        <use href="/svg/svg.svg#shield-with-lock" />
      </svg>
    ),
    component: <ProfilePrivacyContainer />,
  },
  {
    id: "verification",
    icon: (
      <svg aria-hidden="true" className="w-16 h-18">
        <use href="/svg/svg.svg#security-on" />
      </svg>
    ),
    component: <ProfileVerificationContainer />,
  },
  {
    id: "usersBlocked",
    icon: (
      <svg aria-hidden="true" className="w-16 h-16">
        <use href="/svg/svg.svg#signal" />
      </svg>
    ),
    component: <ProfileUsersBlockedContainer />,
  },
];

const ProfileContainer = ({ onSetRef }) => {
  const { t } = useTranslation();

  return <TabModal onSetRef={onSetRef} links={links} title={t("profile.title")} prefix="profile" />;
};

ProfileContainer.propTypes = {
  onSetRef: PropTypes.func.isRequired,
};

export default ProfileContainer;
