import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import useOutsideClickHandler from "@/helpers/useOutsideClickHandler";
import bodyClassManager from "@/helpers/bodyClassManager";

const bodyClass = bodyClassManager("info-panel-events-active");

const Dropdown = ({ renderButton, wrapperClassName, listClassName, children, onOpen }) => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);

  const toggle = () => setOpen(!open);
  const close = () => setOpen(false);

  useOutsideClickHandler(wrapperRef, close);

  useEffect(() => {
    if (open) {
      bodyClass.show();
      onOpen();
    } else {
      bodyClass.hide();
    }
  }, [open]);

  return (
    <div ref={wrapperRef} className={wrapperClassName}>
      {renderButton({ toggle, open })}
      <div className={`dropdown-menu dropdown-menu-end ${listClassName} ${open ? "" : "hidden"}`}>
        {children}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  renderButton: PropTypes.func.isRequired,
  wrapperClassName: PropTypes.string.isRequired,
  listClassName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onOpen: PropTypes.func,
};

Dropdown.defaultProps = {
  onOpen: () => {},
};

export default Dropdown;
