import React from "react";
import MinesRoundControlContainer from "@/containers/games/mines/MinesRoundControlContainer";
import MinesLastWinsContainer from "@/containers/games/mines/MinesLastWinsContainer";
import GameSidebar from "@/components/games/GameSidebar";

const MinesSidebarContainer = () => {
  return (
    <GameSidebar>
      <MinesRoundControlContainer />
      <MinesLastWinsContainer />
    </GameSidebar>
  );
};

export default MinesSidebarContainer;
