import React, { useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ProfileGeneral = ({ user, onChangeReferrer, children }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState("");

  const { createdAt, referrerName } = user;

  return (
    <div>
      <div>
        <label className="field-wrapper" htmlFor="referrer-name">
          <div className="field-wrapper__title text-black-900">{t("profile.referrer.title")}</div>
          <input
            type="text"
            id="referrer-name"
            className="text-field text-field--light"
            readOnly
            placeholder={referrerName || t("profile.referrer.na")}
          />
        </label>

        {moment.utc().diff(moment(createdAt), "hours") > 12 ? (
          <div className="text-gray-600 mt-20 mb-30">{t("profile.referrer.hoursLimit")}</div>
        ) : (
          <div className="field-wrapper mt-20 mb-30">
            <div className="relative">
              <input
                type="text"
                id="referrer-code"
                placeholder={t("profile.referrer.enterCode")}
                className="text-field text-field--light text-primary-light"
                value={code}
                onChange={e => setCode(e.target.value)}
                onBlur={e => setCode(e.target.value)}
              />
              <button
                type="button"
                className="btn btn--tiny btn--rounded btn--primary-light absolute right-0 top-1/2 transform -translate-y-1/2 z-3 -mt-3"
                onClick={() =>
                  onChangeReferrer(code).then(result => {
                    if (result) {
                      setCode("");
                    }
                  })
                }
              >
                {t("profile.referrer.setNewReferrer")}
              </button>
            </div>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

ProfileGeneral.propTypes = {
  user: PropTypes.shape({
    referrerName: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  onChangeReferrer: PropTypes.func.isRequired,
};

export default ProfileGeneral;
