import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import noty from "@/libs/noty";
import ProfileChangePassword from "@/components/profile/ProfileChangePassword";

const ProfileChangePasswordContainer = () => {
  const { t } = useTranslation();
  const user = useSelector(({ auth }) => auth.user);
  const [pending, setPending] = useState(false);

  const { changePassword } = useApi();

  const submit = ({ oldPassword, newPassword }) => {
    if (pending) {
      return false;
    }

    setPending(true);

    return changePassword({ oldPassword, newPassword })
      .then(() => {
        noty.success(t("profile.changePassword.success"));
        return true;
      })
      .catch(error => {
        const senError = key => noty.error(t(`profile.changePassword.${key}`));
        if (error.response && error.response.status === 422) {
          senError("notValid");
        } else if (error.response && error.response.status === 403) {
          senError(error.response.data.error);
        } else {
          senError("fail");
        }
        return false;
      })
      .finally(() => {
        setPending(false);
      });
  };

  return <ProfileChangePassword withoutPassword={user.withoutPassword} onSubmit={submit} />;
};

export default ProfileChangePasswordContainer;
