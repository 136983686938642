import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AnimateNumber from "@/components/AnimateNumber";

const RouletteGameboardStats = ({ playersCount }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="w-230 mb-30 bg-primary-100 bg-opacity-10 h-2 mx-auto mt-auto" />
      <div className="text-xl font-bold mb-5">
        <AnimateNumber number={playersCount} /> {t("roulette.stats.playersCount")}
      </div>
      <div className="text-gray-600 mb-20">{t("roulette.stats.playersCountDesc")}</div>
    </>
  );
};

RouletteGameboardStats.propTypes = {
  playersCount: PropTypes.number.isRequired,
};

export default RouletteGameboardStats;
