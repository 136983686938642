import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import noty from "@/libs/noty";
import { useApi } from "@/api";
import FileSelector from "@/components/ui/FileSelector";

const ProfileVerificationUploadContainer = ({ id, isUploaded, isVerified }) => {
  const { t } = useTranslation();
  const { userVerificationUpload } = useApi();
  const [loading, setLoading] = useState(false);

  const submit = file => {
    if (loading) {
      return false;
    }

    setLoading(true);

    const formData = new FormData();

    formData.append("field", id);
    formData.append(id, file, file.name);

    return userVerificationUpload(formData)
      .then(() => {
        noty.success(t("profile.verification.upload.success"));
        return true;
      })
      .catch(error => {
        const senError = key => noty.error(t(`profile.verification.upload.${key}`));

        if (error.response && error.response.status === 422) {
          senError("notValid");
        } else if (error.response && error.response.status === 403) {
          senError(error.response.data.error);
        } else {
          senError("fail");
        }
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const label = <div className="text-lg mb-20">{t(`profile.verification.upload.${id}`)}</div>;

  if (isVerified || isUploaded) {
    return (
      <>
        {label}
        <div className="field-wrapper__description text-right">
          <div className="text-green inline-flex items-center">
            <svg aria-hidden="true" className="fill-current w-12 h-12 mr-10">
              <use href="/svg/svg.svg#basic-tick" />
            </svg>
            {t(`profile.verification.upload.${isVerified ? "isVerified" : "isUploaded"}`)}
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      {label}
      <FileSelector id={`verification-upload-${id}`} onSubmit={submit} loading={loading} />
    </>
  );
};

ProfileVerificationUploadContainer.propTypes = {
  id: PropTypes.string.isRequired,
  isUploaded: PropTypes.bool.isRequired,
  isVerified: PropTypes.bool.isRequired,
};

export default ProfileVerificationUploadContainer;
