import React from 'react';

const RouletteGameboardTopScale = () => {
  return (
    <div className="mt-30 mb-15">
      <div className="flex justify-between px-10">
        <i className="h-4 w-2 bg-primary-100 bg-opacity-40" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-4 w-2 bg-primary-100 bg-opacity-40" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-4 w-2 bg-primary-100 bg-opacity-40" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-13 w-2 bg-primary-100 bg-opacity-40" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-4 w-2 bg-primary-100 bg-opacity-40" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-4 w-2 bg-primary-100 bg-opacity-40" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
        <i className="h-4 w-2 bg-primary-100 bg-opacity-40" />
      </div>
    </div>
  );
};

export default RouletteGameboardTopScale;