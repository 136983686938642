import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import noty from "@/libs/noty";
import { useApi } from "@/api";
import useSound from "@/helpers/useSound";
import RouletteControl from "@/components/games/roulette/RouletteControl";

const RouletteControlContainer = () => {
  const sound = useSound();
  const user = useSelector(({ auth }) => auth.user);
  const { t } = useTranslation();
  const { rouletteMake } = useApi();

  const placeBet = ({ deposit, number }) => {
    if (!user) {
      noty.error(t("notAuth"));
      return;
    }

    if (user.balance < deposit) {
      noty.error(t("notEnoughBalance"));
      return;
    }

    rouletteMake({ deposit, number })
      .then(() => {
        sound.bet();
        noty.success(t("roulette.success"));
      })
      .catch(error => {
        if (error.response.status === 403) {
          if (error.response.data.error) {
            noty.error(t(`roulette.error.${error.response.data.error}`));
          }
        }
      });
  };

  return <RouletteControl onPlaceBet={placeBet} />;
};

export default RouletteControlContainer;
