import {
  ROULETTE_BETTING_STATUS,
  ROULETTE_WAITING_STATUS,
  ROULETTE_FINISH_STATUS,
  ROULETTE_ROLLING_STATUS,
} from "@/constants/rouletteStatuses";

export default {
  title: "Roulette",
  status: {
    [ROULETTE_WAITING_STATUS]: "Game starting..",
    [ROULETTE_BETTING_STATUS]: "Rolling In",
    [ROULETTE_ROLLING_STATUS]: "Rolling...",
    [ROULETTE_FINISH_STATUS]: "Game starting...",
  },
  stats: {
    playersCount: "Players",
    playersCountDesc: "Total players in the game",
  },
  success: "You have successfully placed your bid",
  error: {
    NOT_ENOUGH_MONEY: "Not enough money",
    ROUND_NOT_ACCEPT_BETS: "Bets are no longer accepted",
    SERVER_ERROR: "An error has occurred",
  },
  bets: "Bets",
  history: "History",
  deposit: "Bet amount",
  placeBet: "Place Bet",
};
