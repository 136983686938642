import React from "react";
import { useTranslation } from "react-i18next";
import noty from "@/libs/noty";
import { useApi } from "@/api";
import Tip from "@/components/Tip";

const TipContainer = () => {
  const { t } = useTranslation();
  const { sendTip } = useApi();

  const submit = (data, clear) => {
    sendTip(data)
      .then(() => {
        noty.success(t("tip.success"));
        clear();
      })
      .catch(error => {
        let message = "fail";

        if (error.response) {
          if (error.response.status === 403) {
            message = error.response.data.error;
          }
        }

        noty.error(t(`tip.${message}`));
      });
  };

  return <Tip onSubmit={submit} minimumBalance={10} />;
};

export default TipContainer;
