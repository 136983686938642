import {
  SET_MINES_ROUND,
  SET_MINES_ROUND_ID,
  SET_MINES_ROUND_DEMO,
  SET_MINES_ROUND_STATUS,
  SET_MINES_ROUND_STEPS,
  SET_MINES_ROUND_AMOUNT,
  SET_MINES_ROUND_BOMBS,
  SET_MINES_ROUND_BOMB_CELLS,
  SET_MINES_LINE,
  NEW_MINES_LINE_BET,
  SET_MINES_ROUND_WITHDRAW,
  SET_MINES_ROUND_SERVER_SEED,
} from "@/constants";

export const setRound = payload => {
  return {
    type: SET_MINES_ROUND,
    payload,
  };
};

export const setRoundDemo = payload => {
  return {
    type: SET_MINES_ROUND_DEMO,
    payload,
  };
};

export const setRoundId = payload => {
  return {
    type: SET_MINES_ROUND_ID,
    payload,
  };
};

export const setRoundStatus = payload => {
  return {
    type: SET_MINES_ROUND_STATUS,
    payload,
  };
};

export const setRoundSteps = payload => {
  return {
    type: SET_MINES_ROUND_STEPS,
    payload,
  };
};

export const setRoundAmount = payload => {
  return {
    type: SET_MINES_ROUND_AMOUNT,
    payload,
  };
};

export const setRoundBombs = payload => {
  return {
    type: SET_MINES_ROUND_BOMBS,
    payload,
  };
};

export const setRoundBombCells = payload => {
  return {
    type: SET_MINES_ROUND_BOMB_CELLS,
    payload,
  };
};

export const setRoundWithdraw = payload => {
  return {
    type: SET_MINES_ROUND_WITHDRAW,
    payload,
  };
};

export const setRoundServerSeed = payload => {
  return {
    type: SET_MINES_ROUND_SERVER_SEED,
    payload,
  };
};

export const setLine = payload => {
  return {
    type: SET_MINES_LINE,
    payload,
  };
};

export const newLineBet = payload => {
  return {
    type: NEW_MINES_LINE_BET,
    payload,
  };
};
