import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useApi } from "@/api";
import { setMessages } from "@/actions/chatAction";

const ChatStateContainer = () => {
  const { chatAllMessages } = useApi();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMessages([]));
    chatAllMessages().then(({ messages }) => {
      dispatch(setMessages(messages));
    });
  }, []);

  return <></>;
};

export default ChatStateContainer;
