import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import noty from "@/libs/noty";
import { setRoundServerSeed } from "@/actions/minesAction";
import GameFairModalContainer from "@/containers/games/GameFairModalContainer";

const MinesFairModalContainer = () => {
  const { t } = useTranslation();
  const serverSeed = useSelector(({ mines }) => mines.round.serverSeed);
  const nonce = useSelector(({ mines }) => mines.round.nonce);
  const dispatch = useDispatch();

  const { minesChangeServerSeed } = useApi();

  const changeServerSeed = () => {
    minesChangeServerSeed()
      .then(result => {
        if (result.success) {
          dispatch(setRoundServerSeed(result.serverSeed));
        } else {
          noty.error(t("mines.changeServerSeed.failInRound"))
        }
      })
      .catch(() => {
        noty.error(t("mines.changeServerSeed.fail"))
      });
  };

  return (
    <GameFairModalContainer
      serverSeed={serverSeed}
      nonce={nonce}
      changeServerSeed={changeServerSeed}
    />
  );
};

export default MinesFairModalContainer;
