import React from 'react';

const RouletteGameboardMaxProfit = () => {
  return (
    <div className="text-gray items-center flex absolute top-0 right-0 mr-20 mt-20">
      <svg aria-hidden="true" className="fill-current w-16 h-16 mr-12">
        <use href="/svg/svg.svg#info" />
      </svg>
      Max Profit
    </div>
  );
};

export default RouletteGameboardMaxProfit;