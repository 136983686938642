import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Dropdown from "@/components/Dropdown";
import toLocaleDate from "@/helpers/toLocaleDate";

const allowedInstructions = [3, 4, 5, 6, 8, 9];

const Notifications = ({ items, hasRead, onView }) => {
  const { t } = useTranslation();

  const disabled = items.length === 0;

  return (
    <Dropdown
      title={t("notifications.title")}
      renderButton={({ toggle }) => (
        <button
          onClick={toggle}
          disabled={disabled}
          type="button"
          className="btn btn--md btn--rounded btn--white btn--alpha-bg btn--icon dropdown-toggle"
        >
          <svg aria-hidden="true" className="fill-current w-22 h-22">
            <use href="/svg/svg.svg#bell" />
          </svg>
          {hasRead && (
            <div className="bg-green text-white flex items-center font-medium text-tiny rounded-tiny h-15 px-4 absolute top-0 right-0 -mt-4 -mr-4">
              {items.length}
            </div>
          )}
        </button>
      )}
      listClassName="w-250 p-0 -mr-3"
      wrapperClassName="relative ml-10 lg:ml-0 lg:mr-10"
      onOpen={onView}
    >
      <ul className="space-y-10 overflow-auto focus-outline max-h-250 pb-15 pt-5 px-15 divide-y divide-gray-100 disable-scrollbars">
        {items.map(({ id, type, payload, createdAt }) => {
          const transPayload = {
            ...payload,
            instructions: "",
          };

          ["amount", "price", "withdraw"].forEach(key => {
            if (transPayload[key]) {
              transPayload[key] =
                typeof transPayload[key] === "number"
                  ? transPayload[key].toFixed(2)
                  : transPayload[key];
            }
          });


          const { error } = payload;

          if (error && allowedInstructions.includes(Number(error))) {
            transPayload.instructions = t(`notifications.instructions.${error}`);
          }

          return (
            <li key={id} className="text-sm text-black-dark pt-10">
              <div className="mb-10 flex">
                <svg
                  aria-hidden="true"
                  className="fill-current text-gray text-opacity-50 w-20 h-20 mr-8"
                >
                  <use href="/svg/svg.svg#money-back" />
                </svg>
                <div>
                  <div className="text-base"  dangerouslySetInnerHTML={{
                    __html: t(`notifications.${type}.title`, transPayload),
                  }}/>
                  <div className="text-gray">{toLocaleDate(createdAt)}</div>
                </div>
              </div>
              <div className="mb-10"  dangerouslySetInnerHTML={{
                __html: t(`notifications.${type}.description`, transPayload),
              }}/>
            </li>
          );
        })}
      </ul>
    </Dropdown>
  );
};

Notifications.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.number.isRequired,
      isRead: PropTypes.bool.isRequired,
      payload: PropTypes.object.isRequired,
      createdAt: PropTypes.string.isRequired,
    }),
  ).isRequired,
  hasRead: PropTypes.bool.isRequired,
  onView: PropTypes.func.isRequired,
};

export default Notifications;
