import React from "react";
import { useApi } from "@/api";
import SocialAuthContainer from "@/containers/auth/SocialAuthContainer";

const SteamAuthContainer = () => {
  const { linkSteam, signInSteam } = useApi();

  const link = payload => {
    const data = { openidUrl: window.location.href, ...payload };

    return linkSteam(data);
  };

  const sign = payload => {
    const data = { openidUrl: window.location.href, ...payload };

    return signInSteam(data);
  };

  return <SocialAuthContainer link={link} sign={sign} />;
};

export default SteamAuthContainer;
