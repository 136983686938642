import React from "react";
import PropTypes from "prop-types";

const ChatLayout = ({ children, onClickClose }) => {
  return (
    <div className="chat-wrapper">
      <div className="bg-black-950 w-full h-full flex flex-col justify-end items-stretch p-15">
        <div>
          <button
            type="button"
            className="btn btn--fab btn--md btn--black mb-15 lg:hidden"
            onClick={onClickClose}
          >
            <svg aria-hidden="true" className="fill-current w-12 h-12">
              <use href="/svg/svg.svg#close" />
            </svg>
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

ChatLayout.propTypes = {
  children: PropTypes.node.isRequired,
  onClickClose: PropTypes.func.isRequired,
};

export default ChatLayout;
