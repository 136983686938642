import React from "react";
import PropTypes from "prop-types";
import GameListItem from "./GameListItem";
import gamePropType from "./gamePropType";

const GameList = ({ games }) => {
  return (
    <ul className="grid grid-cols-12 gap-x-15 gap-y-25">
      {games.map((game, index) => {
        if (!game.show) {
          return <></>;
        }

        const isLarge = index < 2;
        const isSmall = index > 4;

        let className = "col-span-12 sm:col-span-6 lg:col-span-4";
        let itemClassName = "";

        if (isLarge) {
          className = "col-span-12 sm:col-span-6";
          itemClassName = "game-preview-lg";
        }

        if (isSmall) {
          className = "col-span-6 sm:col-span-4 md:col-span-3";
          itemClassName = "game-preview--sm";
        }

        return (
          <li className={`${className} order-${index}`} key={game.id}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <GameListItem {...game} className={itemClassName} />
          </li>
        );
      })}
    </ul>
  );
};

GameList.propTypes = {
  games: PropTypes.arrayOf(PropTypes.shape(gamePropType)).isRequired,
};

export default GameList;
