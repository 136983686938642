import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import CountUp from "react-countup";

const AnimateNumber = ({ number, decimals }) => {
  const ref = useRef();
  const [start, setStart] = useState(number);

  useEffect(() => {
    if (Number(number) === 0) {
      setStart(0);
      ref.current.reset();
    }
  }, [number]);

  return (
    <CountUp preserveValue start={start} end={number} ref={ref} decimals={decimals}>
      {({ countUpRef }) => <span ref={countUpRef} />}
    </CountUp>
  );
};

AnimateNumber.propTypes = {
  number: PropTypes.number.isRequired,
  decimals: PropTypes.number,
};

AnimateNumber.defaultProps = {
  decimals: 0,
};

export default AnimateNumber;
