import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import noty from "@/libs/noty";
import PageLoader from "@/components/PageLoader";

const VerifyEmailContainer = () => {
  const { token } = useParams();
  const history = useHistory();
  const { verifyEmail } = useApi();
  const { t } = useTranslation();

  useEffect(() => {
    verifyEmail(token)
      .then(() => {
        noty.success(t("auth.verifyEmail.success"));
      })
      .catch(() => {
        noty.error(t("auth.verifyEmail.error"));
      })
      .finally(() => {
        history.replace("/");
      });
  }, [token]);

  return <PageLoader />;
};

export default VerifyEmailContainer;
