import React, { useRef, useEffect, useState, memo } from "react";
import PropTypes from "prop-types";
import ChatMessage from "@/components/chat/ChatMessage";

const ChatMessages = ({ messages, onSelect, canBan, disabled, onBan, onDelete, userName }) => {
  const scrollRef = useRef();
  const [isScrolling, setIsScrolling] = useState(false);
  const [active, setActive] = useState();

  const scrollToBottom = () => {
    const wrapper = scrollRef.current;
    wrapper.scrollTop = wrapper.scrollHeight - wrapper.clientHeight;
  };

  useEffect(() => {
    if (!isScrolling) {
      scrollToBottom();
    }
  }, [messages]);

  const onScroll = () => {
    const wrapper = scrollRef.current;
    if (!wrapper) {
      return;
    }
    const height = wrapper.scrollHeight - wrapper.clientHeight;

    setIsScrolling(height - wrapper.scrollTop > 50);
  };

  const showMessages = messages
    ? messages.map(message => {
        return {
          ...message,
          isReply: message.content && message.content.includes(userName),
          content: message.content && message.content.replace(userName, ""),
        };
      })
    : messages;

  return (
    <ul
      className="disable-scrollbars rounded overflow-y-auto space-y-6"
      ref={scrollRef}
      onScroll={onScroll}
    >
      {showMessages.map(message => {
        return (
          <ChatMessage
            key={message.id}
            message={message}
            onClickContent={({ id }) => setActive(id)}
            isActive={active === message.id}
            canBan={canBan}
            userName={userName}
            onBan={duration => onBan({ duration, messageId: message.id, userId: message.user.id })}
            disabled={disabled}
            onDelete={() => onDelete(message.id)}
            onClickName={() => onSelect(message)}
          />
        );
      })}
    </ul>
  );
};

ChatMessages.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      user: PropTypes.shape({
        name: PropTypes.string.isRequired,
        role: PropTypes.number.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  canBan: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onBan: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
};

export default memo(ChatMessages, (a, b) => {
  return a.isThrottle === b.isThrottle && a.messages.length > 20;
});
