import React from "react";
import CasinopubGameContainer from "@/containers/games/casinopub/CasinopubGameContainer";
import GameHeaderContainer from "@/containers/games/GameHeaderContainer";
import GameFooterContainer from "@/containers/games/GameFooterContainer";

const CasinopubContainer = () => {
  return (
    <>
      <GameHeaderContainer title="Blackjack" />
      <CasinopubGameContainer />
      <GameFooterContainer />
    </>
  );
};

export default CasinopubContainer;
