import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import noty from "@/libs/noty";
import { USER_ADMIN_ROLE, USER_MODERATOR_ROLE } from "@/constants/userRoles";
import ChatMessages from "@/components/chat/ChatMessages";
import PropTypes from "prop-types";

let timerThrottle;

const ChatMessagesContainer = ({ formRef, setText }) => {
  const { t } = useTranslation();
  const { chatMute, chatDestroyMessage } = useApi();
  const [isThrottle, setIsThrottle] = useState(false);
  const [pending, setPending] = useState(false);
  const messages = useSelector(({ chat }) => chat.messages);
  const user = useSelector(({ auth }) => auth.user);

  const selectMessage = ({ user }) => {
    setText(`@${user.name}, `);
    formRef.current.focus();
  };

  const isStaff = !!user && [USER_ADMIN_ROLE, USER_MODERATOR_ROLE].includes(user.role);

  const mute = data => {
    if (pending) {
      return;
    }
    setPending(true);
    chatMute(data)
      .then(() => {
        noty.success(t("chat.ban.success"));
      })
      .catch(() => {
        noty.error(t("chat.ban.fail"));
      })
      .finally(() => {
        setPending(false);
      });
  };

  const destroy = messageId => {
    setPending(true);
    chatDestroyMessage(messageId)
      .then(() => {
        noty.success(t("chat.destroy.success"));
      })
      .catch(() => {
        noty.error(t("chat.destroy.fail"));
      })
      .finally(() => {
        setPending(false);
      });
  };

  useEffect(() => {
    clearTimeout(timerThrottle);

    timerThrottle = setTimeout(() => {
      setIsThrottle(!isThrottle);
    }, 100);
  }, [messages]);

  return (
    <ChatMessages
      messages={messages}
      userName={user ? `@${user.name},` : ""}
      isThrottle={isThrottle}
      onSelect={selectMessage}
      disabled={pending}
      canBan={isStaff}
      onBan={mute}
      onDelete={destroy}
      canPin={isStaff}
    />
  );
};


ChatMessagesContainer.propTypes = {
  formRef: PropTypes.shape({}).isRequired,
  setText: PropTypes.func.isRequired,
}

export default ChatMessagesContainer;
