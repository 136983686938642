export const PAYMENT_METHOD_CARD = "card";
export const PAYMENT_METHOD_CARD_ENOT = "card-enot";
export const PAYMENT_METHOD_CARD_GAMEMONEY = "card-gamemoney";
export const PAYMENT_METHOD_CARD_QIWI = "card-qiwi";
export const PAYMENT_METHOD_WEBMONEY = "webmoney";
export const PAYMENT_METHOD_WEBMONEY_WMR = "webmoneyWmr";
export const PAYMENT_METHOD_YANDEX = "yandex";
export const PAYMENT_METHOD_YANDEX_ENOT = "yandex-enot";
export const PAYMENT_METHOD_QIWI = "qiwi";
export const PAYMENT_METHOD_PAYPAL = "paypal";
export const PAYMENT_METHOD_ALFA_CLICK = "alfaClick";
export const PAYMENT_METHOD_PROM_SVIAZ_BANK = "promSviazBank";
export const PAYMENT_METHOD_MOBILE = "mobile";
export const PAYMENT_METHOD_APPLE_PAY = "applepay";
export const PAYMENT_METHOD_ADVCASH = "advcash";
export const PAYMENT_METHOD_ALIPAY = "alipay";
export const PAYMENT_METHOD_BEELINE = "beeline";
export const PAYMENT_METHOD_TELE2 = "tele2";
export const PAYMENT_METHOD_MF = "mf";
export const PAYMENT_METHOD_MTS = "mts";
export const PAYMENT_METHOD_GOOGLEPAY = "googlepay";
export const PAYMENT_METHOD_SAMSUNGPAY = "samsungpay";
export const PAYMENT_METHOD_GAMEMONEY = "gamemoney";
export const PAYMENT_METHOD_FREEKASA = "fk";
export const PAYMENT_METHOD_ENOT = "enot";
export const PAYMENT_METHOD_FKWALLET = "fkwallet";
export const PAYMENT_METHOD_BUY_CODE = "buy-code";
export const PAYMENT_METHOD_CLOVER = "clover";
export const PAYMENT_METHOD_CARD_PIASTRIX = "card-piastrix";
export const PAYMENT_METHOD_CARD_EXBASE = "card-exbase";
