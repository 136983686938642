import React from "react";

const SocialLinks = ({ links }) => {
  return (
    <ul className="flex space-x-20">
      {links.map(({ id, link, icon }) => (
        <li key={id}>
          <a href={link} target="_blank" className="btn btn--md btn--white btn--icon">
            {icon}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default SocialLinks;
