import React from "react";
import { useTranslation } from "react-i18next";

const CoinflipRound = ({ round, onClickBack }) => {
  const { id, hash, deposit, player1, player2 } = round;

  const { t } = useTranslation();

  return (
    <>
      <div className="flex items-center justify-between lg:absolute lg:top-0 lg:inset-x-0 py-20 md:py-30 px-10 md:px-30">
        <button className="btn btn--sm btn--black btn--rounded pr-20" onClick={onClickBack}>
          <svg aria-hidden="true" className="fill-current w-8 h-10 mr-12">
            <use href="/svg/svg.svg#back" />
          </svg>
          {t("coinflip.back")}
        </button>
        <div className="text-right">
          <div className="text-gray items-center inline-flex">
            <svg aria-hidden="true" className="fill-current w-16 h-16 mr-20">
              <use href="/svg/svg.svg#info" />
            </svg>
            {t("coinflip.desc.join")}
          </div>
          <div className="text-gray-400 mt-5">$ {deposit}</div>
        </div>
      </div>
      <div className="lg:h-650 py-20 md:py-30 px-10 md:px-30 text-center lg:pt-100">
        <div className="text-4xl sm:text-5xl font-medium">Winner</div>
        <div className="text-2xl sm:text-3xl text-green mt-6 sm:mt-8">Nurgagomedov</div>

        <div className="w-300 mx-auto mt-10 text-left">
          <div className="text-2xl text-white mb-10 h-18" />
          <div className="h-2 bg-primary-100 bg-opacity-10 w-full">
            <div className="h-full relative mx-auto bg-green" style={{ width: "50%" }} />
          </div>
        </div>

        <div className="bg-primary-100 bg-opacity-5 px-20 py-23 rounded flex flex-col sm:flex-row justify-between mt-50 lg:mt-70 relative">
          <div className="flex items-center text-left">
            <div className="logo-circle">
              <svg aria-hidden="true">
                <use href="/svg/svg.svg#logo" />
              </svg>
              <svg aria-hidden="true">
                <use href="/svg/svg.svg#logo" />
              </svg>
            </div>
            <div className="px-20 mr-auto">
              <div className="text-lg mb-5">{player1.name}</div>
              <div className="flex items-center">
                <div className="text-gray-200">50.00%</div>
              </div>
            </div>
          </div>

          <div className="sm:absolute-center flex items-center justify-center my-15 sm:my-0">
            <div className="mr-12 text-center">
              <div className="text-green mb-5">{deposit}</div>
              <div className="currency-symbol bg-green">$</div>
            </div>
            <div className="p-10 md:p-15 bg-black rounded-full">
              <div className="logo-circle w-100 h-100">
                <svg aria-hidden="true">
                  <use href="/svg/svg.svg#logo" />
                </svg>
                <svg aria-hidden="true">
                  <use href="/svg/svg.svg#logo" />
                </svg>
              </div>
            </div>
            <div className="ml-12 text-center">
              <div className="text-green mb-5">{deposit}</div>
              <div className="currency-symbol bg-green">$</div>
            </div>
          </div>

          <div className="flex items-center flex-row-reverse text-right self-end lg:self-center">
            <div className="logo-circle bg-red">
              <svg aria-hidden="true">
                <use href="/svg/svg.svg#logo" />
              </svg>
              <svg aria-hidden="true">
                <use href="/svg/svg.svg#logo" />
              </svg>
            </div>
            <div className="px-20 mr-auto">
              <div className="text-lg mb-5">{player2.name}</div>
              <div className="text-gray-200">50.00%</div>
            </div>
          </div>
        </div>

        <div className="mt-30 mb-50 md:mb-80">
          <div className="flex justify-between px-10">
            <i className="h-4 w-2 bg-primary-100 bg-opacity-40" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-4 w-2 bg-primary-100 bg-opacity-40" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-4 w-2 bg-primary-100 bg-opacity-40" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-13 w-2 bg-primary-100 bg-opacity-40" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-4 w-2 bg-primary-100 bg-opacity-40" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-4 w-2 bg-primary-100 bg-opacity-40" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-4 w-2 bg-primary-100 bg-opacity-40" />
          </div>
        </div>

        <div className="w-230 mb-30 bg-primary-100 bg-opacity-10 h-2 mx-auto" />
        <div className="text-xl font-bold mb-5">COINFLIP #{id}</div>
        <div className="text-gray-600 mb-20">Hash: {hash}</div>
      </div>
    </>
  );
};

export default CoinflipRound;
