import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const DiceControl = ({ onClickPlaceBet, onClickDice, placeBetDisabled, diceDisabled }) => {
  const { t } = useTranslation();

  return (
    <div className="flex space-x-8 mt-15">
      <button
        type="button"
        disabled={placeBetDisabled}
        onClick={onClickPlaceBet}
        className="btn btn--md btn--rounded-l btn--primary w-full justify-between"
      >
        <span>{t("dice.placeBet")}</span>
      </button>
      <button
        type="button"
        disabled={diceDisabled}
        onClick={onClickDice}
        className="btn btn--md btn--rounded-r btn--green w-full justify-between"
      >
        <svg aria-hidden="true" className="fill-current w-15 h-15 -ml-5">
          <use href="/svg/svg.svg#dice-5" />
        </svg>
        <span>{t("dice.roll")}</span>
      </button>
    </div>
  );
};

DiceControl.propTypes = {
  onClickPlaceBet: PropTypes.func.isRequired,
  onClickDice: PropTypes.func.isRequired,
  placeBetDisabled: PropTypes.bool.isRequired,
  diceDisabled: PropTypes.bool.isRequired,
}

export default DiceControl;
