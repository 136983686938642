import PropTypes from "prop-types";

export default {
  id: PropTypes.string.isRequired,
  path: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.string,
  games: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string,
      show: PropTypes.bool,
      path: PropTypes.string.isRequired,
      image: PropTypes.string,
    }),
  ),
  className: PropTypes.string,
};
