import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useApi } from "@/api";
import { setBalance, setState } from "@/actions/referralAction";
import { setOpenReferral } from "@/actions/mainAction";
import ReferralModal from "@/components/referral/ReferralModal";

const ReferralContainer = () => {
  const dispatch = useDispatch();
  const [modalRef, setModalRef] = useState();
  const [pending, setPending] = useState(false);
  const openReferral = useSelector(({ main }) => main.openReferral);
  const state = useSelector(({ referral }) => referral);
  const { referralState, referralWithdraw } = useApi();

  const load = () => {
    if (pending) {
      return;
    }
    setPending(true);
    referralState()
      .then(data => {
        dispatch(setState(data));
      })
      .finally(() => {
        setPending(false);
      });
  };

  useEffect(() => {
    if (modalRef && modalRef.current && openReferral) {
      load();

      modalRef.current.open();
      dispatch(setOpenReferral(false));
    }
  }, [modalRef, openReferral]);

  const withdraw = () => {
    if (pending) {
      return;
    }
    setPending(true);
    referralWithdraw()
      .then(() => {
        dispatch(setBalance(0));
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <ReferralModal onSetRef={setModalRef} state={state} onWithdraw={withdraw} onRefresh={load} />
  );
};

export default ReferralContainer;
