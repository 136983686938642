import {
  SET_DICE_ROOMS,
  SET_DICE_ACTIVE_ROOM,
  SET_DICE_ACTIVE_ROOM_ROUND,
  SET_DICE_ACTIVE_ROOM_ROUND_BET,
  SET_DICE_USER_TO_ACTIVE_ROOM,
  SET_DICE_ROOM_USERS_COUNT,
} from "@/constants";

export const setRooms = rooms => {
  return {
    type: SET_DICE_ROOMS,
    payload: rooms,
  };
};

export const setRoomUsersCount = payload => {
  return {
    type: SET_DICE_ROOM_USERS_COUNT,
    payload,
  };
};

export const setActiveRoom = room => {
  return {
    type: SET_DICE_ACTIVE_ROOM,
    payload: room,
  };
};

export const setRound = round => {
  return {
    type: SET_DICE_ACTIVE_ROOM_ROUND,
    payload: round,
  };
};

export const setBet = bet => {
  return {
    type: SET_DICE_ACTIVE_ROOM_ROUND_BET,
    payload: bet,
  };
};

export const setUser = payload => {
  return {
    type: SET_DICE_USER_TO_ACTIVE_ROOM,
    payload,
  };
};
