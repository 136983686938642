import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import referralCode from "@/helpers/referralCode";
import analytics from "@/helpers/analytics";
import getFingerprint from "@/helpers/getFingerprint";
import noty from "@/libs/noty";
import { setToken } from "@/actions/authAction";
import authToken from "@/helpers/authToken";

const SocialAuthContainer = ({ sign, link }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = authToken.get();

    if (token) {
      link({})
        .then(() => {
          noty.success(t("profile.linkAccounts.linkSuccess"));
          history.replace("/");
        })
        .catch(() => {
          noty.error(t("profile.linkAccounts.linkFail"));
          history.replace("/");
        });
    } else {
      getFingerprint().then(fingerPrint => {
        const payload = {
          fingerPrint,
        };
        const code = referralCode.get();
        if (code) {
          payload.referralCode = code;
        }
        sign(payload)
          .then(({ token, isRegister, userId }) => {
            if (isRegister) {
              analytics.successRegister(userId);
            }
            authToken.set(token);
            dispatch(setToken(token));
            referralCode.remove();
            history.replace("/");
          })
          .catch(() => {
            noty.error(t("login.error"));
            history.replace("/");
          });
      });
    }
  });

  return <></>;
};

SocialAuthContainer.propTypes = {
  sign: PropTypes.func.isRequired,
  link: PropTypes.func.isRequired,
};

export default SocialAuthContainer;
