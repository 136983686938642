import React from "react";
import { useSelector } from "react-redux";
import TowerBets from "@/components/games/towers/TowerBets";

const TowerBetsContainer = () => {
  const bets = useSelector(({ towers }) => towers.bets);

  return <TowerBets bets={bets} />;
};

export default TowerBetsContainer;
