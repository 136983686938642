import React from "react";
import PropTypes from "prop-types";

const Online = ({ count }) => {

  return (
    <div className="mb-15 sm:mb-0 hidden sm:block">
      <div className="text-green flex justify-end items-center">
        <div className="grid place-items-center w-14 h-14 mr-10 relative">
          <i className="rounded-circle h-full w-full bg-green bg-opacity-15 animate-pulse" />
          <i className="block rounded-circle w-4 h-4 bg-green absolute-center" />
        </div>
        {count.toLocaleString("en-US")}
      </div>
    </div>
  );
};

Online.propTypes = {
  count: PropTypes.number.isRequired,
};

export default Online;
