import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useApi } from "@/api";
import { useTranslation } from "react-i18next";
import { setRounds, setBets } from "@/actions/coinflipAction";
import GameHeaderContainer from "@/containers/games/GameHeaderContainer";
import GameFooterContainer from "@/containers/games/GameFooterContainer";
import CoinflipSidebarContainer from "@/containers/games/coinflip/CoinflipSidebarContainer";
import CoinflipSubscriberContainer from "@/containers/games/coinflip/CoinflipSubscriberContainer";
import CoinflipListContainer from "@/containers/games/coinflip/CoinflipListContainer";
import CoinflipRoundContainer from "@/containers/games/coinflip/CoinflipRoundContainer";

const CoinflipContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeRound = useSelector(({ coinflip }) => coinflip.activeRound);
  const { coinflipState } = useApi();

  useEffect(() => {
    coinflipState().then(({ rounds, bets }) => {
      dispatch(setRounds(rounds));
      dispatch(setBets(bets));
    });
  });

  return (
    <>
      <GameHeaderContainer title={t("coinflip.title")} />
      <div className="game-wrapper game-wrapper--has-sidebar">
        <div className="game-wrapper__main">
          {activeRound ? <CoinflipRoundContainer /> : <CoinflipListContainer />}
        </div>
        <div className="game-wrapper__sidebar">
          <CoinflipSidebarContainer />
        </div>
      </div>
      <GameFooterContainer />
      <CoinflipSubscriberContainer />
    </>
  );
};

export default CoinflipContainer;
