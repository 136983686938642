import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const TabModalLink = ({ link, isActive, setActive, prefix, }) => {
  const { t } = useTranslation();
  const { id, icon } = link;
  return (
    <li role="presentation">
      <button
        type="button"
        className={`sidebar-menu__btn${isActive ? " active" : ""}`}
        onClick={() => setActive(link)}
      >
        {icon}
        <span>{t(`${prefix}.links.${id}`)}</span>
      </button>
    </li>
  );
};

TabModalLink.propTypes = {
  link: PropTypes.shape({
    id: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    component: PropTypes.node.isRequired,
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
};

export default TabModalLink;
