import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import noty from "@/libs/noty";
import { setUserClientSeed } from "@/actions/authAction";
import GameFairModal from "@/components/games/GameFairModal";

const GameFairModalContainer = ({ nonce, serverSeed, changeServerSeed }) => {
  const { t } = useTranslation();
  const user = useSelector(({ auth }) => auth.user);
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);

  const { updateClientSeed } = useApi();

  if (!user) {
    return <></>;
  }

  const changeClientSeed = newClientSeed => {
    if (pending) {
      return;
    }

    setPending(true);

    updateClientSeed(newClientSeed)
      .then(() => {
        dispatch(setUserClientSeed(newClientSeed));
        noty.success(t("fair.modal.changeClientSeedSuccess"));
      })
      .catch(() => {
        noty.error(t("fair.modal.changeClientSeedFail"));
      })
      .finally(() => setPending(false));
  };

  return (
    <GameFairModal
      pending={pending}
      nonce={nonce}
      serverSeed={serverSeed}
      clientSeed={user.clientSeed}
      changeServerSeed={changeServerSeed}
      changeClientSeed={changeClientSeed}
    />
  );
};

GameFairModalContainer.propTypes = {
  serverSeed: PropTypes.string.isRequired,
  nonce: PropTypes.number.isRequired,
  changeServerSeed: PropTypes.func.isRequired,
};

export default GameFairModalContainer;
