import React from "react";
import ProfileChangeEmailContainer from "@/containers/profile/ProfileChangeEmailContainer";
import ProfileChangePasswordContainer from "@/containers/profile/ProfileChangePasswordContainer";
// import ProfileUpdateBooleanFieldContainer from "@/containers/profile/ProfileUpdateBooleanFieldContainer";
// import ProfileTwoFactorCheckbox from "@/components/profile/ProfileTwoFactorCheckbox";

const ProfileSecurityContainer = () => {
  return (
    <>
      <ProfileChangeEmailContainer />
      <ProfileChangePasswordContainer />
      {/*<ProfileUpdateBooleanFieldContainer>*/}
      {/*  {({ onChange }) => <ProfileTwoFactorCheckbox onChange={onChange} />}*/}
      {/*</ProfileUpdateBooleanFieldContainer>*/}
    </>
  );
};

export default ProfileSecurityContainer;
