import React from "react";
import GameSidebar from "@/components/games/GameSidebar";
import TowersControlContainer from "@/containers/games/towers/TowersControlContainer";
import TowerBetsContainer from "@/containers/games/towers/TowerBetsContainer";

const TowersSidebarContainer = () => {
  return (
    <GameSidebar>
      <TowersControlContainer />
      <TowerBetsContainer />
    </GameSidebar>
  );
};

export default TowersSidebarContainer;
