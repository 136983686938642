import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import {
  setAutoCoefficientSettings,
  setAutoCoefficient,
  setBet,
  newBet,
} from "@/actions/crashAction";
import { setOpenAuth } from "@/actions/mainAction";
import noty from "@/libs/noty";
import useSound from "@/helpers/useSound";
import { BET_WAITING_STATUS, BET_WITHDRAW_STATUS } from "@/constants/crashBetStatuses";
import { CRASH_WAITING_STATUS, CRASH_RUNNING_STATUS } from "@/constants/crashStatuses";
import CrashControl from "@/components/games/crash/CrashControl";
import CrashUpgradeContainer from "@/containers/games/crash/CrashUpgradeContainer";

const CrashControlContainer = () => {
  const { t } = useTranslation();
  const sound = useSound();
  const dispatch = useDispatch();
  const autoCoefficientSettings = useSelector(({ crash }) => crash.autoCoefficientSettings);
  const autoCoefficient = useSelector(({ crash }) => crash.autoCoefficient);
  const bet = useSelector(({ crash }) => crash.bet);
  const status = useSelector(({ crash }) => crash.status);
  const user = useSelector(({ auth }) => auth.user);

  const { crashMake } = useApi();

  const create = ({ deposit, autoCoefficient }) => {
    if (!user) {
      dispatch(setOpenAuth(true));
      return;
    }
    sound.bet();
    if (Number(deposit) === 0) {
      const demoBet = {
        id: 0,
        status: BET_WAITING_STATUS,
        coefficientAuto: autoCoefficient,
        deposit: Number(deposit),
        user: {
          id: user.id,
          name: user.name,
        },
      };

      dispatch(setBet(demoBet));
      dispatch(newBet(demoBet));
      return;
    }

    crashMake({ deposit, autoCoefficient })
      .then(() => {
        noty.success(t("crash.create.success"));
      })
      .catch(() => {
        noty.error(t("crash.create.error"));
      });
  };

  return (
    <CrashControl
      autoCoefficientSettings={autoCoefficientSettings}
      onChangeAutoCoefficientSettings={newAutoCoefficientSettings =>
        dispatch(setAutoCoefficientSettings(newAutoCoefficientSettings))
      }
      defaultAutoCoefficient={autoCoefficient}
      onChangeAutoCoefficient={newAutoCoefficient => setAutoCoefficient(newAutoCoefficient)}
      onCreate={create}
      disabledInput={!!bet}
      disabled={status !== CRASH_WAITING_STATUS}
      isUpgrade={!!bet && bet.status !== BET_WITHDRAW_STATUS && status === CRASH_RUNNING_STATUS}
      upgrade={<CrashUpgradeContainer />}
      isStarting={!!bet && status === CRASH_WAITING_STATUS}
    />
  );
};

export default CrashControlContainer;
