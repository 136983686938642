import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSubscribe } from "@/libs/centrifuge";
import useSound from "@/helpers/useSound";
import { newNotification } from "@/actions/authAction";

const NotificationsSubscriberContainer = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const userId = user ? user.id : null;
  const subscribe = useSubscribe();
  const sound = useSound();

  useEffect(() => {
    let unsubscribe;

    if (userId) {
      unsubscribe = subscribe(`u-noty#${userId}`, notification => {
        dispatch(newNotification(notification));
        sound.notification();
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [userId]);

  return <></>;
};

export default NotificationsSubscriberContainer;
