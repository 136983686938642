import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setRooms, setActiveRoom } from "@/actions/diceAction";
import { useApi } from "@/api";

const DiceCurrentStateContainer = () => {
  const dispatch = useDispatch();
  const { diceState } = useApi();

  useEffect(() => {
    diceState()
      .then(({ rooms }) => {
        dispatch(setRooms(rooms));
        dispatch(setActiveRoom(rooms[0]));
      })
  });


  return <></>;
};

export default DiceCurrentStateContainer;
