import React from "react";
import PropTypes from 'prop-types';

const Tooltip = ({ title, content }) => {

  return (
    <div className="flex space-x-20 items-center">
      <div className="text-gray items-center flex">
        <svg aria-hidden="true" className="fill-current w-16 h-16 mr-12">
          <use href="/svg/svg.svg#info" />
        </svg>
        {title}
      </div>
      <div className="btn btn--black btn--nano pointer-events-none">{content}</div>
    </div>
  );
};

Tooltip.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

export default Tooltip;