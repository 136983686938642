import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setBet, updateBet } from "@/actions/crashAction";
import { BET_WITHDRAW_STATUS } from "@/constants/crashBetStatuses";
import useSound from "@/helpers/useSound";
import { useApi } from "@/api";
import noty from "@/libs/noty";
import eventEmitter from "@/components/games/crash/eventEmitter";

const CrashUpgradeContainer = () => {
  const { t } = useTranslation();
  const sound = useSound();
  const dispatch = useDispatch();
  const bet = useSelector(({ crash }) => crash.bet);
  const [coefficient, setCoefficient] = useState(0);
  const { crashUpgrade } = useApi();

  const upgrade = () => {
    sound.bet();
    if (bet.id === 0) {
      dispatch(
        updateBet({
          id: 0,
          coefficient: window.coefficient,
          status: BET_WITHDRAW_STATUS,
          withdraw: 0,
        }),
      );
      dispatch(setBet(null));
      return;
    }
    crashUpgrade({ coefficient })
      .then(() => {
        noty.success(t("crash.upgrade.success"));
      })
      .catch(() => {
        noty.error(t("crash.upgrade.error"));
      });
  };

  useEffect(() => {
    const setNewCef = newCef => setCoefficient(newCef);
    eventEmitter.subscribeCoefficient(setNewCef);
    return () => eventEmitter.unSubscribeCoefficient(setNewCef);
  }, [setCoefficient]);

  return (
    <button
      type="button"
      className="btn btn--md btn--rounded w-full justify-between mt-22 btn--green"
      onClick={() => upgrade()}
    >
      <span>{t("crash.upgrade.button")} </span>
      <span>$ {Number(bet.deposit * coefficient).toFixed(2)}</span>
    </button>
  );
};

export default CrashUpgradeContainer;
