import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "@/components/Modal";
import TabModalLink from "./TabModalLink";

const TabModal = ({ onSetRef, links, prefix, title, children }) => {
  const modalRef = useRef();

  const [activeLink, setActiveLink] = useState(links[0]);

  useEffect(() => {
    onSetRef(modalRef);
  }, [modalRef, activeLink]);

  return (
    <Modal
      ref={modalRef}
      contentClassName="account-form w-800"
      dialogClassName="modal-dialog-centered"
      showCloseButton
    >
      <div className="account-form__inner">
        <div className="account-sidebar flex flex-col items-stretch justify-between pt-20 sm:py-20">
          <div className="account-modal-title px-20 sm:px-30">{title}</div>
          <div className="sidebar-menu mb-auto">
            <ul role="tablist" className="nav">
              {links.map(link => (
                <TabModalLink
                  key={link.id}
                  link={link}
                  isActive={activeLink.id === link.id}
                  setActive={setActiveLink}
                  prefix={prefix}
                />
              ))}
            </ul>
          </div>
          {children}
        </div>

        <div className="account-form__main">{activeLink.component}</div>
      </div>
    </Modal>
  );
};

TabModal.propTypes = {
  onSetRef: PropTypes.func.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
      component: PropTypes.node.isRequired,
    }),
  ).isRequired,
  prefix: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

TabModal.defaultProps = {
  children: null,
};

export default TabModal;
