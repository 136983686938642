import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CoinflipCreateForm = ({ onSubmit }) => {
  const { t } = useTranslation();

  const [amount, setAmount] = useState(0.25);

  return (
    <div>
      <div className="text-lg">{t("coinflip.betAmount")}</div>
      <label className="block relative" htmlFor="coinflip-amount">
        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 -mt-1 text-gray-600">
          $
        </span>
        <input
          type="number"
          id="coinflip-amount"
          className="text-field pl-20"
          placeholder="0.00"
          value={amount}
          onBlur={e => setAmount(e.target.value)}
          onChange={e => setAmount(e.target.value)}
        />
      </label>

      <button
        type="button"
        className="btn btn--md btn--rounded btn--primary w-full justify-between mt-25"
        onClick={() => onSubmit(amount)}
      >
        <span>{t("coinflip.create")}</span>
        <span>$ {amount}</span>
      </button>
    </div>
  );
};

CoinflipCreateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CoinflipCreateForm;
