import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { setSoundsOn } from "@/actions/mainAction";
import GameHeader from "@/components/games/GameHeader";

const GameHeaderContainer = ({ title, children }) => {
  const dispatch = useDispatch();
  const soundsOn = useSelector(({ main }) => main.soundsOn);
  return (
    <GameHeader
      title={title}
      isSoundOn={soundsOn}
      onClickSound={changedSoundsOn => dispatch(setSoundsOn(changedSoundsOn))}
    >
      {children}
    </GameHeader>
  );
};

GameHeaderContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

GameHeaderContainer.defaultProps = {
  children: null,
};

export default GameHeaderContainer;
