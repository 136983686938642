import React from "react";
import { useSelector } from "react-redux";
import CrashBets from "@/components/games/crash/CrashBets";

const CrashBetsContainer = () => {
  const bets = useSelector(({ crash }) => crash.bets);

  return <CrashBets bets={bets} />;
};

export default CrashBetsContainer;
