import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setOpen } from "@/actions/chatAction";
import ChatLayout from "@/components/chat/ChatLayout";
import ChatMessagesContainer from "@/containers/chat/ChatMessagesContainer";
import ChatFormContainer from "@/containers/chat/ChatFormContainer";

const ChatMainContainer = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const [text, setText] = useState("");

  return (
    <ChatLayout onClickClose={() => dispatch(setOpen(false))}>
      <ChatMessagesContainer formRef={ref} setText={setText} />
      <ChatFormContainer formRef={ref} text={text} setText={setText} />
    </ChatLayout>
  );
};

export default ChatMainContainer;
