import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useApi } from "@/api";
import { setRound, setBets } from "@/actions/towersAction";

const TowersCurrentStateContainer = () => {
  const { towersState } = useApi();
  const dispatch = useDispatch();

  useEffect(() => {
    towersState().then(({ round, bets }) => {
      dispatch(setRound(round || {}));
      dispatch(setBets(bets));
    });
  });

  return <></>;
};

export default TowersCurrentStateContainer;
