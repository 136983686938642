import { SET_REFERRAL_STATE, SET_REFERRAL_BALANCE } from "@/constants";

const initialState = {
  balance: 0,
  levels: {},
  referrals: [],
  earns: [],
  earnsTotal: 0,
  code: "",
  total: 0,
  level: 1,
};

const referral = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_REFERRAL_STATE: {
      return action.payload;
    }
    case SET_REFERRAL_BALANCE: {
      return {
        ...state,
        balance: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default referral;
