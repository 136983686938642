import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import referralCode from "@/helpers/referralCode";

const ReferralCodeContainer = () => {
  const history = useHistory();
  const { code } = useParams();

  useEffect(() => {
    referralCode.set(code);
    const urlParams = new URLSearchParams(window.location.search).toString();
    history.replace(urlParams ? `/?${urlParams}` : "/");
  }, [code]);

  return <></>;
};

export default ReferralCodeContainer;
