import {
  SET_CURRENCY,
  SET_CENTRIFUGE_TOKEN,
  SET_IS_MOBILE,
  SET_SOUNDS_ON,
  SET_TOTAL_WAGER,
  SET_FULL_SCREEN,
  SET_OPEN_CASHIER,
  SET_PAYMENT_METHODS,
  SET_OPEN_REFERRAL,
  SET_OPEN_AUTH,
} from "@/constants";

const initialState = {
  currency: 0,
  isMobile: false,
  soundsOn: true,
  centrifugeToken: null,
  totalWager: 1636658,
  fullScreen: false,
  openCashier: false,
  paymentMethods: [],
  openReferral: false,
  openAuth: false,
};

const main = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_CURRENCY:
      return {
        ...state,
        currency: action.payload,
      };

    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      };

    case SET_SOUNDS_ON:
      return {
        ...state,
        soundsOn: action.payload,
      };

    case SET_CENTRIFUGE_TOKEN:
      return {
        ...state,
        centrifugeToken: action.payload,
      };
    case SET_TOTAL_WAGER:
      return {
        ...state,
        totalWager: action.payload,
      };
    case SET_FULL_SCREEN:
      return {
        ...state,
        fullScreen: action.payload,
      };
    case SET_OPEN_CASHIER:
      return {
        ...state,
        openCashier: action.payload,
      };
    case SET_OPEN_REFERRAL:
      return {
        ...state,
        openReferral: action.payload,
      };
    case SET_OPEN_AUTH:
      return {
        ...state,
        openAuth: action.payload,
      };
    case SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payload,
      };
    default:
      return state;
  }
};

export default main;
