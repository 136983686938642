import Noty from "noty";

const config = {
  timeout: 5000,
  type: "alert",
};

const create = (message, type, timeout) => {
  return new Noty({
    type: type || config.type,
    text: message || "",
    timeout: timeout || config.timeout,
    theme: "nest",
  }).show();
};

const noty = {
  info(message, timeout) {
    return create(message, "info", timeout);
  },

  success(message, timeout) {
    return create(message, "success", timeout);
  },

  warning(message, timeout) {
    return create(message, "warning", timeout);
  },

  error(message, timeout) {
    return create(message, "error", timeout);
  },
};

export default noty;
