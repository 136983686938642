import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  ROULETTE_BETTING_STATUS,
  ROULETTE_FINISH_STATUS,
  ROULETTE_ROLLING_STATUS,
} from "@/constants/rouletteStatuses";
import RouletteGameboardCountdown from "@/components/Countdown";
import RouletteGameboardMaxProfit from "./RouletteGameboardMaxProfit";
import RouletteGameboardTopScale from "./RouletteGameboardTopScale";
import RouletteGameboardStats from "./RouletteGameboardStats";

const RouletteGameboard = ({ round, playersCount }) => {
  const { status, randomNumber, bettingTime, endTime } = round;
  const ref = useRef();
  const { t } = useTranslation();
  const numbers = [1, 14, 2, 13, 3, 12, 4, 11, 5, 10, 6, 9, 7, 8, 0];

  let tails = [];

  new Array(12).fill(null).forEach(() => {
    tails = [...tails, ...numbers];
  });

  tails = tails.map((number, id) => ({ number, id }));
  const getNumberIndex = findNumber => {
    return numbers.findIndex(number => findNumber === number);
  };

  const spin = ({ number, addIndex, time, hasAnimation = false }) => {
    const slideToIndex = getNumberIndex(number);

    const container = ref.current;
    const items = container.querySelectorAll("li");

    if (hasAnimation) {
      items.forEach(element => element.classList.remove("active"));
    }

    if (slideToIndex !== null) {
      const indexElement = slideToIndex + addIndex;

      const getRightByIndex = index => {
        const distance = items[index].offsetLeft;
        const elementWidth = items[index].clientWidth
        const containerOffset = container.clientWidth / 2;
        return distance - containerOffset + (elementWidth / 2);
      };

      const right = getRightByIndex(indexElement);

      ref.current.style.transform = `translate3d(-${right}px, 0, 0)`;
      ref.current.style.transitionTimingFunction = "cubic-bezier(0.12, 0.8, 0.38, 1)";
      ref.current.style.transitionDuration = `${time}ms`;
    }
  };

  const roll = (number, time) => {
    const addIndex = numbers.length * 6;
    spin({ number, addIndex, time, hasAnimation: true });
    setTimeout(() => {
      const container = ref.current;
      const items = container.querySelectorAll("li");
      const animationEndIndex = getNumberIndex(number) + addIndex;
      items[animationEndIndex].classList.add("active");
    }, time);
  };

  const stablePosition = number => {
    const addIndex = numbers.length;

    const container = ref.current;
    const items = container.querySelectorAll("li");
    const stableIndex = getNumberIndex(number) + addIndex;
    items[stableIndex].classList.add("active");

    setTimeout(() => {
      spin({ number, addIndex, time: 0 });
    }, 200);
  };

  useEffect(() => {
    switch (status) {
      case ROULETTE_ROLLING_STATUS: {
        roll(randomNumber, endTime);
        break;
      }
      case ROULETTE_FINISH_STATUS: {
        stablePosition(randomNumber);
        break;
      }
      default:
        break;
    }
  }, [ref, status, randomNumber, endTime]);


  return (
    <div className="game-wrapper__main">
      <div className="sm:h-600 relative px-15 py-25 sm:p-25 flex flex-col justify-center text-center">
        <RouletteGameboardMaxProfit />

        <div className="text-4xl sm:text-5xl font-medium">{t(`roulette.status.${status}`)}</div>
        {status === ROULETTE_BETTING_STATUS && <RouletteGameboardCountdown date={bettingTime} />}
        <div className="mt-auto mb-20">
          <RouletteGameboardTopScale />
          <div className="roulette-game finish" id="roulette-game">
            <div className="roulette-game-slider">
              <svg aria-hidden="true" className="roulette-game-slider__arrow">
                <use xlinkHref="svg/svg.svg#triangle-down" />
              </svg>
              <div className="roulette-game-slider__inner">
                <ul ref={ref} style={{ transform: "translateX(0px)" }}>
                  {tails.map(({ id, number }) => {
                    let image = "silver";

                    if (number <= 7) {
                      image = "bronze";
                    }

                    if (number === 0) {
                      image = "gold";
                    }

                    return (
                      <li key={id}>
                        <img alt={number} src={`/img/${image}.png`} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-30 mb-15">
            <div className="flex justify-around px-10">
              <i className="h-8 w-2 bg-primary-100 bg-opacity-40" />
              <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
              <i className="h-8 w-2 bg-primary-100 bg-opacity-40" />
              <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
              <i className="h-8 w-2 bg-primary-100 bg-opacity-40" />
            </div>
          </div>
        </div>
        <RouletteGameboardStats playersCount={playersCount} />
      </div>
    </div>
  );
};

RouletteGameboard.propTypes = {
  round: PropTypes.shape({
    status: PropTypes.number.isRequired,
    randomNumber: PropTypes.number.isRequired,
    endTime: PropTypes.number.isRequired,
    bettingTime: PropTypes.number.isRequired,
    endAt: PropTypes.string.isRequired,
  }).isRequired,
  playersCount: PropTypes.number.isRequired,
};

export default RouletteGameboard;
