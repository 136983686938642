import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActiveRound } from "@/actions/coinflipAction";
import CoinflipRound from "@/components/games/coinflip/CoinflipRound";

const CoinflipRoundContainer = () => {
  const activeRound = useSelector(({ coinflip }) => coinflip.activeRound);
  const dispatch = useDispatch();

  return <CoinflipRound round={activeRound} onClickBack={() => dispatch(setActiveRound(null))} />;
};

export default CoinflipRoundContainer;
