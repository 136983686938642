import {
  SET_ROULETTE_BETS,
  SET_ROULETTE_ROUND,
  NEW_ROULETTE_BET,
  SET_ROULETTE_HISTORY,
  NEW_ROULETTE_HISTORY,
  FINISH_ROULETTE_BETS,
} from "@/constants";

export const setRound = round => {
  return {
    type: SET_ROULETTE_ROUND,
    payload: round,
  };
};

export const setBets = bets => {
  return {
    type: SET_ROULETTE_BETS,
    payload: bets,
  };
};

export const newBet = bet => {
  return {
    type: NEW_ROULETTE_BET,
    payload: bet,
  };
};

export const setHistory = history => {
  return {
    type: SET_ROULETTE_HISTORY,
    payload: history,
  };
};

export const newHistory = history => {
  return {
    type: NEW_ROULETTE_HISTORY,
    payload: history,
  };
};

export const finishBets = round => {
  return {
    type: FINISH_ROULETTE_BETS,
    payload: round,
  };
};
