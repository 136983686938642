import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setRounds } from "@/actions/coinflipAction";
import { useApi } from "@/api";
import CoinflipListRoundContainer from "@/containers/games/coinflip/CoinflipListRoundContainer";

let timer;

const pinnedPositions = new Array(10).fill(null).map((v, position) => 100 + position);

const CoupleGamesRoundsContainer = () => {
  const dispatch = useDispatch();

  const [positions, setPositions] = useState([]);

  const { coinflipState } = useApi();

  useEffect(() => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      coinflipState().then(result => {
        const newPositions = new Array(30).fill(null).map((v, position) => position);
        const rounds = new Array(130).fill(null).map((v, position) => {
          return result.rounds[position] || {};
        });

        setPositions(newPositions);

        dispatch(setRounds(rounds));
      });
    }, 300);
  }, []);

  return (
    <div className="lg:h-620 py-20 md:py-30 px-10 md:px-30 text-center">
      <ul className="space-y-10 overflow-y-auto max-h-300 lg:max-h-full disable-scrollbars h-full rounded">
        {pinnedPositions.map(position => (
          <CoinflipListRoundContainer key={position} position={position} isEmpty />
        ))}
        {positions.map(position => (
          <CoinflipListRoundContainer key={position} position={position} />
        ))}
      </ul>
    </div>
  );
};

export default CoupleGamesRoundsContainer;
