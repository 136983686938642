import {
  DICE_ROUND_WAITING_STATUS,
  DICE_ROUND_BETTING_STATUS,
  DICE_ROUND_ROLLING_STATUS,
} from "@/constants/diceRoundStatuses";

export default {
  title: "Dice",
  winner: "Winner",
  totalBank: "Total Bank",
  deckDesc: "The Winner Will Take Everything",
  rooms: "Bet Rooms",
  choseRoom: "Choose a room to play in",
  betNotCanceled: "The bet cannot be canceled",
  status: {
    [DICE_ROUND_WAITING_STATUS]: {
      title: "Acceptance of bets",
      desc: "The round will start after two bets",
    },
    [DICE_ROUND_BETTING_STATUS]: {
      title: "Acceptance of bets",
      desc: "Will end in",
    },
    [DICE_ROUND_ROLLING_STATUS]: {
      title: "It's time to roll the dice",
      desc: "Will end in",
    },
  },
  place: {
    waiting: "Waiting",
    free: "Free place",
  },
  room: "Room",
  placeBet: "Place Bet",
  roll: "Dice",
  joinError: "Failed to join, you need to log out from another table",
  leaveError: "Couldn't get up from the table",
  betError: "Failed to place a bet",
  rollError: "Couldn't roll the dice",
};
