import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { USER_ADMIN_ROLE, USER_MODERATOR_ROLE } from "@/constants/userRoles";
import { useApi } from "@/api";
import noty from "@/libs/noty";
import toLocaleDate from "@/helpers/toLocaleDate";
import ChatForm from "@/components/chat/ChatForm";

const ChatFormContainer = ({ formRef, text, setText }) => {
  const { t } = useTranslation();
  const { chatStoreMessage } = useApi();

  const user = useSelector(({ auth }) => auth.user);

  const [pending, setPending] = useState(false);

  const showSendError = (key, payload = {}) => {
    noty.error(t(`chat.errors.send.${key}`, payload));
  };

  const isStaff = !!user && [USER_ADMIN_ROLE, USER_MODERATOR_ROLE].includes(user.role);

  const submit = newText => {
    setPending(true);
    setText("");

    chatStoreMessage({ text: newText || text })
      .then(result => {
        if (typeof result === "object" && result.failed) {
          if (result.ban) {
            showSendError("ban");
            return;
          }
          if (result.mutedAt) {
            showSendError("mute", {
              mutedAt: toLocaleDate(result.mutedAt),
            });
            return;
          }
          if (result.deposit) {
            showSendError("depositDenied", result);
          }
          if (result.slowMode) {
            showSendError("slowMode");
          }

          if (result.stickerMode) {
            showSendError("stickerMode");
          }
        }
      })
      .catch(error => {
        let message = "fail";

        if (error.response) {
          if (error.response.status === 403) {
            message = error.response.data.error;
          }
          if (error.response.status === 422) {
            message = "notValid";
          }
          if (error.response.status === 429) {
            message = "toMany";
          }
        }

        showSendError(message);
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <ChatForm
      disabled={pending || !user}
      formRef={formRef}
      value={text}
      onChange={v => setText(v)}
      noneLimit={isStaff}
      onSubmit={submit}
    />
  );
};

ChatFormContainer.propTypes = {
  formRef: PropTypes.shape({}).isRequired,
  setText: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default ChatFormContainer;
