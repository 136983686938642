import React from "react";
import PropTypes from "prop-types";
import MinesRoundTile from "./MinesRoundTile";

const MinesRound = ({ cells, onSelect, disabled }) => {
  return (
    <div className="game-wrapper__main">
      <div className="mines-game">
        <ul className="mines-game__list">
          {cells.map(({ id, isActive, isBomb, isAutoOpen, inStep }) => (
            <li key={id} className={`mines-game__item ${isAutoOpen ? "opacity-50" : ""}`}>
              <MinesRoundTile
                onClick={() => {
                  if (!inStep && !disabled) {
                    onSelect(id);
                  }
                }}
                isBomb={isBomb}
                open={isActive || isBomb}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

MinesRound.propTypes = {
  cells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired,
      isBomb: PropTypes.bool.isRequired,
      isAutoOpen: PropTypes.bool.isRequired,
      inStep: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default MinesRound;
