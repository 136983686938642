import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  CHAT_MESSAGE_TYPE_AUDIO,
  CHAT_MESSAGE_TYPE_IMAGE,
  CHAT_MESSAGE_TYPE_TIP,
} from "@/constants/chatMessageTypes";
import toLocaleDate from "@/helpers/toLocaleDate";
import {
  USER_CREATOR_ROLE,
  USER_ADMIN_ROLE,
  USER_MODERATOR_ROLE,
  USER_PARTNER_ROLE,
  USER_PARTNER_TWITCH_ROLE,
} from "@/constants/userRoles";
import ChatMessageControl from "@/components/chat/ChatMessageControl";

const roleColors = {
  [USER_CREATOR_ROLE]: "#ff00fc",
  [USER_ADMIN_ROLE]: "#f6af54",
  [USER_PARTNER_ROLE]: "#ff4747",
  [USER_PARTNER_TWITCH_ROLE]: "#885aff",
  [USER_MODERATOR_ROLE]: "#7bdb92",
};

const ChatMessage = ({
  message,
  userName,
  onClickName,
  onClickContent,
  canBan,
  onBan,
  onDelete,
  disabled,
  isActive,
}) => {
  const { id, isReply, createdAt, user } = message;

  const { t } = useTranslation();

  const getContent = ({ type, content }) => {
    switch (type) {
      case CHAT_MESSAGE_TYPE_IMAGE: {
        return (
          <div className="chat-item-sticker">
            <img src={content} alt="" />
          </div>
        );
      }
      case CHAT_MESSAGE_TYPE_AUDIO: {
        return (
          <audio controls style={{ width: "100%" }}>
            <source src={content} type="audio/mpeg" />
          </audio>
        );
      }
      case CHAT_MESSAGE_TYPE_TIP: {
        return t("chat.tip", message.payload);
      }
      default:
        return content;
    }
  };

  return (
    <li
      key={id}
      className={`chat-item ${roleColors[user.role] ? "chat-item--special" : ""}`}
      style={roleColors[user.role] ? { "--gradient-color": roleColors[user.role] } : {}}
    >
      <div className="chat-item__header">
        <span
          className="chat-item__name"
          onClick={() => onClickName(message)}
          style={roleColors[user.role] ? { color: roleColors[user.role] } : {}}
        >
          {user.name}
          {roleColors[user.role] && (
            <svg aria-hidden="true" className="fill-current w-12 h-10 ml-5 -mt-1">
              <use href="/svg/svg.svg#basic-tick" />
            </svg>
          )}
        </span>
        <span className="chat-item__date">{toLocaleDate(createdAt, "HH:mm", "HH:mm")}</span>
      </div>
      <div className="chat-item__message" onClick={() => onClickContent(message)}>
        {isReply && <span style={{ color: "#3ed387" }}>{userName}</span>}
        {getContent(message)}
      </div>

      {canBan && (
        <ChatMessageControl
          canBan={canBan}
          onDelete={onDelete}
          onSelect={onBan}
          disabled={disabled}
          isActive={isActive}
        />
      )}
    </li>
  );
};

ChatMessage.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    isReply: PropTypes.bool,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
      steamId: PropTypes.string,
      role: PropTypes.number.isRequired,
    }).isRequired,
    type: PropTypes.number.isRequired,
  }).isRequired,
  userName: PropTypes.string,
  canBan: PropTypes.bool,
  onBan: PropTypes.func,
  onClickContent: PropTypes.func,
  onClickName: PropTypes.func,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
};

ChatMessage.defaultProps = {
  userName: null,
  canBan: false,
  onBan: () => {},
  onClickContent: () => {},
  onClickName: () => {},
  onDelete: () => {},
  disabled: false,
  isActive: false,
};

export default ChatMessage;
