import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setRound, setLine, setRoundDemo } from "@/actions/minesAction";
import { useApi } from "@/api";
import GameHeaderContainer from "@/containers/games/GameHeaderContainer";
import GameFooterContainer from "@/containers/games/GameFooterContainer";
import MinesSidebarContainer from "@/containers/games/mines/MinesSidebarContainer";
import MinesRoundContainer from "@/containers/games/mines/MinesRoundContainer";
import MinesSubscriberContainer from "@/containers/games/mines/MinesSubscriberContainer";
import MinesFairModalContainer from "@/containers/games/mines/MinesFairModalContainer";

const MinesMainContainer = () => {
  const dispatch = useDispatch();
  const { minesState } = useApi();
  const { t } = useTranslation();
  const isMobile = useSelector(({ main }) => main.isMobile);

  useEffect(() => {
    minesState().then(({ line, round }) => {
      if (round) {
        dispatch(setRoundDemo(null));
        dispatch(setRound(round));
      }
      dispatch(setLine(line));
    });
  });

  return (
    <>
      <GameHeaderContainer title={t("mines.title")}>
        <MinesFairModalContainer />
      </GameHeaderContainer>
      <div className="game-wrapper game-wrapper--has-sidebar">
        {isMobile ? <MinesRoundContainer /> : <MinesSidebarContainer />}
        {isMobile ? <MinesSidebarContainer /> : <MinesRoundContainer />}
      </div>
      <GameFooterContainer />
      <MinesSubscriberContainer />
    </>
  );
};

export default MinesMainContainer;
