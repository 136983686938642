import React from "react";
import PropTypes from "prop-types";

const ChatToogler = ({ onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="btn btn--rounded btn--fab btn--primary chat-toggler"
      style={{ "--size": "4.3rem" }}
      aria-label="Chat"
    >
      <svg aria-hidden="true">
        <use href="/svg/svg.svg#chat-2" />
      </svg>
    </button>
  );
};

ChatToogler.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ChatToogler;
