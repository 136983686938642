import React from "react";
import PropTypes from "prop-types";

const GameHeader = ({ title, children, isSoundOn, onClickSound }) => {
  return (
    <div className="game-header">
      <div className="flex items-center">
        <svg aria-hidden="true" className="fill-current text-green h-16 w-16 mr-18">
          <use href="/svg/svg.svg#arrow-up" />
        </svg>
        <div className="text-lg font-medium">{title}</div>
      </div>
      <div className="flex space-x-10">
        {children}
        <button
          type="button"
          className="btn btn--sm btn--black btn--rounded btn--fab"
          onClick={() => onClickSound(!isSoundOn)}
        >
          <svg aria-hidden="true" className="fill-current w-16 h-16">
            <use href={`/svg/svg.svg#${isSoundOn ? "music-sound" : "music-sound-off"}`} />
          </svg>
        </button>
      </div>
    </div>
  );
};

GameHeader.propTypes = {
  title: PropTypes.string.isRequired,
  isSoundOn: PropTypes.bool.isRequired,
  onClickSound: PropTypes.func.isRequired,
  children: PropTypes.node,
};

GameHeader.defaultProps = {
  children: null,
};

export default GameHeader;
