import React from "react";
import CrashControlContainer from "@/containers/games/crash/CrashControlContainer";
import CrashStatsContainer from "@/containers/games/crash/CrashStatsContainer";
import CrashBetsContainer from "@/containers/games/crash/CrashBetsContainer";

const CrashSidebarContainer = () => {
  return (
    <div className="game-wrapper__sidebar">
      <div className="game-sidebar-inner">
        <CrashControlContainer />
        <div className="pt-25 -mx-20 overflow-hidden flex flex-col mt-8 max-h-300">
          <CrashStatsContainer />
          <CrashBetsContainer />
        </div>
      </div>
    </div>
  );
};

export default CrashSidebarContainer;
