import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSubscribe } from "@/libs/centrifuge";
import { setOnline } from "@/actions/chatAction";
import Online from "@/components/Online";

const OnlineContainer = () => {
  const dispatch = useDispatch();
  const count = useSelector(({ chat }) => chat.online);
  const subscribe = useSubscribe();

  useEffect(() => {
    const unsubscribe = subscribe("online", newCount => {
      dispatch(setOnline(newCount));
    });
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return <Online count={count} />;
};

export default OnlineContainer;
