import React from "react";
import PropTypes from "prop-types";

const RouletteNumberImage = ({ number, className }) => {
  const images = {
    1: "bronze",
    2: "silver",
    3: "gold",
  };

  return <img src={`/img/${images[number]}.png`} alt="" className={className} />;
};

RouletteNumberImage.propTypes = {
  number: PropTypes.number.isRequired,
  className: PropTypes.string,
};

RouletteNumberImage.defaultProps = {
  className: "w-25 h-25 rounded-full object-contain mr-10",
};

export default RouletteNumberImage;

