import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setOpenCashier, setOpenReferral } from "@/actions/mainAction";
import authToken from "@/helpers/authToken";
import { clear } from "@/actions/authAction";
import { useApi } from "@/api";
import ProfileContainer from "@/containers/profile/ProfileContainer";
import UserMenu from "@/components/UserMenu";
import UserMenuMobile from "@/components/UserMenu/UserMenuMobile";

const UserMenuContainer = () => {
  const user = useSelector(({ auth }) => auth.user);
  const isMobile = useSelector(({ main }) => main.isMobile);
  const dispatch = useDispatch();
  const history = useHistory();
  const { signOut } = useApi();

  const logout = () => {
    signOut().then(() => {
      authToken.remove();
      dispatch(clear());
      history.replace("/");
    });
  };

  const links = [
    {
      id: "cashier",
      icon: (
        <svg aria-hidden="true" className="fill-current w-20 h-20">
          <use href="/svg/svg.svg#change" />
        </svg>
      ),
      onClick: () => {
        dispatch(setOpenCashier(true));
      },
    },
    {
      id: "profile",
      icon: (
        <svg aria-hidden="true" className="fill-current w-22 h-22">
          <use href="/svg/svg.svg#account" />
        </svg>
      ),
      component: ProfileContainer,
    },
    {
      id: "referral",
      icon: (
        <svg aria-hidden="true" className="fill-current w-20 h-20">
          <use href="/svg/svg.svg#marketing" />
        </svg>
      ),
      onClick: () => {
        dispatch(setOpenReferral(true));
      },
    },
    {
      id: "logout",
      icon: (
        <svg aria-hidden="true" className="fill-current w-20 h-20 text-red">
          <use href="/svg/svg.svg#power-off" />
        </svg>
      ),
      onClick: logout,
    },
  ];

  if(isMobile) {
    return <UserMenuMobile user={user} links={links} />
  }

  return <UserMenu user={user} links={links} />;
};

export default UserMenuContainer;
