export default {
  title: "Crash game",
  countdown: {
    title: "Preparing round",
    desc: "Starting in:"
  },
  progress: {
    desc: "Current Payout"
  },
  finish: {
    desc: "The round is over"
  },
  create: {
    amount: "Bet amount",
    autoCoefficient: "Auto Cashout",
    selectAutoCoefficient: "Choose Auto-Cashout",
    button: "Place Bet",
    demo: "Betting $0.00 will enter demo mode",
    starting: "Waiting",
    success: "You have successfully placed your bid",
    error: "Failed to place a bet",
  },
  upgrade: {
    button: "Upgrade",
    success: "Success upgrade",
    error: "Failed upgrade",
  },
  stats: {
    count: "Players"
  },
  history: "History",
}