import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Modal from "@/components/Modal";

const GamesFairModal = ({ clientSeed, serverSeed, nonce, changeClientSeed, changeServerSeed }) => {
  const { t } = useTranslation();
  const modalRef = useRef();
  const [newClientSeed, setNewClientSeed] = useState(clientSeed);

  useEffect(() => {
    setNewClientSeed(clientSeed);
  }, [clientSeed]);

  return (
    <>
      <button
        type="button"
        className="btn btn--sm btn--black"
        onClick={() => {
          modalRef.current.open()
        }}
      >
        <svg aria-hidden="true" className="fill-current text-white w-17 h-17 mr-12">
          <use href="/svg/svg.svg#exchange" />
        </svg>
        {t("fair.modal.button.open")}
      </button>

      <Modal
        ref={modalRef}
        dialogClassName="modal-dialog-centered"
        contentClassName="account-form w-800"
      >
        <div className="py-20 px-20">
          <div className="field-wrapper mt-10">
            <label htmlFor="fair-client-seed" className="field-wrapper__title">
              {t("fair.modal.label.clientSeed")}
            </label>
            <input
              type="text"
              id="fair-client-seed"
              className="text-field text-field--light"
              placeholder={t("fair.modal.placeholder.clientSeed")}
              value={newClientSeed}
              onChange={e => setNewClientSeed(e.target.value)}
              onBlur={e => setNewClientSeed(e.target.value)}
            />
            <div className="field-wrapper__description text-right">
              <button
                type="button"
                className="btn btn--primary-light btn--text btn--offset-r btn--md"
                onClick={() => changeClientSeed(newClientSeed)}
              >
                {t("fair.modal.button.changeClientSeed")}
              </button>
            </div>
          </div>
          <div className="field-wrapper mt-10">
            <label htmlFor="fair-server-seed" className="field-wrapper__title">
              {t("fair.modal.label.serverSeed")}
            </label>
            <input
              type="text"
              id="fair-server-seed"
              className="text-field text-field--light"
              placeholder={t("fair.modal.placeholder.serverSeed")}
              readOnly
              value={serverSeed}
            />
            <div className="field-wrapper__description text-right">
              <button
                type="button"
                className="btn btn--primary-light btn--text btn--offset-r btn--md"
                onClick={() => changeServerSeed()}
              >
                {t("fair.modal.button.changeServerSeed")}
              </button>
            </div>
          </div>
          <div className="field-wrapper mt-10">
            <label htmlFor="fair-nonce" className="field-wrapper__title">
              {t("fair.modal.label.nonce")}
            </label>
            <input
              type="text"
              id="fair-nonce"
              className="text-field text-field--light"
              placeholder={t("fair.modal.placeholder.nonce")}
              readOnly
              value={nonce}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

GamesFairModal.propTypes = {
  clientSeed: PropTypes.string.isRequired,
  serverSeed: PropTypes.string.isRequired,
  nonce: PropTypes.number.isRequired,
  changeClientSeed: PropTypes.func.isRequired,
  changeServerSeed: PropTypes.func.isRequired,
};

export default GamesFairModal;
