import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Formik } from "formik";
import Field from "@/components/ui/Field";
import Switcher from "@/components/ui/Switcher";

const Tip = ({ onSubmit, minimumBalance }) => {
  const { t } = useTranslation();

  const registerSchema = Yup.object().shape({
    name: Yup.string().required(t("form.validations.required")),
    amount: Yup.number()
      .min(0.01, t("form.validations.minNumber", { min: 0.01 }))
      .max(3000, t("form.validations.maxNumber", { max: 3000 }))
      .required(t("form.validations.required")),
    isPrivate: Yup.bool().required(t("form.validations.required")),
  });

  return (
    <Formik
      initialValues={{
        name: "",
        amount: null,
        isPrivate: false,
      }}
      onSubmit={(values, { resetForm }) => onSubmit(values, () => resetForm())}
      validationSchema={registerSchema}
      validateOnChange
      validateOnBlur
    >
      {({ handleChange, handleBlur, values, submitForm, setValues }) => (
        <>
          <div className="space-y-20 sm:space-y-35 mt-15 sm:mt-30">
            <Field
              name="name"
              label={t("tip.username")}
              placeholder={t("tip.usernamePlaceholder")}
              titleClassName="text-black-900"
              inputClassName="text-field--light"
              required
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={values.name}
            />

            <Field
              name="amount"
              type="number"
              label={t("tip.amount")}
              placeholder={t("tip.amountPlaceholder")}
              titleClassName="text-black-900"
              inputClassName="text-field--light"
              required
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={String(values.amount)}
            >
              <div className="field-wrapper__description text-right text-gray-150">
                {t("tip.amountDesc", { minimumBalance })}
              </div>
            </Field>
          </div>

          <div className="mt-20">
            <div className="text-xl text-black-900 mb-10">{t("tip.privateTitle")}</div>
          </div>

          <Switcher
            label={t("tip.private")}
            name="isPrivate"
            checked={values.isPrivate}
            onChange={(checked, e) => {
              handleChange(e);
            }}
          />

          <div className="text-gray-600 mt-15">{t("tip.privateDesc")}</div>

          <button
            type="button"
            className="btn btn--green btn--bold btn--md btn--rounded mt-20 sm:mt-40"
            onClick={() => submitForm()}
          >
            {t("tip.submit")}
            <svg aria-hidden="true" className="fill-current w-16 h-16 ml-10">
              <use href="/svg/svg.svg#send" />
            </svg>
          </button>
        </>
      )}
    </Formik>
  );
};

Tip.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  minimumBalance: PropTypes.number.isRequired,
};

export default Tip;
