import React from 'react';
import RouletteControlContainer from "@/containers/games/roulette/RouletteControlContainer";
import RouletteBetsContainer from "@/containers/games/roulette/RouletteBetsContainer";


const RouletteSidebarContainer = () => {
  return (
    <div className="game-wrapper__sidebar">
      <div className="game-sidebar-inner">
        <RouletteControlContainer />
        <RouletteBetsContainer />
      </div>
    </div>
  );
};

export default RouletteSidebarContainer;