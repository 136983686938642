import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import pageLoading from "@/helpers/pageLoading";

const NotFoundPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    pageLoading.hide();
  });

  return (
    <div className="main-wrapper">
      <div className="page-404">
        <div className="page-404__title">{t("404.title")}</div>
        <div className="page-404__desc">{t("404.desc")}</div>
        <Link to="/" type="button" className="btn btn--blue btn--arrow">
          {t("404.goBack")}
          <svg aria-hidden="true">
            <use xlinkHref="/svg/svg.svg#arrow-down" />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
