import React from "react";
import { useTranslation } from "react-i18next";
import GameHeaderContainer from "@/containers/games/GameHeaderContainer";
import GameFooterContainer from "@/containers/games/GameFooterContainer";
import RouletteSidebarContainer from "@/containers/games/roulette/RouletteSidebarContainer";
import RouletteRoundContainer from "@/containers/games/roulette/RouletteRoundContainer";
import RouletteSubscribeContainer from "@/containers/games/roulette/RouletteSubscribeContainer";
import RouletteCurrentStateContainer from "@/containers/games/roulette/RouletteCurrentStateContainer";
import RouletteHistoryContainer from "@/containers/games/roulette/RouletteHistoryContainer";

const RouletteMainContainer = () => {
  const { t } = useTranslation();
  return (
    <>
      <GameHeaderContainer title={t("roulette.title")} />
      <div className="game-wrapper game-wrapper--has-sidebar">
        <RouletteRoundContainer />
        <RouletteSidebarContainer />
      </div>
      <GameFooterContainer>
        <RouletteHistoryContainer />
      </GameFooterContainer>
      <RouletteCurrentStateContainer />
      <RouletteSubscribeContainer />
    </>
  );
};

export default RouletteMainContainer;
