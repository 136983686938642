import React from "react";
import PropTypes from "prop-types";

const Switcher = ({ name, className, label, desc, checked, onChange }) => {
  return (
    <>
      <label className={`switcher-wrapper ${className}`}>
        <div className="switcher">
          <input
            type="checkbox"
            name={name}
            className="sr-only"
            checked={checked}
            onChange={e => onChange(e.target.checked, e)}
          />
          <div className="switcher__content" />
        </div>
        <div className="switcher-wrapper__label">{label}</div>
      </label>
      {desc && <div className="text-gray-600 mt-15">{desc}</div>}
    </>
  );
};

Switcher.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  desc: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

Switcher.defaultProps = {
  className: "",
  desc: null,
  name: null,
};

export default Switcher;
