import { useContext } from "react";
import ApiContext from "./ApiContext";

import {
  CURRENT_STATE_URL,
  REGISTRATION_URL,
  VERIFY_EMAIL_URL,
  LOGIN_URL,
  GET_VK_AUTH_URL_URL,
  VK_SIGN_IN_URL,
  SIGN_OUT_URL,
  AUTH_USER_UPDATE_LANG_URL,
  AUTH_USER_UPDATE_CLIENT_SEED_URL,
  MINES_URL,
  MINES_STATE_URL,
  MINES_STEP_URL,
  MINES_CASH_OUT_URL,
  MINES_CHANGE_SERVER_SEED,
  VALIDATION_CHECK_EMAIL_UNIQUE,
  CRASH_STATE_URL,
  CRASH_MAKE_URL,
  CRASH_UPGRADE_URL,
  TOWERS_STATE_URL,
  TOWERS_GAMEBOARD_URL,
  TOWERS_CREATE_URL,
  TOWERS_STEP_URL,
  TOWERS_CASHOUT_URL,
  TOWERS_CHANGE_SERVER_SEED_URL,
  ROULETTE_STATE_URL,
  ROULETTE_MAKE_URL,
  DICE_STATE_URL,
  DICE_URL,
  COINFLIP_STATE_URL,
  COINFLIP_CREATE_URL,
  COINFLIP_URL,
  CHAT_URL_PREFIX,
  CASINOPUB_CREATE_SESSION_URL,
  CREATE_DEPOSIT_URL,
  READ_NOTIFICATIONS_URL,
  REFERRAL_STATE_URL,
  REFERRAL_WITHDRAW_URL,
  AUTH_USER_CHANGE_REFERRER,
  VK_LINK_URL,
  UNLINK_ACCOUNT_URL,
  STEAM_GET_URL_URL,
  STEAM_LINK_URL,
  STEAM_SIGN_IN_URL,
  FACEBOOK_GET_URL_URL,
  FACEBOOK_LINK_URL,
  FACEBOOK_SIGN_IN_URL,
  CHANGE_EMAIL_CREATE_URL,
  CHANGE_EMAIL_CONFIRM_URL,
  CHANGE_PASSWORD_URL,
  UPDATE_BOOLEAN_FIELD_URL,
  USER_DESTROY_URL,
  USER_VERIFICATION_UPLOAD_FILE_URL,
  USER_VERIFICATION_STATE_URL,
  USER_PERSONAL_INFO_SAVE_URL,
  SEND_TIP,
} from "./urls";
import langTransformer from "./langTransformer";

const useApi = () => {
  const client = useContext(ApiContext);

  return {
    currentState: () =>
      client.get(CURRENT_STATE_URL).then(data => {
        if (data.user) {
          // eslint-disable-next-line no-param-reassign
          data.user.lang = langTransformer.in(data.user.lang);
        }

        return data;
      }),

    registration: data => client.post(REGISTRATION_URL, data),
    verifyEmail: token => client.post(VERIFY_EMAIL_URL, { token }),
    login: data => client.post(LOGIN_URL, data),

    getVkAuthUrl: () => client.get(GET_VK_AUTH_URL_URL),
    signInVk: data => client.post(VK_SIGN_IN_URL, data),
    linkVk: data => client.post(VK_LINK_URL, data),

    getSteamAuthUrl: () => client.get(STEAM_GET_URL_URL),
    signInSteam: data => client.post(STEAM_SIGN_IN_URL, data),
    linkSteam: data => client.post(STEAM_LINK_URL, data),

    getFacebookAuthUrl: () => client.get(FACEBOOK_GET_URL_URL),
    signInFacebook: data => client.post(FACEBOOK_SIGN_IN_URL, data),
    linkFacebook: data => client.post(FACEBOOK_LINK_URL, data),

    signOut: () => client.post(SIGN_OUT_URL),
    unlinkAccount: type => client.post(UNLINK_ACCOUNT_URL, { type }),

    updateLang: lang =>
      client.post(AUTH_USER_UPDATE_LANG_URL, {
        lang: langTransformer.out(lang),
      }),
    updateClientSeed: clientSeed =>
      client.post(AUTH_USER_UPDATE_CLIENT_SEED_URL, {
        clientSeed,
      }),
    changeReferrer: code => client.post(AUTH_USER_CHANGE_REFERRER, { code }),

    validationCheckEmailUnique: email => client.post(VALIDATION_CHECK_EMAIL_UNIQUE, { email }),

    minesState: () => client.get(MINES_STATE_URL),
    minesCreate: data => client.post(MINES_URL, data),
    minesStep: index => client.post(MINES_STEP_URL, { index }),
    minesCashOut: () => client.post(MINES_CASH_OUT_URL),
    minesChangeServerSeed: () => client.post(MINES_CHANGE_SERVER_SEED),

    crashState: () => client.get(CRASH_STATE_URL),
    crashMake: data => client.post(CRASH_MAKE_URL, data),
    crashUpgrade: data => client.post(CRASH_UPGRADE_URL, data),

    towersState: () => client.get(TOWERS_STATE_URL),
    towersGameboard: data => client.post(TOWERS_GAMEBOARD_URL, data),
    towersCreate: data => client.post(TOWERS_CREATE_URL, data),
    towersStep: index => client.post(TOWERS_STEP_URL, { index }),
    towersCashOut: () => client.post(TOWERS_CASHOUT_URL),
    towersChangeServerSeed: () => client.post(TOWERS_CHANGE_SERVER_SEED_URL),

    rouletteState: () => client.get(ROULETTE_STATE_URL),
    rouletteMake: data => client.post(ROULETTE_MAKE_URL, data),

    diceState: () => client.get(DICE_STATE_URL),
    diceRoomState: roomId => client.get(`${DICE_URL}${roomId}`),
    diceRoomJoin: (roomId, place) => client.post(`${DICE_URL}${roomId}/join`, { place }),
    diceRoomLeave: roomId => client.post(`${DICE_URL}${roomId}/leave`),
    diceRoomBet: roomId => client.post(`${DICE_URL}${roomId}/bet`),
    diceRoomRoll: roomId => client.post(`${DICE_URL}${roomId}/roll`),

    coinflipState: () => client.get(COINFLIP_STATE_URL),
    coinflipCreate: data => client.post(COINFLIP_CREATE_URL, data),
    coinflipJoin: (roundId, data) => client.post(`${COINFLIP_URL}/${roundId}/join`, data),

    chatAllMessages: () => client.get(`${CHAT_URL_PREFIX}/all`),
    chatClear: () => client.post(`${CHAT_URL_PREFIX}/clear`),
    chatStoreMessage: data => client.post(`${CHAT_URL_PREFIX}`, data),
    chatDestroyMessage: messageId => client.post(`${CHAT_URL_PREFIX}/delete`, { messageId }),
    chatMute: data => client.post(`${CHAT_URL_PREFIX}/mute`, data),
    chatDummy: data => client.post(`${CHAT_URL_PREFIX}/dummy`, data),
    chatPin: ({ messageId }) => client.post(`${CHAT_URL_PREFIX}/pin`, { messageId }),

    getDummies: () => client.get("/dummies"),

    casinopubCreateSession: gameIdentify =>
      client.post(CASINOPUB_CREATE_SESSION_URL, { gameIdentify }),

    createDeposit: ({ endpoint, amount, paymentMethod, cid }) =>
      client.post(`${CREATE_DEPOSIT_URL}${endpoint}`, { amount, paymentMethod, cid }),

    readNotifications: () => client.post(READ_NOTIFICATIONS_URL),
    referralState: () => client.get(REFERRAL_STATE_URL),
    referralWithdraw: () => client.post(REFERRAL_WITHDRAW_URL),

    changeEmailCreate: data => client.post(CHANGE_EMAIL_CREATE_URL, data),
    changeEmailConfirm: data => client.post(CHANGE_EMAIL_CONFIRM_URL, data),

    changePassword: data => client.post(CHANGE_PASSWORD_URL, data),

    updateBooleanField: data => client.post(UPDATE_BOOLEAN_FIELD_URL, data),
    personalInfoSave: data => client.post(USER_PERSONAL_INFO_SAVE_URL, data),
    userDestroy: () => client.post(USER_DESTROY_URL),
    userVerificationUpload: data =>
      client.post(USER_VERIFICATION_UPLOAD_FILE_URL, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    userVerificationState: () => client.get(USER_VERIFICATION_STATE_URL),
    sendTip: data => client.post(SEND_TIP, data),
  };
};

export default useApi;
