import React from "react";
import PropTypes from "prop-types";

const MinesRoundTile = ({ onClick, open, isBomb }) => {
  return (
    <button
      type="button"
      className={`mines-game-btn mines-flip-anim ${open ? "mines-flip-start" : ""}`}
      onClick={onClick}
    >
      <div className="mines-game-btn__front" />
      <div
        className={`mines-game-btn__back ${
          isBomb ? "mines-game-btn__back--bomb" : "mines-game-btn__back--star"
        }`}
        style={{ backfaceVisibility: "hidden", transform: "rotateY(180deg)" }}
      >
        <svg aria-hidden="true">
          <use href={`/svg/svg.svg#${isBomb ? "bomb" : "star"}`} />
        </svg>
        <svg aria-hidden="true">
          <use href={`/svg/svg.svg#${isBomb ? "bomb" : "star"}`} />
        </svg>
      </div>
    </button>
  );
};

MinesRoundTile.propTypes = {
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isBomb: PropTypes.bool.isRequired,
};

export default MinesRoundTile;
