import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { BET_WITHDRAW_STATUS, BET_CRASHED_STATUS } from "@/constants/crashBetStatuses";

const CrashBets = ({ bets }) => {
  const { t } = useTranslation();

  return (
    <ul
      className="overflow-auto focus-outline-inset disable-scrollbars lg:max-h-full"
      style={{ height: "24rem" }}
    >
      {bets.map(({ id, user, deposit, status, withdraw, coefficient }) => {
        let classNameSum = "";
        let classNameSymbol = "";

        if (status === BET_WITHDRAW_STATUS) {
          classNameSum = "text-green-200";
          classNameSymbol = "bg-green";
        }
        if (status === BET_CRASHED_STATUS) {
          classNameSum = "text-red";
          classNameSymbol = "bg-red";
        }

        return (
          <li key={id} className="flex items-center justify-between px-20 py-5 odd:bg-black-600">
            <div className={!user.name ? "text-gray-200" : ""}>
              {status === BET_WITHDRAW_STATUS && (
                <span className="text-green-200 mr-5">{coefficient.toFixed(2)}x</span>
              )}
              {user.name || t("hidden")}
            </div>
            <div className="flex items-center">
              <span className={classNameSum}>
                {status === BET_WITHDRAW_STATUS ? `+${withdraw.toFixed(2)}` : deposit.toFixed(2)}
              </span>
              <div className={`currency-symbol ml-7 ${classNameSymbol}`}>$</div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

CrashBets.propTypes = {
  bets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
      }).isRequired,
      deposit: PropTypes.number.isRequired,
      withdraw: PropTypes.number,
      coefficient: PropTypes.number,
      status: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default CrashBets;
