import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";

const Field = ({
  name,
  label,
  required,
  icon,
  placeholder,
  handleBlur,
  handleChange,
  value,
  type,
  className,
  inputClassName,
  titleClassName,
  errorMessageDisabled,
  children,
}) => {
  return (
    <label htmlFor={`form-field-${name}`} className={`field-wrapper ${className}`}>
      {label && (
        <div className={`field-wrapper__title ${titleClassName}`}>
          {label} {required && <span className="text-red">*</span>}
        </div>
      )}
      <div className="relative">
        {icon && <div className="field-wrapper__icon">{icon}</div>}
        <input
          id={`form-field-${name}`}
          type={type}
          className={`text-field ${inputClassName}`}
          placeholder={placeholder}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
        />
      </div>
      {children}
      {!errorMessageDisabled && (
        <ErrorMessage
          name={name}
          render={message => <div className="mt-10 text-red">{message}</div>}
        />
      )}
    </label>
  );
};

Field.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  icon: PropTypes.node,
  inputClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  className: PropTypes.string,
  errorMessageDisabled: PropTypes.bool,
  children: PropTypes.node,
};

Field.defaultProps = {
  type: "text",
  label: null,
  required: false,
  value: null,
  icon: null,
  placeholder: null,
  inputClassName: "",
  titleClassName: "",
  className: "",
  errorMessageDisabled: false,
  children: null,
};

export default Field;
