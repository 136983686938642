import eventEmitter from "@/components/games/crash/eventEmitter";

class Progress {
  constructor(wrapper, onChangeCurrent, prepareGrow) {
    this.ready = true;
    this.prepareGrow = prepareGrow;
    this.onChangeCurrent = onChangeCurrent;
    this.DOM = {};
    this.DOM.wrapper = wrapper;

    this.DOM.countdownValue = this.DOM.wrapper.querySelector("[data-graph-countdown-value]");
    this.DOM.countdownProgress = this.DOM.wrapper.querySelector("[data-graph-countdown-progress]");
    this.DOM.counterValue = this.DOM.wrapper.querySelector("[data-graph-counter-value]");
    this.DOM.svg = this.DOM.wrapper.querySelector("[data-graph-main]");
    this.DOM.svgLine = this.DOM.svg.querySelector("[data-graph-line]");
    this.DOM.svgLineBefore = this.DOM.svg.querySelector("[data-graph-line-before]");
    this.DOM.svgLineAfter = this.DOM.svg.querySelector("[data-graph-line-after]");
    this.isMobile = window.matchMedia("(max-width: 640px)").matches;

    this.DOM.lines = this.DOM.wrapper.querySelector("[data-graph-lines]");
    this.DOM.lines.innerHTML = "<div></div>".repeat(120);
    this.DOM.linesSet = Array.from(this.DOM.lines.children);

    this.DOM.seconds = this.DOM.wrapper.querySelector("[data-graph-seconds]");
    this.DOM.seconds.innerHTML = "<div></div>".repeat(30);
    this.DOM.secondsSet = Array.from(this.DOM.seconds.children);

    this.DOM.drops = this.DOM.wrapper.querySelector("[data-graph-drops]");
    this.drops = [];

    this.offset = 0.25;
    this.defaultEndIndex = 3 * (1 + this.offset);

    this.timelineMax = 1;
    this.timelineStep = 0.5;

    this.progress = {
      start: 1,
      secondsEstimated: 0,
      cycleTime: 0.15,
      cycleStep: 1.009,
      cycles: 0,
      current: 1,
    };

    this.isFinish = false;
    this.isCountDown = false;
  }

  setSvgParams() {
    const rect = this.DOM.svg.getBoundingClientRect();
    this.w = rect.width;
    this.h = rect.height;
    this.DOM.svg.setAttribute("width", this.w);
    this.DOM.svg.setAttribute("height", this.h);
    this.DOM.svg.setAttribute("viewBox", `0 0 ${this.w} ${this.h}`);

    this.offsetBottom = 37;
    this.offsetRight = this.isMobile ? 65 : 90;

    this.yLineHeight = this.h - this.offsetBottom;
    this.xLineWidth = this.w - this.offsetRight;
    this.fullWidth = this.w - 60 - 50;
  }

  addDrop(p, x = this.progress.current.toFixed(2)) {
    const findIndex = this.drops.findIndex(drop => drop.x === x);
    if (findIndex > -1) {
      if (
        Number(this.drops[findIndex].elem.querySelector(".graph-drop__inner b").textContent) < p
      ) {
        this.drops[findIndex].elem.querySelector(".graph-drop__inner b").textContent = p;
      }
      return;
    }
    this.DOM.drops.insertAdjacentHTML(
      "beforeend",
      `
            <div class="graph-drop">
                <div class="graph-drop__inner">
                    <b>${p}</b>
                    <span>${x}x</span>
                </div>
            </div>
        `,
    );
    const elem = this.DOM.drops.lastElementChild;
    this.drops.push({ x, elem });
  }

  drawTimeline() {
    if (this.progress.secondsEstimated < 1) {
      this.timelineMax = 1;
    } else {
      this.timelineMax = this.progress.secondsEstimated;
    }

    const values = [];
    for (let i = 0; i <= this.timelineMax; i += this.timelineStep) {
      values.push(i);
      values.push(i + this.timelineStep / 2);
    }

    for (let i = values.length; i < this.DOM.secondsSet.length; i++) {
      const item = this.DOM.secondsSet[i];
      item.dataset.value = "";
      item.classList.remove("large-second", "small-second");
    }
    for (let i = 0; i < values.length; i++) {
      const value = values[i];
      const position = 40 + ((this.xLineWidth - 60) * value) / this.timelineMax;
      const item = this.DOM.secondsSet[i];
      item.classList.toggle("large-second", !(i % 2));
      item.classList.toggle("small-second", i % 2);
      item.dataset.value = `${parseFloat(value.toFixed(this.timelineStep < 1 ? 1 : 0))}s`;
      item.style.transform = `translate3d(${position}px,0,0)`;
    }
  }

  drawScaleScales() {
    let yLineHeight = this.yLineHeight;
    let { endIndex, startIndex } = this;
    let drawScale = (i, value, size) => {
      let position = yLineHeight - yLineHeight * this._rangeToPercent(value, startIndex, endIndex);

      let elem = this.DOM.linesSet[i];

      if (this.steps.large >= 5 && value > 1) {
        value = value - startIndex;
      }

      if(elem) {
        elem.className = size;
        elem.style.transform = "translate3d(0," + position.toFixed(1) + "px,0)";
        elem.dataset.value = size !== "small" ? value.toFixed(2) + "x" : "";
      }
    };

    let isInRange = (i, step) => {
      let val = ((i - startIndex) / step).toFixed(2);
      return Math.trunc(val) == val;
    };
    let values = [];

    for (let i = startIndex; i <= endIndex / (1 - this.offset); i = i + this.steps.small) {
      let size = "";
      if (isInRange(i, this.steps.large)) {
        size = "large";
      } else if (isInRange(i, this.steps.medium)) {
        size = "medium";
      } else if (isInRange(i, this.steps.small)) {
        size = "small";
      }
      if (size) {
        values.push([i, size]);
      }
    }

    for (let i = values.length; i < this.DOM.linesSet.length; i++) {
      this.DOM.linesSet[i].className = "";
    }
    for (let i = 0; i < values.length; i++) {
      let elem = values[i];
      drawScale(i, elem[0], elem[1]);
    }
  }

  _rangeToPercent(number, min, max) {
    return (number - min) / (max - min);
  }

  setSteps() {
    var progress = this.progress.current;

    if (this.progress.current > this.defaultEndIndex) {
      this.endIndex = this.progress.current;
    } else {
      this.endIndex = this.defaultEndIndex;
      var progress = this.defaultEndIndex;
    }

    if (progress > 500) {
      this.steps = {
        large: 200,
        medium: 100,
        small: 50,
      };
    } else if (progress > 200) {
      this.steps = {
        large: 100,
        medium: 50,
        small: 25,
      };
    } else if (progress > 100) {
      this.steps = {
        large: 50,
        medium: 25,
        small: 5,
      };
    } else if (progress > 40) {
      this.steps = {
        large: 20,
        medium: 10,
        small: 5,
      };
    } else if (progress > 20) {
      this.steps = {
        large: 10,
        medium: 5,
        small: 1,
      };
    } else if (progress > 10) {
      this.steps = {
        large: 5,
        medium: 2.5,
        small: 0.5,
      };
    } else if (progress > 4) {
      this.steps = {
        large: 2,
        medium: 1,
        small: 0.5,
      };
    } else if (progress > 3) {
      this.steps = {
        large: 1,
        medium: 0.5,
        small: 0.1,
      };
    } else if (progress > 2) {
      if (window.matchMedia("(min-width: 1023px)").matches) {
        this.steps = {
          large: 0.5,
          medium: 0.25,
          small: 0.05,
        };
      } else {
        this.steps = {
          large: 1,
          medium: 0.5,
          small: 0.1,
        };
      }
    } else if (progress > 1) {
      this.steps = {
        large: 0.2,
        medium: 0.1,
        small: 0.05,
      };
    }

    if (this.progress.secondsEstimated > 60) {
      this.timelineStep = 20;
    } else if (this.progress.secondsEstimated > 30) {
      this.timelineStep = 10;
    } else if (this.progress.secondsEstimated > 10) {
      this.timelineStep = 5;
    } else if (this.progress.secondsEstimated > 4) {
      this.timelineStep = 2;
    } else if (this.progress.secondsEstimated > 2) {
      this.timelineStep = 1;
    }
  }

  draw() {
    const { current } = this.progress;
    this.DOM.wrapper.classList.toggle("finish", this.isFinish);
    this.DOM.wrapper.classList.toggle("countdown", this.isCountDown);
    this.DOM.wrapper.classList.toggle("progress", !this.isFinish && !this.isCountDown);

    this.setSteps();

    // На сколько округлять числа
    const toFixedVal = 2;

    // Если это не обратный отсчет - не отрисовывам график
    if (this.isCountDown) {
      this.DOM.countdownProgress.style.width = `${100 - this.progress.current * 10}%`;

      this.DOM.countdownValue.textContent =
        Math.abs(current).toFixed(toFixedVal) + (this.isCountDown ? "s" : "x");
      return;
    }

    this.DOM.counterValue.textContent =
      Math.abs(current).toFixed(toFixedVal) + (this.isCountDown ? "s" : "x");

    // this.DOM.linesSet.forEach(e => e.classList.add('hidden'))
    this.drawGraph();
    this.drawTimeline();
    this.drawScaleScales();

    this.currentLine = 0;

    this.DOM.svgLineLength = this.DOM.svgLine.getTotalLength();
    this.drops.forEach((drop, i, list) => {
      const percent = drop.x / this.progress.current;
      const location = this.DOM.svgLine.getPointAtLength(this.DOM.svgLineLength * percent);
      drop.percent = percent;
      drop.location = location;

      const next = list[i + 1];
      if (next && !next.hidden && next.location) {
        if (drop.location.x + 40 - next.location.x > 0) {
          next.hidden = true;
          next.elem.classList.add("graph-drop--short");
        }
      }
    });

    this.drops.forEach((drop, i, list) => {
      drop.elem.style.transform = `translate3d(${drop.location.x < 30 ? 30 : drop.location.x}px, ${
        drop.location.y
      }px,0)`;
    });
  }

  drawGraph() {
    const p = this.progress.growPercent || 0;
    const h = this.yLineHeight;
    if (this.progress.secondsEstimated > 1) {
      var w = this.xLineWidth;
      //  this.DOM.bullet.classList.add("hide-fire");
    } else {
      var w = this.xLineWidth * this.progress.secondsEstimated;
    }

    const top = h - h * p;
    const t = o => (top + h * p * o).toFixed(1);
    const l = o => (w * o).toFixed(1);
    const point = (ol, ot) => `${l(ol)},${t(ot)}`;

    const curve = `
        M0,${h} C${point(0.124, 0.858)}
        ${point(0.368, 0.774)} ${point(0.536, 0.645)} C${point(0.8, 0.435)}
        ${point(0.848, 0.17)} ${w},${top}
      `;
    this.DOM.svgLine.setAttribute("d", curve);

    const curveBefore = `
        M0,${h} C${point(0.124, 0.858)}
        ${point(0.298, 0.696)} ${point(0.518, 0.569)} C${point(0.8, 0.387)}
        ${point(0.848, 0.17)} ${w},${top}
      `;
    this.DOM.svgLineBefore.setAttribute("d", curveBefore);

    const curveAfter = `
        M0,${h} C${point(0.124, 0.858)}
        ${point(0.39, 0.84)} ${point(0.558, 0.716)} C${point(0.822, 0.506)}
        ${point(0.848, 0.17)} ${w},${top}
      `;
    this.DOM.svgLineAfter.setAttribute("d", curveAfter);
  }

  start(s = 0) {
    if (this.isFinish) {
      return;
    }
    this.started = true;
    this.startIndex = 1;
    this.endIndex = this.defaultEndIndex;

    this.setSvgParams();

    this.stopped = false;

    s /= 1000;
    this.startDate = new Date();
    this.progress.secondsReal = 0;
    this.progress.serverSecondsDelay = 0;
    if (s) {
      this.progress.serverSecondsDelay = this.progress.secondsReal - s;
    }
    this.isCountDown = false;
    this.isFinish = false;

    window.requestAnimationFrame(() => this.step());
  }

  step() {
    if (this.isFinish) {
      return;
    }
    this.progress.secondsReal = (new Date() - this.startDate) / 1000;

    this.progress.secondsEstimated = this.progress.secondsReal - this.progress.serverSecondsDelay;
    this.progress.cycles = this.progress.secondsEstimated / this.progress.cycleTime;
    this.progress.current =
      this.progress.start * Math.pow(this.progress.cycleStep, this.progress.cycles);
    // Оставляем конструкцию, чтобы высчитывать прогресс для скоростии линии
    this.progress.growPercent = (this.progress.current - 1) / (this.defaultEndIndex - 1);
    this.progress.growPercent = this.progress.growPercent > 1 ? 1 : this.progress.growPercent;

    this.draw();

    if (this.onChangeCurrent) {
      this.onChangeCurrent(this.progress.current);
      eventEmitter.publishCoefficient(this.progress.current);
    }

    if (!this.stopped) {
      window.requestAnimationFrame(() => this.step());
    }
  }

  updateProgress(current) {
    this.progress.current = current;
    this.progress.growPercent = (this.progress.current - 1) / 8;
    this.progress.growPercent = this.progress.growPercent > 1 ? 1 : this.progress.growPercent;
  }

  stop() {
    this.stopped = true;
  }

  finish(coefficient) {
    this.stop();
    this.started = false;
    this.isFinish = true;
    this.isCountDown = false;
    this.DOM.drops.innerHTML = "";
    if (coefficient) {
      this.progress.current = coefficient;
    }
    this.draw();
  }

  countDown(s = 3000, onFinish = () => {}) {
    this.startIndex = 1;
    this.endIndex = this.defaultEndIndex;

    this.setSvgParams();

    s /= 1000;

    this.isFinish = false;
    this.DOM.drops.innerHTML = "";
    this.drops = [];
    this.countDownStart = s;
    this.isCountDown = true;
    this.stop();
    this.startDate = new Date();

    this.setSteps();

    this.drawTimeline();
    this.drawScaleScales();

    let isPrepareGrow = false;

    const countDownFunc = () => {
      if (!this.isCountDown) {
        return;
      }
      this.countDownProgress = (new Date() - this.startDate) / 1000;
      const progress = this.countDownStart - this.countDownProgress;
      if (!isPrepareGrow && progress < 1.1) {
        isPrepareGrow = true;
        this.prepareGrow();
      }
      this.progress.current = progress;

      if (progress <= 0) {
        onFinish();
      } else {
        window.requestAnimationFrame(() => countDownFunc());
        this.draw();
      }
    };
    countDownFunc();
  }
}

export default Progress;
