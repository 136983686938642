import React from "react";
import { useSelector } from "react-redux";
import Footer from "@/components/Footer";
import SocialLinksContainer from "@/containers/SocialLinksContainer";

const links = [
  {
    id: "terms",
    path: "/terms",
  },
  {
    id: "privacy",
    path: "/privacy",
  },
  {
    id: "fair",
    path: "/fair",
  },
  {
    id: "vip",
    path: "/vip",
  },
  {
    id: "aml",
    path: "/aml",
  },
  {
    id: "responsible",
    path: "/responsible-gaming",
  },
];

const FooterContainer = () => {
  const online = useSelector(({ chat }) => chat.online);

  return (
    <Footer links={links} online={online}>
      <SocialLinksContainer />
    </Footer>
  );
};

export default FooterContainer;
