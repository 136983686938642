import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import noty from "@/libs/noty";

const ProfileUpdateBooleanFieldContainer = ({ field, children }) => {
  const { t } = useTranslation();
  const [pending, setPending] = useState(false);
  const { updateBooleanField } = useApi();

  const onChange = value => {
    if (pending) {
      return false;
    }

    setPending(true);

    return updateBooleanField({
      field,
      value,
    })
      .then(() => true)
      .catch(() => {
        noty.error(t("profile.updateBooleanField.fail"));
        return false;
      })
      .finally(() => {
        setPending(false);
      });
  };

  return children({ onChange, disabled: pending });
};

ProfileUpdateBooleanFieldContainer.propTypes = {
  field: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
};

export default ProfileUpdateBooleanFieldContainer;
