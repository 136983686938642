import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { TOWERS_RESOLVE_STATUS } from "@/constants/towersStatus";

const TowerBets = ({ bets }) => {
  const { t } = useTranslation();

  return (
    <div className="pt-25 -mx-20 overflow-hidden flex flex-col mt-8 max-h-300">
      <div className="flex items-center text-gray-600 mb-10 px-20">{t("towers.lastGames")}</div>

      <ul
        className="overflow-auto focus-outline-inset disable-scrollbars lg:max-h-full"
        style={{ height: "24rem" }}
      >
        {bets.map(({ id, coefficient, deposit, withdraw, status, userName }) => {
          const isWinner = status === TOWERS_RESOLVE_STATUS;

          return (
            <li key={id} className="flex items-center justify-between px-20 py-5 odd:bg-black-600">
              <div>
                {coefficient > 1 && (
                  <span className="text-green-200 mr-5">{coefficient.toFixed(2)}x</span>
                )}{" "}
                {userName || t("hidden")}
              </div>
              <div className="flex items-center">
                <span className={isWinner ? "text-green" : "text-red"}>
                  {(withdraw - deposit).toFixed(2)}
                </span>
                <div className={`currency-symbol ml-7 ${isWinner ? "bg-green" : "bg-red"}`}>$</div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

TowerBets.propTypes = {
  bets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      coefficient: PropTypes.number.isRequired,
      deposit: PropTypes.number.isRequired,
      withdraw: PropTypes.number.isRequired,
      status: PropTypes.number.isRequired,
      userName: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default TowerBets;
