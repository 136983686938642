import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import noty from "@/libs/noty";
import ProfileVerificationForm from "@/components/profile/ProfileVerificationForm";

const ProfileVerificationFormContainer = () => {
  const { t } = useTranslation();
  const [pending, setPending] = useState(false);
  const user = useSelector(({ auth }) => auth.user);
  const { personalInfoSave } = useApi();

  const submit = data => {
    if (pending) {
      return;
    }

    setPending(true);

    personalInfoSave(data)
      .then(() => {
        noty.success(t("profile.verification.form.success"));
      })
      .catch(() => {
        noty.error(t("profile.verification.form.fail"));
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <ProfileVerificationForm
      onSubmit={submit}
      initialValues={{
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        country: user.country || "",
        city: user.city || "",
        address: user.address || "",
        birthday: user.birthday || "",
      }}
      isVerified={user.verification ? user.verification.status === 2 : false}
    />
  );
};

export default ProfileVerificationFormContainer;
