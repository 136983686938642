import React from "react";
import PropTypes from "prop-types";

const ErrorPage = ({ title }) => {
  return <h1>{title}</h1>;
};

ErrorPage.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ErrorPage;
