import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ProfileVerification = ({ form, uploaders }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="account-modal-title mb-10">{t("profile.verification.form.title")}</div>
      {form}
      <div className="account-modal-title mt-10">{t("profile.verification.upload.title")}</div>
      <div className="text-gray-500">
        <p>{t("profile.verification.upload.desc")}</p>
      </div>
      <div className="text-gray-600 mt-20 mb-40">{t("profile.verification.upload.fileTypes")}</div>
      {uploaders}
    </div>
  );
};

ProfileVerification.propTypes = {
  form: PropTypes.node.isRequired,
  uploaders: PropTypes.node.isRequired,
};

export default ProfileVerification;
