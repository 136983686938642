import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSubscribe } from "@/libs/centrifuge";
import noty from "@/libs/noty";
import { updateUser } from "@/actions/authAction";

const UserSubscriberContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector(({ auth }) => (auth.user ? auth.user.id : null));
  const subscribe = useSubscribe();

  useEffect(() => {
    let unsubscribe;
    let unsubscribeNoty;
    let unsubscribeInventory;

    if (userId) {
      unsubscribe = subscribe(`u-#${userId}`, data => {
        dispatch(updateUser(data));
      });

      unsubscribeNoty = subscribe(`u-noty#${userId}`, ({ type, name }) => {
        noty[type](t(`noty.${name}`));
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
      if (unsubscribeInventory) {
        unsubscribeInventory();
      }
      if (unsubscribeNoty) {
        unsubscribeNoty();
      }
    };
  }, [userId]);

  return <></>;
};

export default UserSubscriberContainer;
