import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CrashHistory = ({ games }) => {
  const { t } = useTranslation();

  return (
    <div className="flex overflow-hidden items-center lg:pl-20 text-lg">
      <div className="text-gray-250 mr-10 flex-shrink-0">{t("crash.history")}</div>
      <svg
        aria-hidden="true"
        className="fill-current w-10 h-10 text-gray-250 mr-7 opacity-40 flex-shrink-0"
      >
        <use href="/svg/svg.svg#arrow-right" />
      </svg>
      <ul className="flex overflow-hidden font-medium">
        {games.map(({ id, crash }) => (
          <li key={id} className={`px-5 ${crash > 1.2 ? "text-green" : "text-red"}`}>
            {crash}x
          </li>
        ))}
      </ul>
    </div>
  );
};

CrashHistory.propTypes = {
  games: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      crash: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default CrashHistory;
