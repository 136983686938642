import React from 'react';

const PageLoader = () => {
    return (
        <div className="main-loading">
            <svg aria-hidden="true">
                <use href="/svg/svg.svg#logo"/>
            </svg>
            <svg aria-hidden="true">
                <use href="/svg/svg.svg#logo"/>
            </svg>
        </div>
    );
};

export default PageLoader;