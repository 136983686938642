import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CrashChart from "@/components/games/crash/CrashChart";
import { BET_WITHDRAW_STATUS } from "@/constants/crashBetStatuses";
import { setCoefficient } from "@/actions/crashAction";

const GameChartContainer = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector(({ main }) => main.isMobile);
  const status = useSelector(({ crash }) => crash.status);
  const delta = useSelector(({ crash }) => crash.delta);
  const crash = useSelector(({ crash }) => crash.crash);
  const bets = useSelector(({ crash }) => crash.bets);

  const withdrawBets = bets.filter(({ status }) => status === BET_WITHDRAW_STATUS);

  const changeCoefficient = coefficient => {
    window.coefficient = coefficient;
    dispatch(setCoefficient(coefficient));
  };

  return (
    <CrashChart
      isMobile={isMobile}
      status={status}
      delta={delta}
      crash={crash}
      bets={withdrawBets}
      onChangeCoefficient={changeCoefficient}
    />
  );
};

export default GameChartContainer;
