export default {
  title: "Notifications",
  1: {
    title: "Refill",
    // eslint-disable-next-line no-template-curly-in-string
    description:
      'You have been made deposit <span class="green">$</span><span class="green">{{ amount }}</span>',
  },
  2: {
    title: "Withdrawal #{{id}}",
    description: `The item <span class="white">{{ name }}</span> with the price <span class="green">$</span><span class="green">{{ price }}</span> has been added to withdrawal queue. Stay for notifications`,
  },
  3: {
    title: "Withdrawal #{{id}}",
    description: `The item <span class="white">{{ name }}</span> with the price <span class="green">$</span><span class="green">{{ price }}</span> has been sent to your Steam. Check trade offers.`,
  },
  4: {
    title: "Withdrawal #{{id}}",
    description: `The item <span class="white">{{ name }}</span> with the price <span class="green">$</span><span class="green">{{ price }}</span> was successfully withdrawn`,
  },
  5: {
    title: "Withdrawal #{{id}}",
    description: `Failed to withdraw the item <span class="white">{{ name }}</span> with the price <span class="green">$</span><span class="green">{{ price }}</span>. You can try again later or withdraw another item`,
  },
  6: {
    title: "Withdrawal #{{id}}",
    description: `Failed to withdraw the item <span class="white">{{ name }}</span> with the price <span class="green">$</span><span class="green">{{ price }}</span>.<br> {{ instructions }}`,
  },
  10: {
    title: "Refund #{{ id }}",
    description: `You were successfully refunded for a bet in the game # {{id}} in the amount of <span class="green">$</span><span class="green">{{amount}}</span>`,
  },
  15: {
    title: "You won the giveaway!",
    description: `You won <span class="green">{{name}}</span> for <span class="green">$</span><span class="green">{{amount}}</span>`,
  },
  20: {
    title: 'Achievement "{{ name }}"',
    description: "{{ description }}",
  },
  30: {
    title: 'You got a tip',
    description: "You got a tip of $ {{ amount }}",
  },
  33: {
    title: "Win!",
    description:
      "Your bet on the event {{ eventName }} ({{ optionName }}) has been won. Win: $ {{ withdraw }}",
  },
  34: {
    title: "Lose!",
    description:
      "Your bet on the event {{ eventName }} ({{ optionName }}) has not been played.",
  },
  35: {
    title: "Refund",
    description:
      "You were refunded for your bet on the event {{ eventName }} ({{ optionName }}).",
  },
  69: {
    title: "A gift for you",
    description:
      "The Great God {{ giver }} present to you {{ amount }} dollars",
  },
  instructions: {
    3: "There is no such item on the market or the price doesn't fit. Try again later or withdraw another item",
    4: "You set wrong Steam Trade-URL in Your profile, check it. Bot can't get Your inventory",
    5: "Seems you have a trade ban or your inventory is not public. Check it at Your Steam profile",
    6: "There is no such item on the market or the price doesn't fit. Try again later or withdraw another item",
    8: "We can't find the item on the market. Try again later or withdraw another item",
    9: "You set wrong Steam Trade-URL in Your profile, check it. Bot can't send your items",
  },
};
