import React, { useState } from "react";
import PropTypes from "prop-types";

const AutoCoefficientSettings = ({
  defaultCoefficients,
  onSetNewCoefficients,
  value,
  onSelect,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [coefficients, setCoefficients] = useState([...defaultCoefficients]);

  const indexes = [0, 1, 2, 3];

  return (
    <div className="flex items-center justify-between">
      <div className="inline-grid grid-cols-4 gap-8 w-250 max-w-full lg:w-auto">
        {indexes.map(index => {
          if (isEdit) {
            return (
              <input
                key={index}
                type="number"
                className="btn btn--md btn--light-black w-full text-center px-5 focus:text-white"
                value={coefficients[index]}
                onChange={e => {
                  coefficients[index] = e.target.value;
                  setCoefficients([...coefficients]);
                  onSetNewCoefficients(coefficients);
                }}
              />
            );
          }
          return (
            <button
              key={index}
              type="button"
              className={`btn btn--md btn--light-black w-full text-center px-5 focus:text-white ${
                value === coefficients[index] ? "text-white" : ""
              }`}
              onClick={() => onSelect(coefficients[index])}
            >
              {coefficients[index]}
            </button>
          );
        })}
      </div>
      <button
        type="button"
        className={`btn btn--md btn--fab btn--light-black flex-shrink-0 ml-18  ${
          isEdit ? "btn--green" : "btn--light-black"
        }`}
        onClick={() => setIsEdit(!isEdit)}
      >
        <svg aria-hidden="true" className="fill-current w-16 h-16">
          <use href={`/svg/svg.svg#${isEdit ? "basic-tick" : "gear"}`} />
        </svg>
      </button>
    </div>
  );
};

AutoCoefficientSettings.propTypes = {
  defaultCoefficients: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSetNewCoefficients: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default AutoCoefficientSettings;
