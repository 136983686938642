import React from "react";

const CoinflipStats = () => {
  return (
    <div className="pt-25 -mx-20 overflow-hidden flex flex-col mt-8 max-h-300">
      <div className="flex items-center text-gray-600 mb-10 px-20">
        <svg aria-hidden="true" className="fill-current text-green w-30 h-25 mr-5 -ml-5 -mt-2">
          <use href="/svg/svg.svg#crown" />
        </svg>
        <span>Last Lucky Wins</span>
      </div>

      <ul
        className="overflow-auto focus-outline-inset disable-scrollbars lg:max-h-full"
        style={{ height: "24rem" }}
      >
        <li className="flex items-center justify-between px-20 py-5 odd:bg-black-600">
          <div>Nurmagamedov</div>
          <div className="flex items-center">
            <span>30.20</span>
            <div className="currency-symbol ml-7">$</div>
          </div>
        </li>
        <li className="flex items-center justify-between px-20 py-5 odd:bg-black-600">
          <div>
            <span className="text-green-200 mr-5">3.00x</span> Balabanov Alexei
          </div>
          <div className="flex items-center">
            <span className="text-green-200">+90.00</span>
            <div className="currency-symbol bg-green ml-7">$</div>
          </div>
        </li>
        <li className="flex items-center justify-between px-20 py-5 odd:bg-black-600">
          <div className="text-gray-200">[Hidden]</div>
          <div className="flex items-center">
            <span>30.20</span>
            <div className="currency-symbol ml-7">$</div>
          </div>
        </li>
        <li className="flex items-center justify-between px-20 py-5 odd:bg-black-600">
          <div>Porsche Cayman</div>
          <div className="flex items-center">
            <span className="text-red">-30.20</span>
            <div className="currency-symbol bg-red ml-7">$</div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default CoinflipStats;
