import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSubscribe from "@/libs/centrifuge/useSubscribe";
import { newBet, updateBet, setBet, updateUserBet } from "@/actions/crashAction";
import betMinifiedToBet from "./transformers/betMinifiedToBet";
import betUpdateMinifiedToBet from "./transformers/betUpdateMinifiedToBet";

const CrashBetsSubscriberContainer = () => {
  const user = useSelector(({ auth }) => auth.user);
  const userBet = useSelector(({ crash }) => crash.bet);
  const dispatch = useDispatch();
  const subscribe = useSubscribe();

  const userId = user ? user.id : null;

  useEffect(() => {
    const unsubscribe = subscribe("bets", ({ type, b }) => {
      switch (type) {
        case "nb": {
          const bet = betMinifiedToBet(b);

          if (bet.user.id === userId) {
            dispatch(setBet(bet));
          }

          dispatch(newBet(bet));
          break;
        }

        case "ub": {
          const bet = betUpdateMinifiedToBet(b);
          dispatch(updateBet(bet));

          if (userBet && bet.id === userBet.id) {
            dispatch(updateUserBet(bet));
          }

          break;
        }

        default:
          break;
      }
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [userId, userBet]);

  return <></>;
};

export default CrashBetsSubscriberContainer;
