import { combineReducers } from "redux";
import auth from "./auth";
import main from "./main";
import mines from "./mines";
import crash from "./crash";
import chat from "./chat";
import towers from "./towers";
import roulette from "./roulette";
import dice from "./dice";
import coinflip from "./coinflip";
import live from "./live";
import referral from "./referral";

export default combineReducers({
  auth,
  main,
  mines,
  crash,
  towers,
  chat,
  roulette,
  dice,
  coinflip,
  live,
  referral,
});
