import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Line } from "react-chartjs-2";
import toLocaleDate from "@/helpers/toLocaleDate";

const ReferralEarns = ({ earns }) => {
  const { t } = useTranslation();
  const [activeDays, setActiveDays] = useState(7);

  const dates = new Array(activeDays)
    .fill(null)
    .map((v, index) => moment().subtract(index, "days"))
    .reverse();
  const values = dates.map(date => {
    return earns
      .filter(({ createdAt }) => {
        return toLocaleDate(createdAt, "DD.MM") === date.format("DD.MM");
      })
      .reduce((sum, { amount }) => sum + Number(amount), 0);
  });

  return (
    <div className="mt-40">
      <div className="flex justify-between items-center">
        <div className="text-xl">{t("referral.earns.title")}</div>
        <label>
          <span className="sr-only">{t("referral.earns.range")}</span>
          <select
            className="select-field"
            onChange={e => setActiveDays(Number(e.target.value))}
            value={activeDays}
          >
            {[7, 14, 30].map(days => {
              return (
                <option key={days} value={days}>
                  {days} {t("referral.earns.days")}
                </option>
              );
            })}
          </select>
        </label>
      </div>

      <Line
        data={canvas => {
          const ctx = canvas.getContext("2d");

          const gradientFill = ctx.createLinearGradient(0, 0, 0, 400);
          gradientFill.addColorStop(0, "rgba(18, 187, 101, .5)");
          gradientFill.addColorStop(0.5, "rgba(18, 187, 101, 0)");
          gradientFill.addColorStop(1, "rgba(18, 187, 101, 0)");

          return {
            labels: dates.map(date => date.format("DD.MM")),
            datasets: [
              {
                label: t("referral.earns.chartTitle"),
                data: values,
                backgroundColor: gradientFill,
                borderColor: "rgba(18, 187, 101, 1)",
                borderWidth: 2,
                pointBackgroundColor: "#fff",
                pointHoverBackgroundColor: "#fff",
                pointBorderWidth: 2,
                pointHoverBorderWidth: 2,
              },
            ],
          };
        }}
        options={{
          aspectRatio: window.matchMedia("(min-width: 600px)").matches ? 3 : 2,
          legend: {
            display: false,
          },

          scales: {
            yAxes: [
              {
                position: "right",
                gridLines: {
                  drawBorder: false,
                  borderDash: [12, 8],
                  color: "#e6e6e6",
                },
                ticks: {
                  padding: 10,
                  maxTicksLimit: 6,
                  callback: value => {
                    return `$ ${value.toFixed(2)}`;
                  },
                },
              },
            ],
            xAxes: [
              {
                offset: true,

                gridLines: {
                  display: false,
                  zeroLineColor: "#fff",
                },
                ticks: {
                  padding: 10,
                },
              },
            ],
          },
        }}
        className="w-full h-200 mt-30"
      />
    </div>
  );
};

ReferralEarns.propTypes = {
  earns: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ReferralEarns;
