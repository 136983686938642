export default  {
  rain: "Rain",
  placeholder: "Enter a message",
  rules: {
    button: "Chat Rules",
  },
  errors: {
    send: {
      notValid: "Invalid message format",
    }
  },
  tip: "Just sent a tip ${{ amount }} to {{ recipient.name }}",
}