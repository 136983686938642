import React from "react";
import PropTypes from "prop-types";

const GameSidebar = ({ children }) => {
  return (
    <div className="game-wrapper__sidebar">
      <div className="game-sidebar-inner">
        {children}
      </div>
    </div>
  );
};

GameSidebar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GameSidebar;
