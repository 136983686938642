import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { connect, shallowEqual, useDispatch } from "react-redux";
import { useApi } from "@/api";
import { setActiveRound } from "@/actions/coinflipAction";
import CoinflipListRound from "@/components/games/coinflip/CoinflipListRound";
import noty from "@/libs/noty";

const CoinflipListRoundContainer = ({ userId, round, disabled, isEmpty }) => {
  const { t } = useTranslation();

  const { coinflipJoin } = useApi();
  const [pending, setPending] = useState(false);
  const dispatch = useDispatch();

  const join = () => {
    setPending(true);

    coinflipJoin(round.id)
      .then(() => {
        noty.success(t("couple.successJoin"));
      })
      .catch(() => {
        noty.error(t("couple.failJoin"));
      })
      .finally(() => {
        setPending(false);
      });
  };

  if (isEmpty && !round.id) {
    return <></>;
  }

  return (
    <CoinflipListRound
      userId={userId}
      round={round}
      onJoin={join}
      disabled={disabled || pending}
      onWatch={data => dispatch(setActiveRound(data))}
    />
  );
};

CoinflipListRoundContainer.propTypes = {
  position: PropTypes.number.isRequired,
  isEmpty: PropTypes.bool,
};

CoinflipListRoundContainer.defaultProps = {
  isEmpty: false,
};

const CoinflipListRoundContainerWithRedux = connect(
  ({ auth, coinflip }, { position, isEmpty }) => {
    const round = coinflip.rounds[position] || { position };
    const userId = auth.user ? auth.user.id : null;
    const balance = auth.user ? auth.user.balance : 0;

    const disabled = round ? balance < round.deposit : false;

    return {
      userId,
      disabled,
      round,
      isEmpty,
    };
  },
  () => ({}),
  null,
  {
    areStatePropsEqual: (next, prev) => {
      if (prev.disabled !== next.disabled) {
        return false;
      }

      return shallowEqual(prev.round, next.round);
    },
  },
)(CoinflipListRoundContainer);

export default CoinflipListRoundContainerWithRedux;
