import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ChatMessageControl = ({
  canBan,
  onSelect,
  disabled,
  isActive,
  onDelete,
}) => {
  const { t } = useTranslation();

  const durations = [
    {
      value: 15,
      label: 15,
    },
    {
      value: 30,
      label: 30,
    },
    {
      value: 180,
      label: t(`chat.ban.hour`, { h: 3 }),
    },
    {
      value: 1440,
      label: t(`chat.ban.day`, { d: 1 }),
    },
    {
      value: 10080,
      label: t(`chat.ban.week`, { w: 1 }),
    },
  ];

  return (
    <div className={`admin-ban-controll ${isActive ? "active" : ""}`}>
      {canBan && (
        <>
          {durations.map(({ value, label }) => (
            <button
              key={value}
              type="button"
              className="admin-ban-controll__btn"
              disabled={disabled}
              onClick={() => onSelect(value)}
            >
              {label}
            </button>
          ))}

          <div className="admin-ban-controll__btn--ban">
            <button
              type="button"
              className="admin-ban-controll__btn"
              disabled={disabled}
              onClick={() => onDelete()}
            >
              {t("chat.destroy.button")}
            </button>


            <button
              type="button"
              className="admin-ban-controll__btn"
              disabled={disabled}
              onClick={() => {
                if (window.confirm(t("chat.permanentlyConfirm"))) {
                  onSelect(null);
                }
              }}
            >
              {t("chat.ban.permanently")}
            </button>
          </div>
        </>
      )}

    </div>
  );
};

ChatMessageControl.propTypes = {
  canBan: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default ChatMessageControl;
