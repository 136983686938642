import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Modal from "@/components/Modal";
import UserMenuLink from "./UserMenuLink";

const UserMenuMobile = ({ user, links }) => {
  const modalRef = useRef();
  const { t } = useTranslation();

  return (
    <>
      <button
        type="button"
        className="btn btn--white btn--icon btn--rounded btn--md lg:hidden"
        onClick={() => {
          modalRef.current.open();
        }}
      >
        <svg className="w-20 h-20 fill-current">
          <use href="/svg/svg.svg#menu" />
        </svg>
      </button>

      <Modal ref={modalRef}  className="mob-nav" showCloseButton>
        <div className="flex flex-col items-stretch h-full">
          <div className="bg-black-600 h-65 mb-20">
            <button
              className="btn btn--start h-full w-full px-20"
              type="button"
              data-toggle-modal="auth-modal"
            >
              <div>
                <div className="text-2xl mb-5">{user.name}</div>
                <div className="text-green text-lg">{user.email}</div>
              </div>
            </button>
          </div>

          <ul>
            {links.map(link => (
              <li key={link.id}>
                <UserMenuLink
                  link={link}
                  className="btn btn--start px-20 h-50 text-3xl w-full border-b border-black-650"
                >
                  <div className="w-22 mr-15">{link.icon}</div>
                  {t(`userMenu.${link.id}`)}
                </UserMenuLink>
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </>
  );
};

UserMenuMobile.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })).isRequired,
};

export default UserMenuMobile;
