import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  setRoundDemo,
  setRoundStatus,
  setRoundSteps,
  setRoundBombCells,
  setRound,
  setRoundAmount,
} from "@/actions/minesAction";
import { useApi } from "@/api";
import noty from "@/libs/noty";
import { MINE_PENDING_STATUS, MINE_RESOLVE_STATUS } from "@/constants/minesStatuses";
import useSound from "@/helpers/useSound";
import MinesRoundControl from "@/components/games/mines/MinesRoundControl";

const MinesRoundControlContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const balance = useSelector(({ auth }) => (auth.user ? auth.user.balance : 0));
  const status = useSelector(({ mines }) => mines.round.status);
  const demo = useSelector(({ mines }) => mines.demo);
  const withdraw = useSelector(({ mines }) => mines.round.withdraw);
  const deposit = useSelector(({ mines }) => mines.round.deposit);
  const bombs = useSelector(({ mines }) => mines.round.bombs);
  const { minesCreate, minesCashOut } = useApi();
  const sound = useSound();

  const createDemoRound = bombs => {
    const demo = [];
    const getUniqCellId = () => {
      const cellId = _.random(0, 24);
      if (demo.findIndex(id => cellId === id) > -1) {
        return getUniqCellId();
      }
      return cellId;
    };
    new Array(bombs).fill(null).forEach(() => {
      demo.push(getUniqCellId());
    });

    dispatch(setRoundStatus(MINE_PENDING_STATUS));
    dispatch(setRoundAmount(0));
    dispatch(setRoundDemo(demo));
    dispatch(setRoundSteps([]));
    dispatch(setRoundBombCells([]));
    sound.bet();
  };

  const createRound = ({ bombs, deposit }) => {
    if (!deposit) {
      createDemoRound(bombs);
      return;
    }

    if (Number(balance) < Number(deposit)) {
      noty.error(t("notEnoughBalance"));
      return;
    }

    minesCreate({ bombs, deposit })
      .then(state => {
        dispatch(setRound(state));
        noty.success(t("mines.create.success"));
      })
      .catch(() => {
        noty.error(t("mines.create.error"));
      });
  };

  const resolve = bombCells => {
    dispatch(setRoundBombCells(bombCells));
    dispatch(setRoundStatus(MINE_RESOLVE_STATUS));
    sound.minesWin();
  };

  const cashOut = () => {
    if (demo) {
      resolve(demo);
      return;
    }

    minesCashOut()
      .then(({ bombCells }) => {
        resolve(bombCells);
      })
      .catch(() => {
        noty.error(t("mines.cashOut.error"));
      });
  };

  return (
    <MinesRoundControl
      onSubmit={createRound}
      status={status}
      onCashOut={() => cashOut()}
      withdraw={withdraw}
      startedBombs={bombs}
      startedDeposit={Number(deposit)}
    />
  );
};

export default MinesRoundControlContainer;
