import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBet, setBets, setHistory, setStatus, setDelta } from "@/actions/crashAction";
import { useApi } from "@/api";

const CrashStateContainer = () => {
  const dispatch = useDispatch();
  const { crashState } = useApi();

  useEffect(() => {
    crashState().then(({ status, bet, bets, history, delta, id }) => {
      dispatch(setStatus(status));
      dispatch(setBet(bet));
      dispatch(setBets(bets));
      dispatch(setHistory(history));
      dispatch(setDelta(delta));
    })
  });

  return <></>;
};

export default CrashStateContainer;
