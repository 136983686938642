import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import { USER_ACCOUNT_STEAM_TYPE, USER_ACCOUNT_GOOGLE_TYPE } from "@/constants/userAccountTypes";
import noty from "@/libs/noty";
import ProfileLinkAccounts from "@/components/profile/ProfileLinkAccounts";

const ProfileLinkAccountsContainer = () => {
  const user = useSelector(({ auth }) => auth.user);
  const [pending, setPending] = useState(false);
  const { t } = useTranslation();

  const { getSteamAuthUrl, unlinkAccount } = useApi();

  const accounts = [
    {
      type: USER_ACCOUNT_STEAM_TYPE,
      icon: (
        <svg aria-hidden="true" className="fill-current w-23 h-23 absolute-center">
          <use href="/svg/svg.svg#steam" />
        </svg>
      ),
      getUrl: getSteamAuthUrl,
    },
    {
      type: USER_ACCOUNT_GOOGLE_TYPE,
      icon: (
        <svg aria-hidden="true" className="fill-current w-22 h-22 absolute-center">
          <use href="/svg/svg.svg#twitter" />
        </svg>
      ),
    },
  ];

  const onLink = type => {
    const { getUrl } = accounts.find(account => type === account.type);

    getUrl().then(({ url }) => {
      window.location.href = url;
    });
  };

  const onUnlink = type => {
    if (pending) {
      return;
    }
    setPending(true);
    unlinkAccount(type)
      .then(() => {
        noty.success(t("profile.linkAccounts.unlinkSuccess"));
      })
      .catch(() => {
        noty.error(t("profile.linkAccounts.unlinkFail"));
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <ProfileLinkAccounts
      accounts={accounts.map(account => {
        const userAccount = user.accounts
          ? user.accounts.find(({ type }) => type === account.type)
          : null;
        return {
          ...account,
          name: userAccount ? userAccount.name : null,
        };
      })}
      onLink={onLink}
      onUnlink={onUnlink}
    />
  );
};

export default ProfileLinkAccountsContainer;
