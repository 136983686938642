import React from "react";
import { useApi } from "@/api";
import SocialAuthContainer from "@/containers/auth/SocialAuthContainer";

const FacebookAuthContainer = () => {
  const { linkFacebook, signInFacebook } = useApi();

  const getData = () => {
    const params = window.location.search;
    const searchParams = new URLSearchParams(params);
    const code = searchParams.get("code");

    return {
      code
    }
  }

  const link = payload => {
    const data = { ...getData(), ...payload };

    return linkFacebook(data);
  };

  const sign = payload => {
    const data = { ...getData(), ...payload };

    return signInFacebook(data);
  };

  return <SocialAuthContainer link={link} sign={sign} />;
};
export default FacebookAuthContainer;