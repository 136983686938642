import React from "react";
import { useSelector } from "react-redux";

import RouletteBets from "@/components/games/roulette/RouletteBets";

const RouletteBetsContainer = () => {
  const bets = useSelector(({ roulette }) => roulette.bets);

  return <RouletteBets bets={bets} />;
};

export default RouletteBetsContainer;
