import React from "react";
import Chart from "./Chart";
import propTypes from "./propTypes";

const CrashChart = ({ isMobile, bets, status, delta, crash, onChangeCoefficient }) => {
  return (
    <div className="game-wrapper__main">
      <div className="h-400 md:h-500 lg:h-620 text-center relative">
        <div className="graph-wrapper">
          <div className="graph">
            <div className="graph__inner">
              <Chart
                isMobile={isMobile}
                bets={bets}
                status={status}
                delta={delta}
                crash={crash}
                onChangeCoefficient={onChangeCoefficient}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CrashChart.propTypes = propTypes;

export default CrashChart;
