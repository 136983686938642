import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Switcher from "@/components/ui/Switcher";
import ProfileUpdateBooleanFieldContainer from "@/containers/profile/ProfileUpdateBooleanFieldContainer";

const ProfilePrivacyContainer = () => {
  const { t } = useTranslation();
  const user = useSelector(({ auth }) => auth.user);

  const [displayTotalWagers, setDisplayTotalWagers] = useState(user.displayTotalWagers);
  const [displayAmountWagers, setDisplayAmountWagers] = useState(user.displayAmountWagers);
  const [incognito, setIncognito] = useState(user.incognito);

  return (
    <div>
      <div className="account-modal-title">{t("profile.privacy.title")}</div>
      <ProfileUpdateBooleanFieldContainer field="displayTotalWagers">
        {({ onChange, disabled }) => (
          <Switcher
            label={t("profile.privacy.displayTotalWagers.label")}
            desc={t("profile.privacy.displayTotalWagers.desc")}
            checked={displayTotalWagers}
            disabled={disabled}
            onChange={checked => {
              const prev = displayTotalWagers;
              setDisplayTotalWagers(checked);
              onChange(checked).then(s => {
                if(!s) {
                  setDisplayTotalWagers(prev);
                }
              });
            }}
          />
        )}
      </ProfileUpdateBooleanFieldContainer>
      <ProfileUpdateBooleanFieldContainer field="displayAmountWagers">
        {({ onChange, disabled }) => (
          <Switcher
            className="mt-30"
            label={t("profile.privacy.displayAmountWagers.label")}
            desc={t("profile.privacy.displayAmountWagers.desc")}
            checked={displayAmountWagers}
            disabled={disabled}
            onChange={checked => {
              const prev = displayAmountWagers;
              setDisplayAmountWagers(checked);
              onChange(checked).then(s => {
                if(!s) {
                  setDisplayAmountWagers(prev);
                }
              });
            }}
          />
        )}
      </ProfileUpdateBooleanFieldContainer>
      <div className="bg-gray-100 h-2 my-30" />
      <div className="account-modal-title">{t("profile.privacy.gameFeed")}</div>
      <ProfileUpdateBooleanFieldContainer field="incognito">
        {({ onChange, disabled }) => (
          <Switcher
            label={t("profile.privacy.incognito.label")}
            desc={t("profile.privacy.incognito.desc")}
            checked={incognito}
            disabled={disabled}
            onChange={checked => {
              const prev = incognito;
              setIncognito(checked);
              onChange(checked).then(s => {
                if(!s) {
                  setIncognito(prev);
                }
              });
            }}
          />
        )}
      </ProfileUpdateBooleanFieldContainer>
    </div>
  );
};

export default ProfilePrivacyContainer;
