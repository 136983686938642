import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import noty from "@/libs/noty";
import CoinflipCreateForm from "@/components/games/coinflip/CoinflipCreateForm";
import CoinflipStats from "@/components/games/coinflip/CoinflipStats";

const CoinflipSidebarContainer = () => {
  const { t } = useTranslation();
  const [pending, setPending] = useState(false);
  const { coinflipCreate } = useApi();
  const balance = useSelector(({ auth }) => (auth.user ? auth.user.balance : 0));
  const bets = useSelector(({ coinflip }) => coinflip.bets);

  const create = amount => {
    if (amount > balance) {
      noty.error(t("notEnoughBalance"));
      return;
    }
    if (pending) {
      return;
    }

    setPending(true);

    coinflipCreate({ deposit: amount })
      .then(() => {
        noty.success(t("coinflip.create.success"));
      })
      .catch(() => {
        noty.error(t("coinflip.create.fail"));
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <div className="game-sidebar-inner">
      <CoinflipCreateForm onSubmit={create} />
      <CoinflipStats bets={bets} />
    </div>
  );
};

export default CoinflipSidebarContainer;
