import {
  SET_MINES_ROUND,
  SET_MINES_ROUND_DEMO,
  SET_MINES_ROUND_AMOUNT,
  SET_MINES_ROUND_BOMBS,
  SET_MINES_ROUND_BOMB_CELLS,
  SET_MINES_ROUND_ID,
  SET_MINES_ROUND_STATUS,
  SET_MINES_ROUND_STEPS,
  SET_MINES_ROUND_WITHDRAW,
  SET_MINES_ROUND_SERVER_SEED,
  SET_MINES_LINE,
  NEW_MINES_LINE_BET,
} from "@/constants";

const initialState = {
  demo: null,
  round: {
    id: null,
    deposit: 0,
    bombs: 3,
    status: null,
    withdraw: 0,
    cells: [],
    bombCells: [],
    steps: [],
    serverSeed: "",
    nonce: 0,
  },
  line: [],
};

const mines = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_MINES_ROUND: {
      return {
        ...state,
        round: {
          ...action.payload,
        },
      };
    }
    case SET_MINES_ROUND_DEMO: {
      return {
        ...state,
        demo: action.payload,
      };
    }
    case SET_MINES_ROUND_AMOUNT:
      return {
        ...state,
        round: {
          ...state.round,
          deposit: action.payload,
        },
      };
    case SET_MINES_ROUND_BOMBS:
      return {
        ...state,
        round: {
          ...state.round,
          bombs: action.payload,
        },
      };
    case SET_MINES_ROUND_ID:
      return {
        ...state,
        round: {
          ...state.round,
          id: action.id,
        },
      };
    case SET_MINES_ROUND_STEPS:
      return {
        ...state,
        round: {
          ...state.round,
          steps: [...action.payload],
        },
      };
    case SET_MINES_ROUND_STATUS:
      return {
        ...state,
        round: {
          ...state.round,
          status: action.payload,
        },
      };
    case SET_MINES_ROUND_BOMB_CELLS:
      return {
        ...state,
        round: {
          ...state.round,
          bombCells: action.payload,
        },
      };
    case SET_MINES_ROUND_WITHDRAW:
      return {
        ...state,
        round: {
          ...state.round,
          withdraw: action.payload,
        },
      };
    case SET_MINES_ROUND_SERVER_SEED:
      return {
        ...state,
        round: {
          ...state.round,
          serverSeed: action.payload,
        },
      };
    case SET_MINES_LINE:
      return {
        ...state,
        line: [...action.payload],
      };
    case NEW_MINES_LINE_BET:
      return {
        ...state,
        line: [action.payload, ...state.line].slice(0, 10),
      };

    default:
      return state;
  }
};

export default mines;
