import {
  SET_COINFLIP_ROUNDS,
  SET_COINFLIP_ACTIVE_ROUND,
  SET_COINFLIP_BETS,
  NEW_COINFLIP_ROUND,
  NEW_COINFLIP_BET,
  REMOVE_COINFLIP_ROUND,
} from "@/constants";

export const setRounds = payload => {
  return {
    type: SET_COINFLIP_ROUNDS,
    payload,
  }
}

export const setActiveRound = payload => {
  return {
    type: SET_COINFLIP_ACTIVE_ROUND,
    payload,
  }
}

export const setBets = payload => {
  return {
    type: SET_COINFLIP_BETS,
    payload,
  }
}

export const newRound = payload => {
  return {
    type: NEW_COINFLIP_ROUND,
    payload,
  }
}

export const newBet = payload => {
  return {
    type: NEW_COINFLIP_BET,
    payload,
  }
}

export const removeRound = payload => {
  return {
    type: REMOVE_COINFLIP_ROUND,
    payload,
  }
}
