import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useApi } from "@/api";
import { updateUser } from "@/actions/authAction";
import ProfileVerificationUploadContainer from "@/containers/profile/ProfileVerificationUploadContainer";
import ProfileVerification from "@/components/profile/ProfileVerification";
import ProfileVerificationFormContainer from "@/containers/profile/ProfileVerificationFormContainer";

const ProfileVerificationContainer = () => {
  const dispatch = useDispatch();
  const { userVerificationState } = useApi();
  const [stateLoaded, setStateLoaded] = useState(false);
  const verification = useSelector(({ auth }) => auth.user.verification);

  useEffect(() => {
    if (stateLoaded) {
      return;
    }
    setStateLoaded(true);
    userVerificationState().then(data => {
      dispatch(
        updateUser({
          verification: data,
        }),
      );
    });
  });

  return (
    <ProfileVerification
      form={<ProfileVerificationFormContainer />}
      uploaders={
        verification ? (
          <>
            <ProfileVerificationUploadContainer
              id="photoIdentity"
              isUploaded={!!verification.photoIdentity}
              isVerified={verification.isIdentityVerified}
            />
            <div className="bg-gray-100 h-2 my-25" />
            <ProfileVerificationUploadContainer
              id="photoAddress"
              isUploaded={!!verification.photoAddress}
              isVerified={verification.isAddressVerified}
            />
          </>
        ) : (
          <></>
        )
      }
    />
  );
};

export default ProfileVerificationContainer;
