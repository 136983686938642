import React from "react";
import Notifications from "@/components/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { useApi } from "@/api";
import { setNotifications } from "@/actions/authAction";
import NotificationsSubscriberContainer from "@/containers/NotificationsSubscriberContainer";

const NotificationsContainer = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(({ auth }) => auth.notifications);
  const { readNotifications } = useApi();

  const hasRead = notifications.filter(({ isRead }) => !isRead).length > 0;

  const read = () => {
    if (hasRead) {
      readNotifications().then(() => {
        dispatch(
          setNotifications(
            notifications.map(notification => {
              return {
                ...notification,
                isRead: true,
              };
            }),
          ),
        );
      });
    }
  };

  return (
    <>
      <NotificationsSubscriberContainer />
      <Notifications items={notifications} hasRead={hasRead} onView={read} />
    </>
  );
};

export default NotificationsContainer;
