import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setOpenCashier } from "@/actions/mainAction";
import Header from "@/components/Header";
import UserMenuContainer from "@/containers/UserMenuContainer";
import LanguageChangeContainer from "@/containers/LanguageChangeContainer";
import SocialLinksContainer from "@/containers/SocialLinksContainer";
import NavigationContainer from "@/containers/NavigationContainer";
import NotificationsContainer from "@/containers/NotificationsContainer";
import AuthContainer from "@/containers/auth/AuthContainer";
import CashierContainer from "@/containers/CashierContainer";
import DepositButton from "@/components/deposit/DepositButton";
import ReferralContainer from "@/containers/ReferralContainer";

const HeaderContainer = () => {
  const user = useSelector(({ auth }) => auth.user);
  const dispatch = useDispatch();

  return (
    <Header>
      <LanguageChangeContainer />
      <div className="hidden xl:block ml-20 xxl:ml-30">
        <SocialLinksContainer />
      </div>
      <NavigationContainer />
      <div className="ml-auto lg:ml-0 space-x-10 lg:mr-10 flex flex-row-reverse lg:flex-row">
        {user && (
          <>
            <NotificationsContainer />
            <DepositButton balance={Number(user.balance)} onClick={() => dispatch(setOpenCashier(true))} />
            <CashierContainer />
            <ReferralContainer />
          </>
        )}
        {!user && <AuthContainer />}
      </div>
      {user && <UserMenuContainer />}
    </Header>
  );
};

export default HeaderContainer;
