export default {
  label: {
    name: "Name",
    email: "E-mail",
    birthday: "Date of Birth",
    password: "Password",
  },
  validations: {
    required: "Required field",
    birthday: "Incorrect age",
    email: "Invalid e-mail format",
    minLength: "Minimum length {{ length }} characters",
    maxLength: "Maximum length {{ length }} characters",
    uniqEmail: "User with this email is already registered",
    maxNumber: "Maximum number {{ max }}",
    minNumber: "Minimum number {{ min }}",
  },
  placeholder: {
    name: "Enter your name",
    email: "Enter your e-mail",
    birthday: "Enter your date of birth",
    password: "Enter your password",
    confirmPassword: "Repeat your",
  },
};
