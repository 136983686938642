import React from "react";
import { useTranslation } from "react-i18next";
import GameHeaderContainer from "@/containers/games/GameHeaderContainer";
import GameFooterContainer from "@/containers/games/GameFooterContainer";
import DiceSidebarContainer from "@/containers/games/dice/DiceSidebarContainer";
import DiceRoomContainer from "@/containers/games/dice/DiceRoomContainer";
import DiceCurrentStateContainer from "@/containers/games/dice/DiceCurrentStateContainer";
import DiceRoomSubscriberContainer from "@/containers/games/dice/DiceRoomSubscriberContainer";

const DiceMainContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <GameHeaderContainer title={t("dice.title")} />
      <div className="game-wrapper game-wrapper--has-sidebar">
        <DiceRoomContainer />
        <DiceSidebarContainer />
      </div>
      <GameFooterContainer />
      <DiceCurrentStateContainer />
      <DiceRoomSubscriberContainer />
    </>
  );
};

export default DiceMainContainer;
