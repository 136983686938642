import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import MainContainer from "@/containers/MainContainer";
import HomeContainer from "@/containers/HomeContainer";
import VkSignInAuthContainer from "@/containers/auth/VkSignInAuthContainer";
import MinesMainContainer from "@/containers/games/mines/MinesMainContainer";
import CrashMainContainer from "@/containers/games/crash/CrashMainContainer";
import TowersMainContainer from "@/containers/games/towers/TowersMainContainer";
import RouletteMainContainer from "@/containers/games/roulette/RouletteMainContainer";
import DiceMainContainer from "@/containers/games/dice/DiceMainContainer";
import CoinflipContainer from "@/containers/games/coinflip/CoinflipContainer";
import VerifyEmailContainer from "@/containers/auth/VerifyEmailContainer";
import FairContainer from "@/containers/FairContainer";
import NotFoundPage from "@/pages/NotFoundPage";
import ErrorPage from "@/pages/ErrorPage";
import CasinopubContainer from "@/containers/games/casinopub/CasinopubContainer";
import ReferralCodeContainer from "@/containers/ReferralCodeContainer";
import SteamAuthContainer from "@/containers/auth/SteamAuthContainer";
import FacebookAuthContainer from "@/containers/auth/FacebookAuthContainer";
import ProfileChangeEmailConfirmContainer from "@/containers/profile/ProfileChangeEmailConfirmContainer";
import VipPage from "@/pages/contents/VipPage";
import TermsPage from "@/pages/contents/TermsPage";
import TosPage from "@/pages/contents/TosPage";
import AmlPage from "@/pages/contents/AmlPage";
import PrivacyPage from "@/pages/contents/PrivacyPage";
import ResponsiblePage from "@/pages/contents/ResponsiblePage";

const App = () => {
  const isMobile = useSelector(({ main }) => main.isMobile);
  const gameType = useSelector(({ main }) => main.gameType);

  return (
    <Router>
      <Switch>
        <Route path="/ref/:code">
          <ReferralCodeContainer />
        </Route>
        <Route path="/auth/vk">
          <VkSignInAuthContainer />
        </Route>
        <Route path="/auth/steam">
          <SteamAuthContainer />
        </Route>
        <Route path="/auth/facebook">
          <FacebookAuthContainer />
        </Route>
        <Route path="/auth/verify-email/:token">
          <VerifyEmailContainer />
        </Route>
        <Route path="/change-email-verification">
          <ProfileChangeEmailConfirmContainer />
        </Route>
        <Route path="/404">
          <NotFoundPage />
        </Route>
        <Route path="/">
          <MainContainer>
            <Switch>
              <Route exact path="/">
                <HomeContainer />
              </Route>
              <Route exact path="/mines">
                <MinesMainContainer />
              </Route>
              <Route exact path="/crash">
                <CrashMainContainer />
              </Route>
              <Route exact path="/towers">
                <TowersMainContainer />
              </Route>
              <Route exact path="/roulette">
                <RouletteMainContainer />
              </Route>
              <Route exact path="/dice">
                <DiceMainContainer />
              </Route>
              <Route path="/coinflip">
                <CoinflipContainer />
              </Route>
              <Route path="/casino/:game">
                <CasinopubContainer />
              </Route>
              <Route path="/fair">
                <FairContainer />
              </Route>
              <Route path="/vip">
                <VipPage />
              </Route>
              <Route path="/terms">
                <TermsPage />
              </Route>
              <Route path="/tos">
                <TosPage />
              </Route>
              <Route path="/aml">
                <AmlPage />
              </Route>
              <Route path="/privacy">
                <PrivacyPage />
              </Route>
              <Route path="/responsible-gaming">
                <ResponsiblePage/>
              </Route>
              <Route path="/403">
                <ErrorPage title="403" />
              </Route>
              <Route path="*">
                <Redirect to="/404" />
              </Route>
            </Switch>
          </MainContainer>
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
