export default {
  titleModal: "Midasly",
  loginButton: "Login",
  registerButton: "Registration",
  wagerAllTime: "Wager all times",
  orContinue: "Или продолжите через",
  textFooter:
    "This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply. By accessing the site I attest that I am at least 18 years old and have read the Terms of Service.",
  register: {
    success:
      "The account has been created. A confirmation email has been sent to your email address. Follow the link to activate your account.",
    error: "An error has occurred. Please try again later.",
  },
  verifyEmail: {
    success: "Now you can log into your account using your email and password",
    error: "Error. Try later.",
  },
  login: {
    errors: {
      default: "Failed to login. Try again later",
      WRONG_CREDENTIAL: "Wrong login or password",
      NOT_VERIFIED_EMAIL: "Email address not verified",
    },
  },
};
