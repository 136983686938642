import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  setRoundStatus,
  setRoundSteps,
  setRoundBombCells,
  setRoundWithdraw,
} from "@/actions/minesAction";
import { useApi } from "@/api";
import {
  MINE_PENDING_STATUS,
  MINE_REJECT_STATUS,
  MINE_RESOLVE_STATUS,
} from "@/constants/minesStatuses";
import useSound from "@/helpers/useSound";
import noty from "@/libs/noty";
import MinesRound from "@/components/games/mines/MinesRound";

const MinesRoundContainer = () => {
  const sound = useSound();
  const { minesStep } = useApi();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);
  const steps = useSelector(({ mines }) => mines.round.steps);
  const bombCells = useSelector(({ mines }) => mines.round.bombCells);
  const status = useSelector(({ mines }) => mines.round.status);
  const demo = useSelector(({ mines }) => mines.demo);

  const isFinish = [MINE_REJECT_STATUS, MINE_RESOLVE_STATUS].includes(status);

  const cells = new Array(25).fill(null).map((v, id) => {
    const isBomb = bombCells.findIndex(step => step === id) > -1;
    const inStep = steps.findIndex(step => step === id) > -1;
    const isActive = isFinish && !isBomb ? true : inStep;
    const isAutoOpen = isFinish && !inStep;

    return {
      id,
      inStep,
      isActive,
      isBomb,
      isAutoOpen,
    };
  });

  const disabled = status !== MINE_PENDING_STATUS;

  const selectDemoCell = cellId => {
    dispatch(setRoundSteps([...steps, cellId]));
    if (demo.findIndex(id => cellId === id) > -1) {
      dispatch(setRoundStatus(MINE_REJECT_STATUS));
      dispatch(setRoundBombCells(demo));
      sound.minesBomb();
    } else {
      sound.minesTile();
    }
  };

  const selectCell = cellId => {
    if (disabled || pending) {
      return;
    }
    if (demo) {
      selectDemoCell(cellId);
      return;
    }

    setPending(true);

    minesStep(cellId)
      .then(({ status: newStatus, withdraw, bombCells }) => {
        dispatch(setRoundSteps([...steps, cellId]));
        dispatch(setRoundWithdraw(withdraw));
        if (newStatus && newStatus !== status) {
          dispatch(setRoundStatus(newStatus));
          dispatch(setRoundBombCells(bombCells));

          if (newStatus === MINE_RESOLVE_STATUS) {
            sound.minesWin();
          } else {
            sound.minesBomb();
          }
        } else {
          sound.minesTile();
        }
      })
      .catch(() => {
        noty.error(t("mines.stepError"));
      })
      .finally(() => {
        setPending(false);
      });
  };

  return <MinesRound cells={cells} disabled={pending} onSelect={selectCell} />;
};

export default MinesRoundContainer;
