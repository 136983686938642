import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const Navigation = ({ links }) => {
  const { t } = useTranslation();

  return (
    <nav className="main-nav ml-auto mr-20 xxl:mr-40 hidden lg:block">
      <ul>
        {links.map(({ id, path }) => {
          return (
            <li key={id}>
              <NavLink exact to={path} className="main-nav__link" activeClassName="active">
                <span>{t(`navigation.${id}`)}</span>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

Navigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Navigation;
