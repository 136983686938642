import React from "react";
import { useSelector } from "react-redux";
import RouletteHistory from "@/components/games/roulette/RouletteHistory";

const RouletteHistoryContainer = () => {
  const history = useSelector(({ roulette }) => roulette.history);

  return <RouletteHistory rounds={history} />;
};

export default RouletteHistoryContainer;
