import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import moment from "moment";
import { Formik } from "formik";
import Field from "@/components/ui/Field";

const ProfileVerificationForm = ({ initialValues, onSubmit, isVerified }) => {
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t("form.validations.minLength", { length: 2 }))
      .max(50, t("form.validations.maxLength", { length: 50 }))
      .required(t("form.validations.required")),
    lastName: Yup.string()
      .min(2, t("form.validations.minLength", { length: 2 }))
      .max(50, t("form.validations.maxLength", { length: 50 }))
      .required(t("form.validations.required")),
    country: Yup.string()
      .min(2, t("form.validations.minLength", { length: 2 }))
      .max(50, t("form.validations.maxLength", { length: 50 }))
      .required(t("form.validations.required")),
    city: Yup.string()
      .min(2, t("form.validations.minLength", { length: 2 }))
      .max(50, t("form.validations.maxLength", { length: 50 }))
      .required(t("form.validations.required")),
    address: Yup.string()
      .min(2, t("form.validations.minLength", { length: 2 }))
      .max(50, t("form.validations.maxLength", { length: 50 }))
      .required(t("form.validations.required")),
    birthday: Yup.date()
      .test("birthday", t("form.validations.birthday"), value => {
        const years = moment().diff(moment(value), "years");
        return years > 14 && years < 120;
      })
      .required(t("form.validations.required")),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => onSubmit(values, () => resetForm())}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ submitForm, handleChange, handleBlur, values }) => (
        <>
          {["firstName", "lastName", "country", "city", "address"].map((key, index) => (
            <Field
              key={key}
              name={key}
              placeholder={t(`profile.verification.form.placeholder.${key}`)}
              required
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={values[key]}
              className={index === 0 ? "" : "mt-10"}
              inputClassName="text-field--light"
            />
          ))}
          <Field
            name="birthday"
            type="date"
            placeholder={t(`profile.verification.form.placeholder.birthday`)}
            required
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.birthday}
            className="mt-10"
            inputClassName="text-field--light"
          />
          <div className="field-wrapper__description text-right">
            <div className="text-green inline-flex items-center">
              {isVerified ? (
                <>
                  <svg aria-hidden="true" className="fill-current w-12 h-12 mr-10">
                    <use href="/svg/svg.svg#basic-tick" />
                  </svg>
                  {t("profile.verification.form.isVerified")}
                </>
              ) : (
                <button
                  type="button"
                  className="btn btn--primary-light btn--text btn--offset-r btn--md"
                  onClick={submitForm}
                >
                  {t("profile.verification.form.submit")}
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

ProfileVerificationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  isVerified: PropTypes.bool.isRequired,
};

export default ProfileVerificationForm;
