import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import noty from "@/libs/noty";
import LoginForm from "@/components/auth/LoginForm";
import useSetToken from "@/containers/auth/useSetToken";

const LoginContainer = ({ successCallback }) => {
  const { t } = useTranslation();
  const [pending, setPending ] = useState(false);
  const { login } = useApi();
  const setToken = useSetToken();

  const submit = ({ email, password }) => {
    if (pending) {
      return;
    }
    setPending(true);

    login({ email, password })
      .then(data => {
        successCallback();
        setToken(data);
      })
      .catch(error => {
        let message = t("auth.login.errors.default");

        if (error.response) {
          if (error.response.status === 403) {
            message = t(`auth.login.errors.${error.response.data.error}`);
          }
        }

        noty.error(message);
      })
      .finally(() => {
        setPending(false);
      });
  };


  return <LoginForm onSubmit={submit} pending={pending} />;
};

LoginContainer.propTypes = {
  successCallback: PropTypes.func.isRequired,
};

export default LoginContainer;
