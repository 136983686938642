import {
  AUTH_SET_TOKEN,
  AUTH_CLEAR,
  SET_USER,
  SET_USER_LANG,
  SET_USER_BALANCE,
  SET_USER_CLIENT_SEED,
  SET_NOTIFICATIONS,
  NEW_NOTIFICATION,
  UPDATE_USER,
} from "@/constants";

const initialState = {
  user: null,
  notifications: [],
  token: null,
};

const auth = (state = { ...initialState }, action) => {
  switch (action.type) {
    case AUTH_SET_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }

    case SET_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }

    case UPDATE_USER: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    }

    case AUTH_CLEAR: {
      return {
        ...initialState,
      };
    }

    case SET_USER_LANG: {
      return {
        ...state,
        user: {
          ...state.user,
          lang: action.payload,
        },
      };
    }

    case SET_USER_CLIENT_SEED: {
      return {
        ...state,
        user: {
          ...state.user,
          clientSeed: action.payload,
        },
      };
    }

    case SET_USER_BALANCE: {
      return {
        ...state,
        user: {
          ...state.user,
          balance: action.payload,
        },
      };
    }

    case SET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: [...action.payload],
      };
    }

    case NEW_NOTIFICATION: {
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    }

    default:
      return state;
  }
};

export default auth;
