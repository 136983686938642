import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { newBet } from "@/actions/towersAction";
import useSubscribe from "@/libs/centrifuge/useSubscribe";

const TowerBetsSubscriberContainer = () => {
  const dispatch = useDispatch();
  const subscribe = useSubscribe();

  useEffect(() => {
    const unsubscribe = subscribe("towers-bets", round => {
      dispatch(newBet(round));
    });

    return () => unsubscribe();
  });

  return <></>;
};

export default TowerBetsSubscriberContainer;
