import {
  SET_ROULETTE_BETS,
  SET_ROULETTE_ROUND,
  NEW_ROULETTE_BET,
  SET_ROULETTE_HISTORY,
  NEW_ROULETTE_HISTORY,
  FINISH_ROULETTE_BETS,
} from "@/constants";

import {
  ROULETTE_BET_RESOLVE_STATUS,
  ROULETTE_BET_REJECT_STATUS,
} from "@/constants/rouletteBetStatuses";

const initialState = {
  bets: [],
  history: [],
  round: null,
};

const roulette = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_ROULETTE_ROUND: {
      return {
        ...state,
        round: action.payload,
      };
    }

    case SET_ROULETTE_BETS: {
      return {
        ...state,
        bets: action.payload,
      };
    }

    case NEW_ROULETTE_BET: {
      return {
        ...state,
        bets: [...state.bets, action.payload],
      };
    }

    case SET_ROULETTE_HISTORY: {
      return {
        ...state,
        history: action.payload,
      };
    }

    case NEW_ROULETTE_HISTORY: {
      return {
        ...state,
        history: [action.payload, ...state.history].splice(0, 20),
      };
    }

    case FINISH_ROULETTE_BETS: {
      const coefficient = action.payload.winnerNumber === 3 ? 14 : 2;

      const bets = state.bets.map(bet => {
        const isWin = bet.number === action.payload.winnerNumber;

        const status = isWin ? ROULETTE_BET_RESOLVE_STATUS : ROULETTE_BET_REJECT_STATUS;
        const withdraw = isWin ? bet.deposit * coefficient : 0;

        return {
          ...bet,
          status,
          withdraw,
        };
      });

      return {
        ...state,
        bets,
      };
    }

    default: {
      return state;
    }
  }
};

export default roulette;
