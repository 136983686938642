import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const InputDeposit = ({ label, value, onChange, min, max }) => {
  const { t } = useTranslation();

  const change = e => {
    let newValue = e.target.value ? parseFloat(e.target.value) : "";
    if (newValue > max) {
      newValue = max;
    }
    if (newValue < min) {
      newValue = min;
    }
    onChange(newValue);
  };

  return (
    <>
      <div className="text-lg">{label || t("inputDeposit")}</div>
      <label htmlFor="mines-deposit" className="block relative mb-20">
        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 -mt-1 text-gray-600">
          $
        </span>
        <input
          id="mines-deposit"
          type="number"
          className="text-field pl-20"
          placeholder="0.00"
          step="0.1"
          value={value}
          onChange={change}
          onBlur={change}
        />
      </label>
    </>
  );
};

InputDeposit.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

InputDeposit.defaultProps = {
  label: null,
  value: 0.0,
  min: 0,
  max: 10000,
};

export default InputDeposit;
