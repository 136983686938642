import {
  TOWERS_EASY_DIFFICULTY,
  TOWERS_MEDIUM_DIFFICULTY,
  TOWERS_HARD_DIFFICULTY,
} from "@/constants/towersDifficulty";

export default {
  title: "Towers",
  create: {
    deposit: "Bet amount",
    level: "Select the difficulty level",
    submit: "Place Bet",
    demo: "Play Demo",
  },
  levels: {
    [TOWERS_EASY_DIFFICULTY]: "Easy",
    [TOWERS_MEDIUM_DIFFICULTY]: "High Wins",
    [TOWERS_HARD_DIFFICULTY]: "Lucky Wins",
  },
  cashOut: "Cash-Out",
  demo: "Betting $0.00 will enter demo mode",
  lastGames: "Last Games Players"
};
