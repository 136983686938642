import React from "react";
import { useSelector } from "react-redux";
import RouletteGameboard from "@/components/games/roulette/RouletteGameboard";

const RouletteRoundContainer = () => {
  const round = useSelector(({ roulette }) => roulette.round);
  const bets = useSelector(({ roulette }) => roulette.bets);

  if (!round) {
    return <></>;
  }

  return <RouletteGameboard round={round} playersCount={bets.length} />;
};

export default RouletteRoundContainer;
