import {
  SET_CRASH_STATE,
  SET_CRASH_BET,
  SET_CRASH_STATUS,
  SET_CRASH_DELTA,
  SET_CRASH_CRASH,
  SET_CRASH_HISTORY,
  ADD_CRASH_HISTORY,
  SET_CRASH_BETS,
  SET_CRASH_STATISTIC,
  NEW_CRASH_BET,
  UPDATE_CRASH_BET,
  CRASH_CRASH_BETS,
  UPDATE_USER_CRASH_BET,
  SET_CRASH_HASH,
  SET_CRASH_COEFFICIENT,
  SET_CRASH_AUTO_COEFFICIENT,
  SET_CRASH_AUTO_COEFFICIENT_SETTINGS,
} from "@/constants";

import { CRASH_WAITING_STATUS, CRASH_SHIPPED_STATUS } from "@/constants/crashStatuses";

import { BET_WAITING_STATUS, BET_CRASHED_STATUS } from "@/constants/crashBetStatuses";

const initialState = {
  bet: null,
  bets: [],
  history: [],
  crash: 0,
  coefficient: 0,
  delta: null,
  status: null,
  statistic: null,
  hash: null,
  autoCoefficient: "2.00",
  autoCoefficientSettings: ["1.20", "1.50", "2.00", "3.00"],
};

const crash = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_CRASH_STATE: {
      return {
        ...action.payload,
      };
    }
    case SET_CRASH_BET: {
      return {
        ...state,
        bet: action.payload,
      };
    }
    case SET_CRASH_BETS: {
      return {
        ...state,
        bets: action.payload,
      };
    }
    case SET_CRASH_HISTORY: {
      return {
        ...state,
        history: action.payload,
      };
    }
    case ADD_CRASH_HISTORY: {
      const history = [action.payload, ...state.history].slice(0, 30);
      return {
        ...state,
        history,
      };
    }
    case SET_CRASH_STATUS: {
      const isNewRound =
        state.status === CRASH_SHIPPED_STATUS && action.payload === CRASH_WAITING_STATUS;

      return {
        ...state,
        bet: isNewRound ? null : state.bet,
        bets: isNewRound ? [] : state.bets,
        status: action.payload,
      };
    }
    case SET_CRASH_DELTA: {
      return {
        ...state,
        delta: action.payload,
      };
    }
    case SET_CRASH_CRASH: {
      return {
        ...state,
        crash: action.payload,
      };
    }
    case SET_CRASH_STATISTIC: {
      return {
        ...state,
        statistic: action.payload,
      };
    }
    case NEW_CRASH_BET: {
      return {
        ...state,
        bets: [...state.bets, action.payload],
      };
    }
    case SET_CRASH_HASH: {
      return {
        ...state,
        hash: action.payload,
      };
    }
    case SET_CRASH_COEFFICIENT: {
      return {
        ...state,
        coefficient: action.payload,
      };
    }
    case UPDATE_CRASH_BET: {
      const { bets } = state;
      const index = bets.findIndex(({ id }) => action.payload.id === id);

      if (index > -1) {
        const bet = bets[index];
        bets[index] = {
          ...bet,
          ...action.payload,
        };
      }

      return {
        ...state,
        bets: [...bets],
      };
    }
    case CRASH_CRASH_BETS: {
      const crashBet = bet => {
        const isWaiting = bet.status === BET_WAITING_STATUS;
        return {
          ...bet,
          status: isWaiting ? BET_CRASHED_STATUS : bet.status,
          coefficient: isWaiting ? action.payload : bet.coefficient,
        };
      };

      const bets = state.bets.map(crashBet);

      return {
        ...state,
        bets: [...bets],
        bet: state.bet ? { ...crashBet(state.bet) } : null,
      };
    }
    case UPDATE_USER_CRASH_BET: {
      return {
        ...state,
        bet: {
          ...state.bet,
          ...action.payload,
        },
      };
    }
    case SET_CRASH_AUTO_COEFFICIENT_SETTINGS:
      return {
        ...state,
        autoCoefficientSettings: action.payload,
      };
    case SET_CRASH_AUTO_COEFFICIENT:
      return {
        ...state,
        autoCoefficient: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default crash;
