import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import noty from "@/libs/noty";
import { PAYMENT_TYPE_MONEY, PAYMENT_TYPE_SKINS, PAYMENT_TYPE_TEST } from "@/constants/paymentTypes";
import { PAYMENT_METHOD_BUY_CODE, PAYMENT_METHOD_CLOVER } from "@/constants/paymentMethods";
import analytics from "@/helpers/analytics";
import Deposit from "@/components/deposit/Deposit";

const MIN_PRICE_MONEY = 0.25;

const DepositContainer = () => {
  const hiddenRef = useRef();
  const { t } = useTranslation();
  const paymentMethods = useSelector(({ main }) => main.paymentMethods);
  const { createDeposit } = useApi();

  const validatePrice = (amount, min, currency = "$") => {
    if (amount < min) {
      noty.error(t("payment.minimumAmountWarning", { amount: min, currency }));
      return false;
    }
    return true;
  };

  const submit = ({ type, amount, rub, paymentMethod }) => {
    if (paymentMethod && paymentMethod.name === PAYMENT_METHOD_BUY_CODE) {
      window.location.href = "https://goodasly.com/?type=2";
      return;
    }

    if (paymentMethod && paymentMethod.name === PAYMENT_METHOD_CLOVER) {
      window.location.href = "https://goodasly.com/product/658099e9-7ca8-4ebd-b73e-982ed98ca7a4";
      return;
    }

    if (!paymentMethod && type !== PAYMENT_TYPE_SKINS) {
      noty.error(t("payment.modalTitle"));
      return;
    }

    if (type === PAYMENT_TYPE_MONEY) {
      if (!validatePrice(amount, MIN_PRICE_MONEY)) {
        return;
      }

      if (paymentMethod && paymentMethod.minAmount) {
        const isRub = paymentMethod.currency !== 1;
        if (
          !validatePrice(isRub ? Number(rub) : amount, paymentMethod.minAmount, isRub ? "руб" : "$")
        ) {
          return;
        }
      }
    }

    let depositAmount = amount;

    if (type === PAYMENT_TYPE_SKINS) {
      depositAmount = 0.25;
    }

    const cid = analytics.getClientId();

    const endpoint = type === PAYMENT_TYPE_SKINS ? "skins" : paymentMethod.type;

    createDeposit({ endpoint, amount: depositAmount, cid })
      .then(({ url }) => {
        noty.success(t("payment.success"));

        if (typeof url === "object") {
          if (paymentMethod.name === "interkassa") {
            const inputs = Object.keys(url).reduce(
              (sum, key) => `${sum}<input type="hidden" name="${key}" value="${url[key]}"/>`,
              "",
            );
            hiddenRef.current.innerHTML = `<form name="payment" method="post" action="https://sci.interkassa.com/" accept-charset="UTF-8">
                                            ${inputs}
                                            <button type="submit">pay</button>
                                          </form>`;
            setTimeout(() => {
              hiddenRef.current.querySelectorAll("button")[0].click();
            }, 300);
          }
        } else {
          window.location.href = url;
        }
      })
      .catch(error => {
        let message = t("payment.fail");

        if (error.response && error.response.status === 403) {
          if (error.response.data.error.includes("LIMITdollar")) {
            message = t("payment.minimumAmountWarning", {
              amount: error.response.data.error.replace("LIMITdollar_", ""),
              currency: "$",
            });
          } else if (error.response.data.error.includes("LIMIT")) {
            message = t("payment.minimumAmountWarning", {
              amount: error.response.data.error.replace("LIMIT_", ""),
              currency: "руб",
            });
          } else {
            message = t(`payment.errors.${error.response.data.error}`);
          }
        }

        noty.error(message);
      });
  };

  if (!paymentMethods) {
    return <></>;
  }

  return (
    <>
      <div ref={hiddenRef} />
      <Deposit paymentMethods={paymentMethods} onSubmit={submit} />
    </>
  );
};

export default DepositContainer;
