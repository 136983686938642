import React from "react";
import { useApi } from "@/api";
import SocialAuthContainer from "@/containers/auth/SocialAuthContainer";

const VkSignInAuthContainer = () => {
  const { linkVk, signInVk } = useApi();

  const getData = () => {
    const params = window.location.hash.substring(1);
    const searchParams = new URLSearchParams(params);

    const accessToken = searchParams.get("access_token");
    const uid = searchParams.get("user_id");

    return {
      accessToken,
      uid,
    }
  }

  const link = payload => {
    const data = { ...getData(), ...payload };

    return linkVk(data);
  };

  const sign = payload => {
    const data = { ...getData(), ...payload };

    return signInVk(data);
  };

  return <SocialAuthContainer link={link} sign={sign} />;
};
export default VkSignInAuthContainer;