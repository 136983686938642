import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { setOpenReferral } from "@/actions/mainAction";
import CurrentStateContainer from "@/containers/CurrentStateContainer";
import HeaderContainer from "@/containers/HeaderContainer";
import FooterContainer from "@/containers/FooterContainer";
import LiveBetsContainer from "@/containers/LiveBetsContainer";
import UserSubscriberContainer from "@/containers/UserSubscriberContainer";
import ChatContainer from "@/containers/chat/ChatContainer";
import Layout from "@/components/Layout";
import OnlineContainer from "@/containers/OnlineContainer";

const MainContainer = ({ children }) => {
  const fullScreen = useSelector(({ main }) => main.fullScreen);
  const dispatch = useDispatch();

  const menu = [
    {
      id: "promo",
      onClick: () => {},
    },
    {
      id: "referral",
      onClick: () => {
        dispatch(setOpenReferral(true));
      },
    },
  ];

  return (
    <>
      <Layout
        header={<HeaderContainer />}
        footer={
          <>
            <LiveBetsContainer />
            <FooterContainer />
          </>
        }
        online={<OnlineContainer />}
        fullScreen={fullScreen}
        menu={menu}
      >
        {children}
        <CurrentStateContainer />
        <UserSubscriberContainer />
        <ChatContainer />
      </Layout>
    </>
  );
};

MainContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainContainer;
