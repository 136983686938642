export default {
  modal: {
    button: {
      open: "Change seed",
      changeClientSeed: "Change Client Seed",
      changeServerSeed: "Change Server Seed",
    },
    label: {
      clientSeed: "Your Client seed",
      serverSeed: "Our Server seed(hashed)",
      nonce: "Nonce",
    },
    placeholder: {
      clientSeed: "Enter Client seed",
      serverSeed: "Server seed",
      nonce: "Nonce",
    },
    changeClientSeedSuccess: "Client Seed success change!",
    changeClientSeedFail: "Failed to change Client Seed. Try later.",
  },
  page: {
    title: "How fair play works at Midasly",
    item: {
      overview: {
        name: "Overview",
        content: `<div>Midasly is fully licensed by the gaming license of the Netherlands Antilles and is carried out in accordance with the legislation of the country.</div>`,
      },
      mines: {
        name: "Mines",
        content: `
<div><p>This game uses three ingredients to calculate each result:</p><ul><li><b>Server Seed</b> - provided by us</li><li><b>Client Seed</b> - provided by you</li><li><b>Nonce</b> - a number that increases with each bet</li></ul><br><p>You will get an <b>encrypted hash</b> (<a href="https://support.google.com/google-ads/answer/9004655?hl=en" target="_blank">SHA256</a>) of the Server Seed as you begin playing. This does not change unless you change it or reveal your Server Seed.</p><p>You provide us the <b>Client Seed</b> which can be changed before any bet to ensure you have control over the outcome.</p><p>After each bet you make, the <b>Nonce</b> increments by 1.</p><p>When you verify a bet some things happen:</p><ul><li>We reveal the Server Seed of which we gave you an encrypted hash of</li><li>We re-run the three ingredients (Server Seed, Client Seed, Nonce) to re-generate the outcome of the bet for you to see</li><li>Your Nonce gets reset</li><li>Your Server Seed gets reset</li></ul><br><p>With this information, you can do a number of things to further verify your bet:</p><ol><li>You can take a SHA256 of the revealed server seed and make sure that it equals what we gave you as the encrypted hash</li><li>Using the codesandbox provided below, you can run the exact same code we ran to determine the result of the game, using your revealed Server Seed</li></ol><br><iframe style="width: 100%; height: 600px" src="https://codesandbox.io/embed/mines-forked-k7w1j?codemirror=1&amp;view=editor&amp;fontsize=14" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe></div>`,
      },
    },
  },
};
