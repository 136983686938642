import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import noty from "@/libs/noty";
import ProfileChangeEmail from "@/components/profile/ProfileChangeEmail";

const ProfileChangeEmailContainer = () => {
  const { t } = useTranslation();
  const user = useSelector(({ auth }) => auth.user);
  const { changeEmailCreate } = useApi();
  const [pending, setPending] = useState(false);

  const submit = newEmail => {
    return;
    // if (pending) {
    //   return;
    // }
    // setPending(true);
    // changeEmailCreate({ newEmail })
    //   .then(() => {
    //     noty.success(t(`profile.changeEmail.successCreate${user.email ? "Multiple" : "Single"}`));
    //   })
    //   .catch(error => {
    //     const senError = key => noty.error(t(`profile.changeEmail.${key}`));
    //     if (error.response && error.response.status === 422) {
    //       senError("notValidCreate");
    //     } else if (error.response && error.response.status === 403) {
    //       senError(error.response.data.error);
    //     } else {
    //       senError("failCreate");
    //     }
    //   })
    //   .finally(() => {
    //     setPending(false);
    //   });
  };

  return (
    <ProfileChangeEmail onSubmit={submit} email={user.email} isVerified={user.isEmailVerified} />
  );
};

export default ProfileChangeEmailContainer;
